/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
// REF: https://www.w3.org/TR/wai-aria-practices/examples/combobox/aria1.1pattern/listbox-combo.html
import cx from 'clsx'
import { findIndex, get, isObject } from 'lodash'
import React from 'react'
import styled, { css } from 'styled-components'

import * as Icons from '../icons'
import RawInput from './input'

export const Control = ({
  state,
  children,
  loader,
  disabled,
  className,
  darkModeDarkerBg,
  multiple
}) => {
  const [position, setPosition] = React.useState({ left: 0, width: 0 })
  const [ref, setRef] = React.useState()
  React.useEffect(() => {
    if (!ref || !state.active) return
    const scrollParent = state.scrollParent
    setPosition({ width: ref.offsetWidth, left: scrollParent?.scrollLeft ?? 0 })
    window.addEventListener('resize', state.hide)
    if (scrollParent) scrollParent.addEventListener('scroll', state.hide)
    return () => {
      window.removeEventListener('resize', state.hide)
      if (scrollParent) scrollParent.removeEventListener('scroll', state.hide)
    }
  }, [ref, state.active, state.scrollParent, state.hide])
  return (
    <div className={className}>
      <div className='relative' ref={setRef}>
        <div>
          <Input
            autoComplete='off'
            disabled={disabled}
            state={state}
            className={cx(
              darkModeDarkerBg
                ? 'dark:!border-light-gray-500 dark:!bg-light-gray-300'
                : 'dark:!bg-light-gray-400'
            )}
            multiple={multiple}
          />
          <Icons.Search
            className={cx(
              'absolute',
              state.gridded ? 'left-4 top-1' : 'left-2 top-2',
              disabled
                ? 'fill-medium-gray-200 dark:fill-medium-gray-100'
                : 'fill-medium-gray-500 dark:fill-medium-gray-300'
            )}
          />
        </div>
        {loader}
      </div>
      {state.active && (
        <Results state={state} position={position} multiple={multiple}>
          {state.fixedOptions &&
            state.fixedOptions.map(fixedOption => (
              <Result
                key={fixedOption.id}
                state={state}
                value={fixedOption}
                isSelected={
                  findIndex(state.value, v => v.id === fixedOption.id) !== -1
                }
              />
            ))}
          {state.fixedOptions && <Divider />}
          {state.options?.map(option => (
            <Result
              key={option.id}
              state={state}
              value={option}
              isSelected={
                findIndex(state.value, v => v.id === option.id) !== -1
              }
            />
          ))}
        </Results>
      )}
      {children}
    </div>
  )
}

export const useLookup = ({
  id,
  gridded,
  placeholder,
  format,
  options,
  onChange,
  query,
  setQuery,
  aria,
  scrollParent,
  fixedOptions,
  value
}) => {
  const inputRef = React.useRef()
  const resultsRef = React.useRef()
  const [active, setActive] = React.useState(false)
  const [selected, setSelected] = React.useState(-1)
  const hide = () => {
    setActive(false)
    setSelected(-1)
  }
  React.useEffect(() => {
    if (!active) return
    const handler = e => {
      if (inputRef.current && e.target === inputRef.current) return
      if (resultsRef.current && resultsRef.current.contains(e.target)) return
      hide()
    }
    document.addEventListener('click', handler, { capture: true })
    return () => {
      return document.removeEventListener('click', handler)
    }
  }, [active])
  const allOptions = fixedOptions ? fixedOptions.concat(options) : options
  const selectedId = selected === -1 ? null : get(allOptions, [selected, 'id'])
  return {
    id,
    gridded,
    placeholder,
    scrollParent,
    options,
    format: format || (data => data.label),
    onChange,
    aria,
    inputRef,
    resultsRef,
    query,
    setQuery,
    active,
    setActive,
    selected,
    setSelected,
    selectedId,
    hide,
    fixedOptions,
    allOptions,
    value
  }
}

export const Input = ({ state, multiple, ...props }) => (
  <StyledInput
    ref={state.inputRef}
    gridded={state.gridded}
    placeholder={state.placeholder}
    width='100%'
    value={state.query}
    onChange={val => {
      if (!state.active) state.setActive(true)
      state.setQuery(val)
      state.setSelected(-1)
    }}
    onBlur={e => {
      e.preventDefault()
      const selected = e.target.value

      const selectedIndex = state.allOptions.findIndex(
        opt =>
          opt.id.toLowerCase() === selected.toLowerCase() ||
          (opt.abbr && opt.abbr.toLowerCase() === selected.toLowerCase())
      )
      selectedIndex !== -1 && !multiple
        ? state.onChange(state.allOptions[selectedIndex])
        : state.setSelected(-1)
    }}
    onFocus={() => state.setActive(true)}
    onKeyDown={e => {
      if (e.key === 'ArrowDown') {
        e.preventDefault()
        state.setSelected(
          state.selected === state.allOptions.length - 1
            ? -1
            : state.selected + 1
        )
      }
      if (e.key === 'ArrowUp') {
        e.preventDefault()
        state.setSelected(
          state.selected === -1
            ? state.allOptions.length - 1
            : state.selected - 1
        )
      }
      if (e.key === 'Escape') {
        if (state.active) {
          e.stopPropagation()
        }
        state.setQuery('')
        state.hide()
      }
      if (
        (e.key === 'Enter' || e.key === 'Tab' || e.key === ' ') &&
        state.selectedId
      ) {
        e.preventDefault()
        state.onChange(state.allOptions[state.selected])
        // TODO:: Set focus further down
      } else if (e.key === 'Tab') {
        state.hide()
      }
    }}
    id={state.id}
    role='combobox'
    aria-expanded={state.active}
    aria-haspopup='listbox'
    aria-labelledby={isObject(state.aria) ? state.aria.labelledby : state.aria}
    {...(state.aria?.describedby && {
      'aria-describedby': state.aria.describedby
    })}
    aria-autocomplete='list'
    aria-controls={`${state.id}-box`}
    aria-activedescendant={
      state.active && state.selectedId
        ? `${state.id}-option-${state.selectedId}`
        : ''
    }
    {...props}
  />
)

const StyledInput = styled(RawInput)`
  ${p =>
    p.gridded &&
    css`
      border: none;
      outline: none;
      padding: 0 16px 16px 40px;
      background: none;
      width: 100%;
    `}

  ${p =>
    !p.gridded &&
    css`
      padding-left: 32px;
    `}
`

export const Results = ({ state, position, multiple, ...props }) => {
  return (
    <LookupAreaInnerOuter width={position.width}>
      <LookupAreaInner
        left={position.left}
        ref={state.resultsRef}
        role='listbox'
        id={`${state.id}-box`}
        aria-label='Results'
        aria-multiselectable={multiple}
        {...props}
      />
    </LookupAreaInnerOuter>
  )
}

const LookupAreaInnerOuter = styled.div`
  position: absolute;
  height: 0;
  max-width: ${p => p.width}px;
  min-width: 150px;
`

const LookupAreaInner = styled.ul`
  padding: 0;
  margin: 0;
  list-style: none;
  position: relative;
  left: -${p => p.left}px;
  z-index: 1;
  min-width: 100%;
  box-shadow:
    0px 3px 3px rgba(26, 26, 26, 0.2),
    0px 2px 2px rgba(26, 26, 26, 0.12),
    0px 2px 0px rgba(26, 26, 26, 0.14);
  border-radius: 2px;
  max-height: 400px;
  overflow-y: auto;
`

export const Result = ({ state, value, isSelected, ...props }) => {
  const ref = React.useRef()
  const isFocused = state.selectedId === value.id
  React.useEffect(() => {
    if (isFocused && ref.current) {
      ref.current.scrollIntoView({ block: 'nearest', behavior: 'smooth' })
    }
  }, [isFocused])
  return (
    <li
      ref={ref}
      aria-selected={isSelected}
      selected={isSelected}
      role='option'
      id={`${state.id}-option-${value.id}`}
      onClick={() => state.onChange(value)}
      className={cx(
        'flex min-h-10 cursor-pointer items-center px-4 py-1 hover:bg-light-gray-200 dark:hover:bg-light-gray-300',
        {
          'bg-light-gray-100 dark:bg-light-gray-400': !isSelected,
          'bg-blue-100 font-medium': isSelected,
          '!bg-light-gray-200': isFocused
        }
      )}
      {...props}
    >
      <LookupOptionLabel>{state.format(value)}</LookupOptionLabel>
      <div className='h-4 w-4'>
        {isSelected && <Icons.Check className='h-4 w-4 fill-blue-500' />}
      </div>
    </li>
  )
}

const LookupOptionLabel = styled.span`
  width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
`

export const Chip = styled.div`
  background: #eee;
  display: inline-flex;
  align-items: center;
  border-radius: 16px;
  padding-left: 16px;
  overflow: hidden;
  max-width: 100%;
  html.dark & {
    // Outlier: dark:bg-light-gray-300
    background: #333;
  }
`

export const ChipLabel = styled.span`
  padding: 5px 0;
  max-width: calc(100% - 2.5rem);
  overflow: hidden;
  text-overflow: ellipsis;
`

const Divider = styled.div`
  height: 1px;
  background: #dddddd;
`
