import { i18n } from '@lingui/core'
import { Trans } from '@lingui/react'
import cx from 'clsx'
import { get } from 'lodash'
import React from 'react'
import shortid from 'shortid'

import { formbot } from '../..'
import { ModalPage } from '../../../components/modal-page'
import * as Icons from '../../../icons'
import { Option, Select } from '../../../ui/select'
import StaticFormbot from '../../static'

const gadgetTypes = [
  { key: 'Spacer', lbl: 'Rule Text' },
  { key: 'Text', lbl: 'Text' },
  { key: 'Number', lbl: 'Number' },
  { key: 'DataLookup', lbl: 'Data Lookup' },
  { key: 'DataMultiselect', lbl: 'Data Multiselect' }
]

export default function DecisionTree ({ tree, setTree, onClose, allGadgets }) {
  const [selectedNode, setSelectedNode] = React.useState(null)
  const handleNodeSelect = node =>
    setSelectedNode(selectedNode?.id === node?.id ? null : node)
  return (
    <ModalPage
      className='text-sm'
      title={i18n._('decision.tree')}
      onClose={() => onClose()}
    >
      <div className='flex h-full flex-col justify-start p-8'>
        <RenderTree
          node={tree}
          position={{ root: true, first: true, last: false }}
          updateTree={setTree}
          selectedNode={selectedNode}
          handleNodeSelect={handleNodeSelect}
        />
        {selectedNode?.id && (
          <Sidebar
            selectedNode={selectedNode}
            handleNodeSelect={handleNodeSelect}
            updateTree={setTree}
            tree={tree}
            allGadgets={allGadgets}
          />
        )}
      </div>
    </ModalPage>
  )
}

const RenderTree = ({
  node,
  position,
  updateTree,
  addSibling,
  selectedNode,
  handleNodeSelect
}) => {
  const addNode = () => {
    updateTree({
      ...node,
      children: [
        ...(node.children || []),
        {
          id: shortid.generate(),
          children: [],
          gadgets: [
            {
              id: shortid.generate(),
              formKey: shortid.generate(),
              type: 'Spacer'
            }
          ]
        }
      ]
    })
  }

  const removeNode = () => {
    updateTree()
  }

  const updateNode = () => {
    // change the value of the node
  }

  const moveNode = () => {
    // move the node
  }

  return (
    <div className='relative flex flex-row'>
      {!position.root && (
        <Node
          node={node}
          position={position}
          updateTree={updateTree}
          addNode={addNode}
          addSibling={addSibling}
          removeNode={removeNode}
          updateNode={updateNode}
          moveNode={moveNode}
          selectedNode={selectedNode}
          handleNodeSelect={handleNodeSelect}
        />
      )}
      <div className='flex flex-col justify-center'>
        {(node.children || []).map((child, index) => (
          <RenderTree
            node={child}
            position={{
              root: false,
              first: index === 0,
              childCount: node.children.length,
              last: index === node.children.length - 1
            }}
            updateTree={n => {
              if (n === undefined) {
                const newChildren = [...node.children]
                newChildren.splice(index, 1)
                updateTree({ ...node, children: newChildren })
                return
              }
              const newChildren = [...node.children]
              newChildren[index] = n
              updateTree({ ...node, children: newChildren })
            }}
            addSibling={addNode}
            selectedNode={selectedNode}
            handleNodeSelect={handleNodeSelect}
            key={child.id}
          />
        ))}
      </div>
    </div>
  )
}

const Node = ({
  node, // value
  position,
  updateTree, // onChange
  addNode,
  addSibling,
  moveNode,
  removeNode,
  selectedNode,
  handleNodeSelect
}) => {
  const { first, last, childCount } = position

  return (
    <div
      className={cx(
        'relative flex place-content-center p-8 before:absolute before:left-0 before:box-content before:border-l-2 before:border-medium-gray-100 after:absolute after:right-0 after:top-1/2 after:z-10 after:w-full after:-translate-y-1/2 after:border-b-2 after:border-medium-gray-100 after:bg-light-gray-300',
        {
          'before:top-1/2 before:h-1/2': first && !last,
          'before:bottom-1/2 before:h-1/2': last && !first,
          'before:top-0 before:h-full': !first && !last,
          'before:h-0': childCount === 1
        }
      )}
    >
      <button
        className='kp-button-sm kp-button-icon kp-button-solid absolute left-0 top-1/2 z-20 -translate-x-1/2 -translate-y-1/2 rounded-full text-center'
        onClick={() => addSibling('decision')}
      >
        <Icons.Add />
      </button>
      <div className='group relative z-20 flex items-center'>
        <div
          className={cx(
            'flex min-w-24 flex-col justify-start gap-1 overflow-auto rounded-xl border border-light-gray-300 bg-light-gray-300 transition-all',
            {
              'p-1': node.gadgets?.length > 1,
              'border-medium-gray-500 outline': selectedNode?.id === node.id
            }
          )}
          onClick={() => handleNodeSelect(node)}
        >
          {node.gadgets?.map((gadget, i) => (
            <NodeGadget
              gadget={gadget}
              key={i}
              showOr={i < node.gadgets.length - 1}
            />
          ))}
        </div>
        <button
          className='kp-button-icon kp-button-current absolute right-0 opacity-0 transition-all group-hover:opacity-100'
          onClick={removeNode}
        >
          <Icons.Delete />
        </button>
      </div>

      {node.children?.length === 0 && (
        <>
          <button
            className='kp-button-sm kp-button-icon kp-button-solid absolute left-full top-1/2 z-20 -translate-x-1/2 -translate-y-1/2 rounded-full'
            onClick={() => addNode()}
          >
            <Icons.Add />
          </button>
        </>
      )}
    </div>
  )
}

const NodeGadget = ({ gadget, showOr }) => {
  const gadgetDefinition = get(formbot, `context.gadgets.${gadget.type}`, {})

  const Icon = get(gadgetDefinition, 'meta.Icon', () => <Icons.WorkflowError />)

  return (
    <div className='relative flex h-max min-h-12 w-full items-center gap-3 rounded-xl border border-light-gray-300 bg-white px-4 py-2 text-base'>
      <Icon width={20} height={20} className='fill-medium-gray-500' />
      <span>{gadget.ruleText}</span>
      {showOr && (
        <span className='absolute left-1/2 top-full z-40 -translate-x-1/2 -translate-y-1/2 rounded-full bg-medium-gray-100 px-3 py-1 text-xs text-white dark:text-black'>
          <Trans id='or' />
        </span>
      )}
    </div>
  )
}

const Sidebar = ({
  selectedNode,
  handleNodeSelect,
  updateTree,
  tree,
  allGadgets
}) => {
  // todo :: jon :: clean up
  const { id } = selectedNode

  const nodeInTree = traverseTree(tree, id)

  const addGadget = () => {
    const newTree = { ...tree }

    addGadgetToNode(newTree, nodeInTree.path, {
      id: shortid.generate(),
      formKey: shortid.generate(),
      type: 'Spacer'
    })
    updateTree(newTree)
  }

  const changeGadget = (gadget, index) => {
    const newTree = { ...tree }

    replaceGadgetInNode(newTree, nodeInTree.path, [index], gadget)
    updateTree(newTree)
  }

  const deleteGadget = index => {
    const newTree = { ...tree }

    deleteGadgetInNode(newTree, nodeInTree.path, [index])
    updateTree(newTree)
  }

  return (
    <ModalPage
      darkTop
      side
      title='NODE SETTINGS'
      width='450px'
      closeAriaLabel={i18n._('close.settings.modal')}
      onClose={() => handleNodeSelect(null)}
    >
      <div className='relative flex w-full flex-col gap-2 py-4'>
        {selectedNode.gadgets.map((gadget, i) => (
          <GadgetConfig
            key={i}
            gadget={gadget}
            selectedNode={selectedNode}
            changeGadget={changeGadget}
            deleteGadget={deleteGadget}
            addGadget={addGadget}
            index={i}
            allGadgets={allGadgets}
          />
        ))}
      </div>
      <div>
        <button
          className='kp-button-outline ml-6 flex gap-2'
          onClick={addGadget}
        >
          <Icons.Add />
          <Trans id='add.option' />
        </button>
      </div>
    </ModalPage>
  )
}

const GadgetConfig = ({
  gadget,
  onChange,
  selectedNode,
  changeGadget,
  deleteGadget,
  index,
  allGadgets
}) => {
  const gadgetDefinition = get(formbot, `context.gadgets.${gadget.type}`)
  const RequiredConfig = get(gadgetDefinition, 'RequiredConfig')

  return (
    <div className='flex items-center gap-2 px-6'>
      <StaticFormbot
        value={gadget}
        update={newGadget => changeGadget(newGadget, index)}
      >
        {Gadgets => (
          <div className='flex flex-1 flex-col justify-between gap-2 rounded-md border border-light-gray-300 bg-white p-3'>
            <Select
              value={getSelectValue(gadget.type)}
              onChange={val => {
                const newGadget = {
                  ...gadget,
                  type: val
                }
                changeGadget(newGadget, index)
              }}
            >
              {gadgetTypes.map(({ key, lbl }) => (
                <Option key={key} value={key}>
                  {lbl}
                </Option>
              ))}
            </Select>
            <Gadgets.Text configKey='ruleText' label={i18n._('display.text')} />
            <div className='w-full'>
              <Gadgets.Custom
                configKey='details'
                defaultValue={{}}
                withGadgets
                unPadded
              >
                {({ onChange, value, Gadgets }) => (
                  <div>
                    {RequiredConfig && (
                      <RequiredConfig
                        onChange={onChange}
                        value={value}
                        label={gadget.type}
                        formKey={gadget.formKey}
                        Gadgets={Gadgets}
                        allGadgets={allGadgets}
                        updateDataLookupSource={patch =>
                          changeGadget({ ...gadget, ...patch }, index)
                        }
                        selectedLinks={[]}
                      />
                    )}
                  </div>
                )}
              </Gadgets.Custom>
            </div>
          </div>
        )}
      </StaticFormbot>
      <button onClick={() => deleteGadget(index)}>
        <Icons.Delete />
      </button>
    </div>
  )
}

const getSelectValue = type =>
  type?.includes('Multiselect')
    ? 'DataMultiselect'
    : type?.includes('Typeahead')
      ? 'DataLookup'
      : type

function addGadgetToNode (root, nodePath, newGadget, position = null) {
  let currentNode = root
  for (let i = 0; i < nodePath.length; i++) {
    currentNode = currentNode.children[nodePath[i]]
  }

  if (position === null) {
    currentNode.gadgets.push(newGadget)
  } else {
    currentNode.gadgets.splice(position, 0, newGadget)
  }
}

function replaceGadgetInNode (root, nodePath, gadgetPath, newGadget) {
  const targetNode = nodePath.reduce(
    (current, index) => current.children[index],
    root
  )

  const gadgetParent = gadgetPath
    .slice(0, -1)
    .reduce((current, index) => current[index].details, targetNode.gadgets)

  gadgetParent[gadgetPath[gadgetPath.length - 1]] = newGadget
}

function deleteGadgetInNode (root, nodePath, gadgetPath) {
  let currentNode = root
  for (let i = 0; i < nodePath.length; i++) {
    currentNode = currentNode.children[nodePath[i]]
  }

  let parentOfGadget = currentNode.gadgets
  for (let j = 0; j < gadgetPath.length; j++) {
    if (j === gadgetPath.length - 1) {
      parentOfGadget.splice(gadgetPath[j], 1)
    } else {
      parentOfGadget = parentOfGadget[gadgetPath[j]].details
    }
  }
}

function traverseTree (tree, id, path = []) {
  if (tree.id === id) {
    return { node: tree, path }
  }
  if (tree.children) {
    for (let index = 0; index < tree.children.length; index++) {
      const child = tree.children[index]
      const result = traverseTree(child, id, [...path, index])
      if (result) {
        return result
      }
    }
  }
  return null
}
