/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import { i18n } from '@lingui/core'
import { Trans } from '@lingui/react'
import cx from 'clsx'
import React from 'react'
import { NavLink, useLocation, useNavigate } from 'react-router-dom'

import WorkflowStatus from '../formbot/gadgets/workflow-status/view'
import * as Icons from '../icons'
import { useAlerts } from '../ui/alerts'
import { Popover2 } from '../ui/popover'
import { useAddNewVersionMutation } from './mutation.add-new-version'
import { Tooltip, TooltipTrigger } from './new-tooltip'

export default ({ document, onNewVersionLoading, routeIdentifierCallback }) => {
  const alerts = useAlerts()
  const navigate = useNavigate()
  const location = useLocation()
  const [addNewVersion] = useAddNewVersionMutation(location.pathname)
  const [disableAddNewVersion, setDisableAddNewVersion] = React.useState(
    !document?.dataset?.allowNewVersions || document?.hasIncompleteVersion
  )

  return (
    <Popover2
      role='menu'
      trigger={
        <button className='flex w-96 items-center rounded border border-solid border-light-gray-300 bg-white px-2 py-1 text-sm max-md:w-[90vw]'>
          <VersionTitle
            meta={document?.meta}
            simulation={document?.workflow?.simulation}
          />
          <div className='flex-1' />
          <WorkflowStatus value={document?.meta?.workflowStatus} />
          <Icons.HtmlSelect className='ml-2' />
        </button>
      }
      top='30'
      right='-23px'
    >
      {close => (
        <div className='flex w-96 flex-col justify-between max-md:w-[90vw]'>
          <div className='max-h-[210px] w-full flex-1 overflow-y-auto overflow-x-hidden'>
            <div className='sr-only' id='Versions-list'>
              <Trans
                id='you.have.length.versions'
                values={{ length: document?.versions?.length }}
              />
            </div>
            {document?.versions?.map(version => (
              <NavLink
                onClick={close}
                role='menuitem'
                key={version?.id}
                to={routeIdentifierCallback(version)}
              >
                <div
                  className={cx(
                    'flex w-full items-center px-3 py-2 text-sm hover:bg-light-gray-100',
                    {
                      'bg-light-gray-200 font-medium hover:bg-light-gray-200':
                        version.id === document?.id
                    }
                  )}
                >
                  <VersionTitle
                    meta={version?.meta}
                    simulation={version?.workflow?.simulation}
                  />
                  <div className='flex-1' />
                  <WorkflowStatus value={version?.meta?.workflowStatus} />
                </div>
              </NavLink>
            ))}
          </div>
          {document?.viewer?.canCreateVersion &&
            location.pathname.includes('/document-list') &&
            onNewVersionLoading && (
              <TooltipTrigger
                as='button'
                className='relative flex items-center gap-2 border-t border-light-gray-300 p-2 text-sm hover:bg-light-gray-100 disabled:text-light-gray-400'
                onClick={async () => {
                  onNewVersionLoading(true)
                  close()
                  setDisableAddNewVersion(true)
                  const {
                    data: { addNewVersion: newVersion }
                  } = await addNewVersion(
                    document?.documentSetId,
                    Intl.DateTimeFormat().resolvedOptions().timeZone
                  )
                  switch (newVersion.__typename) {
                    case 'Redirect': {
                      const actionId = newVersion?.newActionId
                      const to = location.pathname.includes('versions')
                        ? `../../../${actionId}/action`
                        : `../../${actionId}/action`
                      navigate(to, { state: { disableAnimation: true } })
                      break
                    }
                    default: {
                      alerts.type2(
                        `${
                          newVersion?.reason ??
                          'An error occurred when making a new version'
                        }`,
                        'error'
                      )
                      onNewVersionLoading(false)
                      break
                    }
                  }
                }}
                disabled={disableAddNewVersion}
              >
                <Icons.Add
                  className={cx(disableAddNewVersion && 'fill-light-gray-400')}
                />
                <Trans id='create.new.version' message='Create new version' />
                {disableAddNewVersion && (
                  <Tooltip>
                    <Trans
                      id='unavailable.while.in.draft.state'
                      message='Unavailable while a version is in draft or in-progress state.'
                    />
                  </Tooltip>
                )}
              </TooltipTrigger>
            )}
        </div>
      )}
    </Popover2>
  )
}

export function DocumentStatus ({ meta, simulation }) {
  return (
    <div className='flex w-full max-w-96 items-center rounded py-1 text-sm max-md:max-w-[90vw]'>
      <VersionTitle meta={meta} simulation={simulation} />
      <WorkflowStatus value={meta?.workflowStatus} />
    </div>
  )
}

function VersionTitle ({ meta, simulation }) {
  if (!meta) return null
  let date
  let leadingWord = meta?.workflowStatus

  switch (meta?.workflowStatus) {
    case 'Complete':
      leadingWord = i18n._({
        id: 'completed',
        message: 'Completed'
      })
      date = simulation?.steps?.at(-1)?.completedAt
      break
    case 'In Progress':
      leadingWord = i18n._({
        id: 'submitted',
        message: 'Submitted'
      })
      date = meta?.submittedAt
      break
    case 'Denied':
      date = simulation?.steps?.at(-1)?.completedAt
      break
    case 'Draft':
      leadingWord = i18n._({
        id: 'created.at',
        message: 'Created at'
      })
      date = meta?.createdAt
      break
    case 'Withdrawn':
      if (simulation) {
        date = simulation?.steps?.at(-1)?.completedAt
        break
      } else {
        date = meta?.createdAt + meta?.workflowTotalRuntime
        leadingWord = i18n._({
          id: 'withdrawn',
          message: 'Withdrawn'
        })
        break
      }
    case 'Error':
      leadingWord = i18n._({
        id: 'error',
        message: 'Error'
      })
      date = simulation?.steps?.findLast(
        step => step.status === 'Error'
      )?.startedAt
      break
    default:
      leadingWord = i18n._({
        id: 'submitted',
        message: 'Submitted'
      })
      date = meta?.submittedAt
      break
  }

  return (
    <div className='mr-2 truncate overflow-ellipsis'>
      {meta?.versionTitle && meta?.versionTitle !== '--'
        ? meta.versionTitle
        : `${date && leadingWord} ${formatDate(date)}`}
    </div>
  )
}

function formatDate (date) {
  if (!date) return null
  const first = new Intl.DateTimeFormat(window.navigator.language, {
    month: 'short',
    day: 'numeric',
    year: 'numeric'
  }).format(+new Date(date))
  const second = new Intl.DateTimeFormat(window.navigator.language, {
    hour: 'numeric',
    minute: 'numeric'
  }).format(+new Date(date))

  return `${first} · ${second}`
}
