/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import { Trans } from '@lingui/react'
import {
  compact,
  concat,
  debounce,
  find,
  get,
  includes,
  isArray,
  isEmpty,
  isNil,
  isNumber,
  isObject,
  map,
  mapValues,
  pickBy,
  reject
} from 'lodash'
import React from 'react'

import Tooltip, { TooltipTrigger } from '../components/tooltip'
import * as Icons from '../icons'
import Input from '../ui/input'
import { Popover2 } from '../ui/popover'
import Radios from '../ui/radios'
import { Option, Select } from '../ui/select'
import { useFormbotData, useIdFormkeyMap } from './engine/formbot-react/hooks'

const defaultSubFields = [
  {
    path: 'label',
    label: 'Label',
    type: 'Text',
    details: {}
  },
  {
    path: 'id',
    label: 'ID',
    type: 'Text',
    details: {}
  }
]

export function subFields ({ id, formKey, label, details }, isMulti = false) {
  const outputFields = isArray(details?.outputFields)
    ? details.outputFields
    : []
  const fields = concat(defaultSubFields, outputFields)
  return compact(
    map(fields, field => {
      const newFormKey = `${formKey}${isMulti ? '.**' : ''}.${field.path}`
      const baseObject = {
        id: `${id}.${field.path}`,
        type: field.type,
        formKey: newFormKey,
        label: `${label} - ${field.label}`,
        subFieldLabel: field.label,
        details: field.details || {},
        autoUpdate: !!details?.autoUpdate?.enabled,
        childrenTemplate: field.childrenTemplate,
        unsortable: true
        // These are unsortable because the data referenced in output fields is
        // stored as a raw string, so the database can't sort or filter on any
        // of those fields. @see https://kualico.atlassian.net/browse/PLT-467
      }

      return pickBy(baseObject, value => value !== null && value !== undefined)
    })
  )
}

export function getOutputFields (integration) {
  if (!integration) return null
  if (!integration.__outputs?.length) return null
  return compact(
    integration.__outputs.map(output => {
      if (!output.__dataPath || !output.__gadget?.type?.id) return null
      const result = {
        label: output.__label,
        path: `data.${output.__dataPath}`,
        type: output.__gadget?.type?.id
      }
      if (!isEmpty(output.__gadget?.details)) {
        result.details = output.__gadget?.details
      }
      return result
    })
  )
}

export function collectInputs (data) {
  if (!data) return []
  let inputs = [
    ...map(data.__urlInputs, input => ({ ...input, type: 'url' })),
    ...map(data.__queryParameterInputs, input => ({ ...input, type: 'query' })),
    ...map(data.__requestBodyInputs, input => ({
      ...input,
      type: 'requestBody'
    }))
  ]
  inputs = inputs.filter(input => input.__dataPath && input.__label)
  let searchId = data.__searchParameter?.id ?? ''
  searchId = searchId.replace(/^(url|query):/, '')
  if (searchId) inputs = reject(inputs, { __dataPath: searchId })
  return inputs.map(input => ({
    ...input,
    required: input.type === 'url' || !!find(input.__required, { id: 'yes' })
  }))
}

export function getInputFields (data) {
  return collectInputs(data).reduce(
    (data, input) => ({
      ...data,
      [input.__dataPath]: {
        type: 'form',
        required: input.required,
        value: null
      }
    }),
    {}
  )
}

export function getSearchParameter (data) {
  if (!data) return null
  const { __urlInputs, __queryParameterInputs, __searchParameter } = data
  if (!__searchParameter?.id) return null
  const [type, ...parts] = __searchParameter.id.split(':')
  const __dataPath = parts.join(':')
  const inputs = collectInputs({ __urlInputs, __queryParameterInputs })
  const input = find(inputs, { type, __dataPath })
  if (!input) return null
  return { id: __searchParameter.id, required: input.required }
}

export function useInputDefinitions (
  integration,
  allGadgets,
  siblingGadgets,
  forWorkflow
) {
  return React.useMemo(() => {
    if (!integration) return []
    const gadgets = [...(allGadgets || []), ...(siblingGadgets || [])]

    return collectInputs(integration.data).map(input => ({
      dataPath: input.__dataPath,
      label: input.__label,
      gadgets: determineGadgets(input, gadgets, forWorkflow),
      required: input.required,
      type: input.type
    }))
  }, [integration, allGadgets, siblingGadgets])
}

const TYPES = [
  'Text',
  'Email',
  'Number',
  'Currency',
  'Url',
  'Textarea',
  'CountryDropdown',
  'LanguagesDropdown',
  'StateDropdown',
  'LinearScale',
  'Date',
  'TimePicker'
]

const ADDITIONAL_TYPES = [
  'Radios',
  'Dropdown',
  'RichText',
  'Checkboxes',
  'UserTypeahead',
  'GroupTypeahead',
  'FormTypeahead',
  'FormMultiselect',
  'UserMultiselect',
  'GroupMultiselect',
  'IntegrationFill',
  'IntegrationTypeahead',
  'IntegrationMultiselect'
]

function determineGadgets (input, allGadgets, forWorkflow = false) {
  let types = TYPES
  if (!forWorkflow) {
    types = [...TYPES, ...ADDITIONAL_TYPES]
  }
  const textGadgets = allGadgets.filter(gadget => {
    return includes(types, gadget.type)
  })
  const idLabelListGadgets = allGadgets.filter(gadget =>
    includes(
      [
        'FormMultiselect',
        'UserMultiselect',
        'GroupMultiselect',
        'IntegrationMultiselect'
      ],
      gadget.type
    )
  )
  // Advanced integrations use '__type' while basic integrations use 'type'
  if (input.__type === 'json') return idLabelListGadgets
  const inputGadgetType = input?.__gadget?.type?.id
  if (['url', 'query'].includes(input.type) || inputGadgetType === 'Text') {
    return textGadgets
  }
  return inputGadgetType
    ? allGadgets.filter(gadget => gadget.type === inputGadgetType)
    : []
}

function getFormValue (formData, idMap, id, rowIndex) {
  if (!id) return null
  let key = idMap?.[id]
  if (key?.includes('.*.')) {
    key = key.replace('.*.', `.${rowIndex}.`)
  }
  if (key?.includes('.**.')) {
    return getFormValueIterable(formData, key)
  }
  return get(formData, key, null)
}

function getFormValueIterable (formData, key) {
  const [firstKey, secondKey] = key.split('.**.')
  const valuesArr = get(formData, firstKey, [])
  return valuesArr?.map(value => {
    return get(value, secondKey, null)
  })
}

export function useInputs ({ inputFields, rowIndex }) {
  const formData = useFormbotData()
  const idMap = useIdFormkeyMap()
  const inputs = React.useMemo(() => {
    let validInputs = true
    const inputs = mapValues(inputFields, source => {
      const value =
        source?.type === 'static'
          ? source.value
          : extractText(
              getFormValue(formData, idMap, source?.value?.id, rowIndex),
              source?.options
            )
      const empty = value == null || value === ''
      if (empty && source?.required) validInputs = false
      return value
    })
    if (!validInputs) return null
    return inputs
  }, [formData, idMap, inputFields, rowIndex])
  return inputs
}

export const formatValue = (value, byFormKey) => {
  if (byFormKey) return value?.formKey
  const id = value?.id
  return id?.startsWith('data.') ? id.replace('data.', '') : id
}

const getValue = (gadget, byFormKey) => {
  if (!gadget) return
  const { type, id, formKey } = gadget
  if (byFormKey) return { type, formKey }
  return {
    type,
    id: formKey.startsWith('data.') ? `data.${id}` : id
  }
}

const DEFAULT_CONCAT_VALUE = ','
const extractText = (formValue, options) => {
  if (isNil(formValue)) return
  if (isNumber(formValue)) return formValue.toString()
  if (isArray(formValue)) {
    return formValue
      .map(value => extractText(value, options))
      .filter(item => item || item === 0)
      .join(
        isNil(options?.concatValue)
          ? DEFAULT_CONCAT_VALUE
          : options?.concatValue
      )
  }
  if (isObject(formValue)) {
    if (options?.gadgetToUse) {
      return get(formValue, options.gadgetToUse, null)
    }
    return formValue.label
  }
  return formValue
}

export function SelectGadgetInput ({
  Gadgets,
  input,
  value,
  onChange,
  byFormKey
}) {
  return (
    <Gadgets.Padded>
      <Gadgets.Label htmlFor={`inputSources.${input.dataPath}`}>
        {input.label} {input.required && '*'}
      </Gadgets.Label>
      <Radios
        pt={1}
        id={`inputSources.${input.dataPath}`}
        value={value?.type || 'form'}
        onChange={type => {
          if (value?.type === type) return
          onChange({ type, value: type === 'form' ? null : '' })
        }}
        options={[
          {
            htmlId: `form.${input.dataPath}`,
            id: 'form',
            label: (
              <div>
                <div>
                  <Trans id='from.data.in.form' />
                </div>
                {value?.type !== 'static' && (
                  <GadgetDropdown
                    Gadgets={Gadgets}
                    fields={input.gadgets}
                    value={value}
                    onChange={onChange}
                    required={input.required}
                    byFormKey={byFormKey}
                  />
                )}
              </div>
            ),
            ariaLabel: <Trans id='from.data.in.form' />
          },
          {
            htmlId: `static.${input.dataPath}`,
            id: 'static',
            label: (
              <div>
                <div>
                  <Trans id='fixed.value.type.here' />
                </div>
                {value?.type === 'static' && (
                  <StaticInput
                    input={input}
                    value={value}
                    onChange={onChange}
                  />
                )}
              </div>
            ),
            ariaLabel: <Trans id='fixed.value' />
          }
        ]}
      />
    </Gadgets.Padded>
  )
}

const StaticInput = ({ input, value, onChange }) => {
  const [inputValue, setInputValue] = React.useState(value?.value || '')

  const debouncedOnChange = React.useCallback(
    debounce(newValue => {
      onChange({
        type: 'static',
        required: input.required,
        value: newValue
      })
    }, 250),
    [onChange, input.required]
  )

  const handleInputChange = newValue => {
    setInputValue(newValue)
    debouncedOnChange(newValue)
  }

  return (
    <Input
      title={value?.value}
      onChange={handleInputChange}
      value={inputValue}
      className='!w-full'
    />
  )
}

function GadgetDropdown ({
  Gadgets,
  fields,
  value,
  onChange,
  required,
  byFormKey
}) {
  const { metaGadgets, dataGadgets, autoUpdateGadgets, subFieldGadgets } =
    groupGadgets(fields)

  const selectedGadget = getSelectedGadget(value, fields, byFormKey)
  const subFields =
    subFieldGadgets[formatValue(selectedGadget, byFormKey)] || []

  const onSelect = id => {
    const search = byFormKey ? { formKey: id } : { id }
    const gadget = find(fields, search)
    const gadgetValue = getValue(gadget, byFormKey)
    const newValue = {
      type: 'form',
      required,
      value: gadgetValue
    }
    if (gadget?.options) {
      newValue.options = gadget.options
    }
    const newSubFields = subFieldGadgets[formatValue(gadget, byFormKey)] || []
    if (!byFormKey && newSubFields.length) {
      const newGadget = newSubFields[0]
      const newValueToUse = getValue(newGadget, byFormKey)
      newValue.value = newValueToUse
    }
    onChange(newValue)
  }

  return (
    <div className='relative flex items-center gap-1'>
      <Select
        onChange={onSelect}
        value={formatValue(selectedGadget, byFormKey)}
      >
        <Option value=''>- - -</Option>
        {dataGadgets?.length && (
          <optgroup label='Form Fields'>
            {dataGadgets.reverse().map(gadget => {
              const customName =
                get(gadget, 'customName.enabled') &&
                get(gadget, 'customName.value')
              return (
                <Option
                  key={gadget.id}
                  value={byFormKey ? gadget.formKey : gadget.id}
                >
                  {customName || gadget.label} ({gadget.formKey})
                </Option>
              )
            })}
          </optgroup>
        )}
        {metaGadgets?.length && (
          <optgroup label='Metadata'>
            {metaGadgets.map(gadget => {
              const customName =
                get(gadget, 'customName.enabled') &&
                get(gadget, 'customName.value')
              return (
                <Option
                  key={gadget.id}
                  value={byFormKey ? gadget.formKey : gadget.id}
                >
                  {customName || gadget.label} ({gadget.formKey})
                </Option>
              )
            })}
          </optgroup>
        )}
        {autoUpdateGadgets?.length && (
          <optgroup label='Unavailable'>
            {autoUpdateGadgets.map(gadget => {
              const customName =
                get(gadget, 'customName.enabled') &&
                get(gadget, 'customName.value')
              return (
                <Option
                  key={gadget.id}
                  value={byFormKey ? gadget.formKey : gadget.id}
                  disabled
                >
                  {customName || gadget.label} ({gadget.formKey})
                </Option>
              )
            })}
          </optgroup>
        )}
      </Select>
      {!byFormKey &&
        (subFields.length || gadgetInputMap[selectedGadget?.type]) && (
          <GadgetInputConfig
            Gadgets={Gadgets}
            onChange={onChange}
            selectedValue={value}
            selectedGadget={selectedGadget}
            byFormKey={byFormKey}
            required={required}
            subFields={subFields}
          />
        )}
    </div>
  )
}

function groupGadgets (gadgets) {
  return gadgets.reduce(
    (acc, gadget) => {
      if (gadget.subField) {
        if (!acc.subFieldGadgets[gadget.parent]) {
          acc.subFieldGadgets[gadget.parent] = []
        }
        acc.subFieldGadgets[gadget.parent].push(gadget)
      } else if (gadget.id === 'id' || gadget.id.startsWith('meta.')) {
        acc.metaGadgets.push(gadget)
      } else if (gadget.autoUpdate || gadget.details?.autoUpdate?.enabled) {
        acc.autoUpdateGadgets.push(gadget)
      } else {
        acc.dataGadgets.push(gadget)
      }
      return acc
    },
    {
      metaGadgets: [],
      dataGadgets: [],
      autoUpdateGadgets: [],
      subFieldGadgets: {}
    }
  )
}

function getSelectedGadget (value, gadgets, byFormKey) {
  const valueId = formatValue(value?.value, byFormKey)
  const valueGadget = gadgets?.find(
    gadget => formatValue(gadget, byFormKey) === valueId
  )

  const parentGadget =
    !byFormKey && valueGadget?.parent
      ? gadgets?.find(gadget => gadget.id === valueGadget.parent)
      : valueGadget

  return parentGadget || valueGadget
}

const GadgetInputConfig = ({
  Gadgets,
  selectedGadget,
  onChange,
  selectedValue,
  byFormKey,
  required,
  subFields
}) => {
  const Config = gadgetInputMap[selectedGadget?.type]
  return (
    <Popover2
      role='menu'
      trigger={
        <div className='kp-button-transparent kp-button-sm kp-button-icon'>
          <Icons.Settings />
        </div>
      }
      right={16}
      top={16}
    >
      {hide => (
        <div className='min-2-48 flex h-max w-max max-w-72 flex-col gap-3 p-4'>
          {subFields?.length > 0 && (
            <SubFieldInputConfig
              Gadgets={Gadgets}
              onChange={onChange}
              selectedValue={selectedValue}
              required={required}
              byFormKey={byFormKey}
              subFields={subFields}
            />
          )}
          {Config && (
            <Config
              Gadgets={Gadgets}
              onChange={onChange}
              selectedValue={selectedValue}
              required={required}
            />
          )}
        </div>
      )}
    </Popover2>
  )
}

const SubFieldInputConfig = ({
  Gadgets,
  onChange,
  selectedValue,
  required,
  byFormKey,
  subFields
}) => {
  return (
    <div>
      <Gadgets.Label
        htmlFor='valueToUse'
        className='mb-1 dark:text-medium-gray-100'
      >
        Value to use:
        <TooltipTrigger
          as={Icons.AlertHelp}
          tooltipId='value-to-use-input-config'
          className='ml-1 inline-block'
        />
        <Tooltip id='value-to-use-input-config' place='left' className='w-48'>
          This value has multiple attributes. Select which one to use.
        </Tooltip>
      </Gadgets.Label>
      <Radios
        label='Value to use'
        id='valueToUse'
        options={subFields?.map(subField => {
          return {
            id: subField.id,
            label: subField.subFieldLabel || subField.label
          }
        })}
        autoFocus
        onChange={val => {
          const subField = find(subFields, { id: val })
          const subFieldValue = getValue(subField, byFormKey)

          const newVal = {
            type: 'form',
            required,
            value: subFieldValue
          }
          if (selectedValue?.options) {
            newVal.options = selectedValue.options
          }
          onChange(newVal)
        }}
        value={formatValue(selectedValue?.value)}
        className='ml-1'
      />
    </div>
  )
}

const ArrayInputConfig = ({ Gadgets, onChange, selectedValue, required }) => {
  const [inputValue, setInputValue] = React.useState(
    selectedValue?.options?.concatValue || DEFAULT_CONCAT_VALUE
  )

  const debouncedOnChange = React.useCallback(
    debounce(newValue => {
      onChange({
        value: selectedValue.value,
        type: 'form',
        required,
        options: { ...selectedValue.options, concatValue: newValue }
      })
    }, 800),
    [onChange, selectedValue.value, selectedValue.options]
  )

  const handleInputChange = newValue => {
    setInputValue(newValue)
    debouncedOnChange(newValue)
  }
  return (
    <div>
      <Gadgets.Label
        htmlFor='concatValue'
        className='mb-1 dark:text-medium-gray-100'
      >
        Join values with:
        <TooltipTrigger
          as={Icons.AlertHelp}
          tooltipId='concat-value-input-config'
          className='ml-1 inline-block'
        />
        <Tooltip id='concat-value-input-config' place='left' className='w-48'>
          This value is a list of values. This determines how they are joined
          together.
        </Tooltip>
      </Gadgets.Label>
      <Input id='concatValue' onChange={handleInputChange} value={inputValue} />
    </div>
  )
}

const gadgetInputMap = {
  Checkboxes: ArrayInputConfig,
  GroupMultiselect: ArrayInputConfig,
  FormMultiselect: ArrayInputConfig,
  UserMultiselect: ArrayInputConfig,
  IntegrationMultiselect: ArrayInputConfig
}
