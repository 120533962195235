/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import { i18n } from '@lingui/core'
import { cloneDeep, filter, forEachRight, get } from 'lodash'
import React from 'react'

export function getSendBackData (wf, action) {
  if (action?.details?.sendBackData) return action.details.sendBackData
  if (!wf || !wf.steps) return null
  const nonSimulated = filter(wf.steps, st => st.simulated !== true)
  nonSimulated.pop()
  let sendBackData = null
  forEachRight(nonSimulated, step => {
    const users = get(step, 'group.users', step.users)
    forEachRight(users, user => {
      if (
        get(user, 'workflowAction.action', '').toLowerCase() === 'sent back'
      ) {
        let from = step.name || step.stepName
        if (get(step, 'group.role')) from += ` - ${step.group.name}`
        sendBackData = {
          from,
          comment: user.workflowAction.comment,
          date: user.workflowAction.date
        }
        return false
      }
    })
    if (sendBackData) return false
  })
  return sendBackData
}

export const useWorkflowOverview = simulation => {
  const [workflowOverview, setWorkflowOverview] = React.useState({
    status: 'Loading',
    step: []
  })

  function updateWorkflowOverview ({ simulation }) {
    try {
      const wf = simulation
        ? cloneDeep(simulation)
        : { status: 'Unknown', steps: [] }
      wf.status = wf.status === 'Rejected' ? 'Denied' : wf.status
      setWorkflowOverview(wf)
    } catch (err) {
      console.error(
        i18n._({
          id: 'pagesrunner.workflow.tracker.failed',
          message: 'Failed to get workflow history'
        }),
        err
      )
      setWorkflowOverview(undefined)
    }
  }

  React.useEffect(() => {
    updateWorkflowOverview({ simulation })
  }, [])

  return [workflowOverview, updateWorkflowOverview]
}
