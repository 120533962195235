/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import { find, map, reject } from 'lodash'
import React from 'react'
import styled from 'styled-components'

import { useScrollParent } from '../formbot/gadgets/table/scroll-parent'
import * as Icons from '../icons'
import Button from '../ui/button'
import { Space } from '../ui/layout'
import * as Lookup from '../ui/lookup'
import Spinner from './spinner'

export function Multiselect ({
  standalone,
  hideResults,
  value,
  onChange,
  onAdd,
  onRemove,
  options,
  loading,
  className,
  chipStyle,
  ...props
}) {
  const scrollParent = useScrollParent()
  const format = props.format || (data => data.label)
  const lookup = Lookup.useLookup({
    ...props,
    scrollParent,
    value,
    options,
    format,
    onChange: option => {
      props.setQuery('')
      if (find(value, { id: option.id })) {
        onRemove?.(option.id)
        onChange?.(reject(value, { id: option.id }))
      } else {
        onChange?.([...reject(value, { id: option.id }), option])
        onAdd?.({ id: option.id, label: option.label })
      }
    }
  })
  const component = (
    <Lookup.Control
      state={lookup}
      className={className}
      darkModeDarkerBg={!props.gridded && !props.noDarkerModeDarkerBg}
      loader={
        loading && <PositionedSpinner gridded={props.gridded} size={16} />
      }
      multiple
    >
      {!hideResults && (
        <>
          {!!value?.length && <Space h='8px' />}
          <Wrapper gridded={props.gridded}>
            {map(value, option => (
              <SpacedChip
                key={option.id}
                style={chipStyle}
                className='dark:border-2 dark:border-solid dark:border-light-gray-400 dark:bg-light-gray-300'
              >
                <Lookup.ChipLabel>{format(option)}</Lookup.ChipLabel>
                <StyledButton
                  aria-label={`remove ${format(option)}`}
                  transparent
                  onClick={e => {
                    e.preventDefault()
                    onChange?.(reject(value, { id: option.id }))
                    onRemove?.(option.id)
                  }}
                >
                  <Icons.Close width='8px' height='8px' />
                </StyledButton>
              </SpacedChip>
            ))}
          </Wrapper>
        </>
      )}
    </Lookup.Control>
  )
  return standalone ? <div className='relative'>{component}</div> : component
}

const SpacedChip = styled(Lookup.Chip)`
  margin-right: 4px;
  margin-bottom: 4px;
`

export function Typeahead ({
  value,
  onChange,
  loading,
  className,
  disabled,
  ...props
}) {
  const scrollParent = useScrollParent()
  const format = props.format || (data => data.label)
  const lookup = Lookup.useLookup({
    ...props,
    scrollParent,
    format,
    onChange: option => {
      props.setQuery('')
      onChange(option)
    }
  })
  const hiddenInputRef = React.useRef()
  React.useEffect(() => {
    if (value) {
      hiddenInputRef.current?.focus()
    }
  }, [Boolean(value)])
  if (!value) {
    return (
      <Lookup.Control
        state={lookup}
        disabled={disabled}
        className={className}
        darkModeDarkerBg={!props.gridded}
        loader={
          loading && <PositionedSpinner gridded={props.gridded} size={16} />
        }
      />
    )
  }
  // What's with the hidden input?
  // Before this was added, as soon as an option was selected, the input would
  // disappear and focus would return to the document body. This behavior was
  // a problem for keyboard users because they would then have to tab all the
  // way back to the current gadget. This hidden input gives a place for focus
  // to go. It also allows screen readers to read the current value of the gadget,
  // which also wasn't happening before.
  return (
    <Wrapper gridded={props.gridded}>
      <input
        ref={hiddenInputRef}
        readOnly
        value={format(value)}
        className='peer sr-only'
        aria-labelledby={props.aria?.labelledby}
        onKeyDown={e => {
          if (e.key === 'Backspace') {
            onChange(null)
          }
        }}
      />
      <Lookup.Chip className='peer-focus-visible:ring-2'>
        <Lookup.ChipLabel>{format(value)}</Lookup.ChipLabel>
        <Button
          aria-label='clear value'
          transparent
          onClick={() => onChange(null)}
        >
          <Icons.Close width='8px' height='8px' />
        </Button>
      </Lookup.Chip>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  padding: ${p => (p.gridded ? '0 16px 16px' : 0)};
`

const PositionedSpinner = styled(Spinner)`
  position: absolute;
  right: ${p => (p.gridded ? '16px' : '8px')};
  top: ${p => (p.gridded ? 0 : '8px')};
`

const StyledButton = styled(Button)`
  border-radius: 4px 16px 16px 4px;
  margin-left: 4px;
`
