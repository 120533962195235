/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import { gql } from '@apollo/client'
import { i18n } from '@lingui/core'
import { Trans } from '@lingui/react'
import {
  filter,
  flatMap,
  get,
  isEmpty,
  keyBy,
  map,
  some,
  startCase
} from 'lodash'
import React from 'react'
import { Link } from 'react-router-dom'
import shortid from 'shortid'

import { productBuilder } from '../../components/feature-flags'
import { LoadingPage } from '../../components/loading'
import { GraphQLError as Error } from '../../components/system-error'
import Tooltip, { TooltipTrigger } from '../../components/tooltip'
import { useDocumentTitle } from '../../components/use-document-title'
import { useIds } from '../../components/use-ids'
import { useQuery } from '../../components/use-query'
import { validate } from '../../flowbot'
import { formbot } from '../../formbot'
import { utils as fb } from '../../formbot/engine/formbot'
import * as Icons from '../../icons'
import { useAlerts } from '../../ui/alerts'
import Checkbox from '../../ui/checkbox'
import AnimatedOutlet from '../app-modals-outlet'
import * as InfoBox from './components/info-box'
import { useDiscardDraftMutation } from './components/mutation.discard-draft'
import { usePublishAppMutation } from './components/mutation.publish-app'

export default function PublishOuter () {
  return (
    <>
      <AnimatedOutlet />
      <Publish />
    </>
  )
}

function Publish () {
  useDocumentTitle('Publish')
  const { appId, datasetId } = useIds()
  const q = getPublishPageQuery(appId, datasetId)
  const { loading, error, data, refetch } = useQuery(q)
  return loading ? (
    <LoadingPage />
  ) : error ? (
    <Error error={error} />
  ) : (
    <PublishInner data={data} q={q} reload={refetch} />
  )
}

const isUnsetDataGadget = value =>
  ['DataLookup', 'DataMultiselect', 'DataFill'].includes(value.type)

const termsSourceUnset = gadget => !gadget.details
const termsConfigMissingEndDate = gadget => !gadget.details?.endDate
const termsConfigMissingStartDate = gadget => !gadget.details?.startDate
const termsConfigMissingPreviousTerm = gadget => !gadget.details?.previousTerm

const hasMissingConditionalVisibilityFields = (value, schema) => {
  if (!value.conditionalVisibility?.enabled) return false
  return some(get(value, 'conditionalVisibility.value.parts', []), part => {
    return !schema[part.formKey]
  })
}

function validateForm (schema, template, gadgetIndexTypes, isProductTemplate) {
  schema = fb.expandTableColumns(schema, formbot, (gadget, parent) => {
    if (!parent) return gadget
    return { ...gadget, id: `${parent.id}.${gadget.id}` }
  })
  const isNew = gadget => {
    if (gadget.type === 'DataLink') return false
    if (!gadget.formKey?.startsWith('data.')) return false
    const formKey = gadget.formKey.replace('data.', '')
    if (
      formKey
        .replaceAll('.data.*.data.', '')
        .replaceAll('.*.', '')
        .includes('.')
    ) {
      return false
    }
    return !gadgetIndexTypes[formKey]
  }
  const schemaByFormKey = keyBy(schema, 'formKey')
  const allErrors = flatMap(schema, gadget => {
    const errors = []
    if (isUnsetDataGadget(gadget)) {
      errors.push({
        id: shortid.generate(),
        gadgetName: gadget.label,
        gadgetId: gadget.id,
        error: `${i18n._('pagesbuilder.publish.missing.datasource')}`
      })
    }
    if (gadget.type === 'Terms') {
      if (termsSourceUnset(gadget)) {
        errors.push({
          id: shortid.generate(),
          gadgetName: gadget.label,
          gadgetId: gadget.id,
          error: `${i18n._('pagesbuilder.publish.missing.datasource')}`
        })
      } else {
        if (termsConfigMissingEndDate(gadget)) {
          errors.push({
            id: shortid.generate(),
            gadgetName: gadget.label,
            gadgetId: gadget.id,
            error: `${i18n._('pagesbuilder.publish.missing.termsconfig.end')}`
          })
        }
        if (termsConfigMissingStartDate(gadget)) {
          errors.push({
            id: shortid.generate(),
            gadgetName: gadget.label,
            gadgetId: gadget.id,
            error: `${i18n._('pagesbuilder.publish.missing.termsconfig.start')}`
          })
        }
        if (termsConfigMissingPreviousTerm(gadget)) {
          errors.push({
            id: shortid.generate(),
            gadgetName: gadget.label,
            gadgetId: gadget.id,
            error: `${i18n._(
              'pagesbuilder.publish.missing.termsconfig.previousTerm'
            )}`
          })
        }
      }
    }
    if (hasAutoUpdatingIntegrationInput(gadget, schema)) {
      errors.push({
        id: shortid.generate(),
        gadgetName: gadget.label,
        gadgetId: gadget.id,
        error: i18n._({
          id: 'pagesbuilder.publish.auto.updating.integration',
          message:
            'Integration gadgets cannot reference auto-updating fields. Select an available field.'
        })
      })
    }
    if (hasAutoUpdatingCalculationInput(gadget, schema)) {
      errors.push({
        id: shortid.generate(),
        gadgetName: gadget.label,
        gadgetId: gadget.id,
        error: i18n._({
          id: 'pagesbuilder.publish.auto.updating.calculation',
          message:
            'Calculated gadgets cannot reference auto-updating fields. Select an available field.'
        })
      })
    }
    if (isProductTemplate && isNew(gadget) && isMissingKualiKey(gadget)) {
      errors.push({
        id: shortid.generate(),
        gadgetName: gadget.label,
        gadgetId: gadget.id,
        error: `${i18n._('pagesbuilder.publish.missing.kuali')}`
      })
    }
    if (
      isProductTemplate &&
      isNew(gadget) &&
      isExistingKualiKey(gadget, gadgetIndexTypes)
    ) {
      errors.push({
        id: shortid.generate(),
        gadgetName: gadget.label,
        gadgetId: gadget.id,
        error: `${i18n._('pagesbuilder.publish.duplicate.kuali')}`
      })
    }
    if (
      isProductTemplate &&
      notDerivedDataLink(gadget) &&
      hasMissingOptionKeys(gadget, gadgetIndexTypes)
    ) {
      errors.push({
        id: shortid.generate(),
        gadgetName: gadget.label,
        gadgetId: gadget.id,
        error: `${i18n._('pagesbuilder.publish.missing.kuali.option')}`
      })
    }
    if (
      isProductTemplate &&
      notDerivedDataLink(gadget) &&
      hasDuplicateOptionKeys(gadget, gadgetIndexTypes)
    ) {
      errors.push({
        id: shortid.generate(),
        gadgetName: gadget.label,
        gadgetId: gadget.id,
        error: `${i18n._('pagesbuilder.publish.duplicate.kuali.option')}`
      })
    }
    if (hasCustomKualiKey(gadget) || (isNew(gadget) && hasKualiKey(gadget))) {
      errors.push({
        id: shortid.generate(),
        gadgetName: gadget.label,
        gadgetId: gadget.id,
        error: `${i18n._('pagesbuilder.publish.reserved.kuali')}`
      })
    }
    return errors
  })

  // Sections can have conditional visibility rules, but they are not
  // included in the schema, so we need to traverse the template to
  // find them.
  fb.traverseTemplate(template, gadget => {
    if (hasMissingConditionalVisibilityFields(gadget, schemaByFormKey)) {
      allErrors.push({
        id: shortid.generate(),
        gadgetName: gadget.label,
        gadgetId: gadget.id,
        gadgetType: gadget.type,
        error: `${i18n._('pagesbuilder.publish.missing.gadget')}`
      })
    }
  })

  return allErrors
}

const notDerivedDataLink = gadget => !gadget.id.includes('.data.')

function hasAutoUpdatingIntegrationInput (gadget, schema) {
  if (gadget.type !== 'IntegrationFill') return false
  return some(gadget.details?.inputFields, field => {
    const fieldId = field?.value?.id ?? ''
    const searchId = fieldId.split('.')[1]
    return (
      !isEmpty(searchId) &&
      some(
        schema,
        g => g.id?.endsWith(searchId) && g.details?.autoUpdate?.enabled
      )
    )
  })
}

function hasAutoUpdatingCalculationInput (gadget, schema) {
  const calcConfig = gadget.details?.calculation || {}
  if (!calcConfig.enabled) return false
  return some(calcConfig.value?.parts, field => {
    const fieldId = field?.id ?? ''
    const searchId = fieldId.split('.')[0]
    return (
      !isEmpty(searchId) &&
      some(
        schema,
        g => g.id?.endsWith(searchId) && g.details?.autoUpdate?.enabled
      )
    )
  })
}

function isMissingKualiKey (gadget) {
  return (
    gadget.formKey &&
    (!gadget.customFormKey?.enabled || !gadget.customFormKey?.value)
  )
}

function isExistingKualiKey (gadget, gadgetIndexTypes) {
  if (!gadget.formKey || isMissingKualiKey(gadget)) return false
  let kualiKey = gadget.formKey.replace('data.', '').split('.*.')
  kualiKey.pop()
  kualiKey.push('kuali_' + gadget.customFormKey.value)
  kualiKey = kualiKey.join('.*.')
  return !!gadgetIndexTypes[kualiKey]
}

function hasMissingOptionKeys (gadget, gadgetIndexTypes) {
  const kualiKey = gadget.formKey.replace('data.', '')
  const git = gadgetIndexTypes[kualiKey]
  const existingOptions = map(git?.details?.options, 'key')
  return some(
    gadget.details?.options,
    o => !o.customKey && !existingOptions.includes(o.key)
  )
}

function hasDuplicateOptionKeys (gadget, gadgetIndexTypes) {
  const kualiKey = gadget.formKey.replace('data.', '')
  const git = gadgetIndexTypes[kualiKey]
  const existingOptions = map(git?.details?.options, 'key')
  const options = filter(gadget.details?.options, 'customKey')
  return (
    options.length !== new Set(map(options, 'customKey')).size ||
    some(options, o => existingOptions.includes(`kuali_${o.customKey}`))
  )
}

function hasCustomKualiKey (gadget) {
  return (
    gadget.customFormKey?.enabled &&
    gadget.customFormKey.value?.startsWith('kuali_')
  )
}

function hasKualiKey (gadget) {
  return gadget.formKey
    ?.replace('data.', '')
    .split('.*.')
    .pop()
    .startsWith('kuali_')
}

const PublishInner = ({ data, q, reload }) => {
  const { appId, datasetId, routes } = useIds()
  const isProductTemplate = !!datasetId && productBuilder
  const alerts = useAlerts()
  const publishApp = usePublishAppMutation(appId, datasetId)
  const discardDraft = useDiscardDraftMutation(appId, datasetId)
  const changes = data.app.dataset.draft?.changes ?? []
  const formWarnings = validateForm(
    data.app.dataset.form.schema,
    data.app.dataset.form.template,
    data.app.dataset.formContainer.gadgetIndexTypes,
    isProductTemplate
  )
  const wfWarnings = validate(
    data.app.dataset.workflow,
    data.app.dataset.form.schema
  )
  const warnings = [...formWarnings, ...wfWarnings]
  const publish = showDraft =>
    publishApp(showDraft)
      .then(() =>
        alerts.type3(
          i18n._('pagesbuilder.publish.version.published'),
          'success'
        )
      )
      .catch(() =>
        alerts.type3(i18n._('pagesbuilder.publish.error.publishing'), 'error')
      )
  const discard = () =>
    discardDraft()
      .then(() => {
        reload()
        alerts.type3(i18n._('pagesbuilder.publish.draft.discarded'), 'success')
      })
      .catch(() =>
        alerts.type3(
          i18n._('pagesbuilder.publish.draft.discard.error'),
          'error'
        )
      )
  if (!data.app.dataset.isPublished && !warnings.length) {
    return <InfoBox.FirstPublish publish={publish} />
  }
  if (!data.app.dataset.hasDraft) {
    return (
      <InfoBox.NoChanges
        app={data.app}
        q={q}
        getSchema={() =>
          fb.expandTableColumns(data.app.dataset.form.schema, formbot)
        }
        showDraft={data.app.dataset.showDraft}
      />
    )
  }
  return (
    <div className='mx-auto flex max-h-[calc(100vh-120px)] w-[800px] flex-col bg-white'>
      <div className='flex items-center justify-between pb-4 pt-12'>
        <div>
          {/* The "Publish Settings" button linking to /settings should go here */}
        </div>
        {data.app.dataset.hasDraft && data.app.dataset.isPublished && (
          <button className='kp-button-transparent' onClick={discard}>
            <Icons.Delete className='mr-2 fill-blue-500' />
            <Trans id='pagesbuilder.discard.draft' />
          </button>
        )}
      </div>
      <div className='flex-1 overflow-auto'>
        {!!warnings.length && (
          <>
            <h2 className='border-b border-b-light-gray-300 py-4 text-xs uppercase text-medium-gray-500'>
              <Trans
                id='pagesbuilder.publish.numwarning'
                values={{ numWarnings: warnings.length }}
              />
            </h2>
            <ul>
              {formWarnings.map(warning => (
                <li
                  key={warning.id}
                  className='flex items-center justify-between border-b border-light-gray-300 px-2 py-3'
                >
                  <div className='flex items-center'>
                    <Icons.AlertError className='mr-3 h-4 w-4 fill-red-400' />
                    <div className='text-sm'>
                      {warning.gadgetType === 'Section'
                        ? 'Section'
                        : 'Form gadget'}{' '}
                      <b>{warning.gadgetName}</b>: {warning.error}
                    </div>
                  </div>
                  {warning.gadgetId ? (
                    <Link
                      className='text-sm text-text-link hover:underline'
                      to={`${routes.form()}?showGadget=${warning.gadgetId}`}
                    >
                      <Trans id='pagesbuilder.publish.view' />
                    </Link>
                  ) : (
                    <span />
                  )}
                </li>
              ))}
              {wfWarnings.map(warning => (
                <li
                  key={warning.id}
                  className='flex items-center justify-between border-b border-light-gray-300 px-2 py-3'
                >
                  <div className='flex items-center'>
                    <Icons.AlertError className='mr-3 h-4 w-4 fill-red-400' />
                    <div className='text-sm'>
                      <Trans id='pagesbuilder.publish.workflow.step' />{' '}
                      <b>{warning.stepName}</b>: {warning.error}
                    </div>
                  </div>
                  {warning.stepId ? (
                    <Link
                      className='text-sm text-text-link hover:underline'
                      to={`${routes.workflow()}?showStep=${warning.stepId}`}
                    >
                      <Trans id='pagesbuilder.publish.view' />
                    </Link>
                  ) : (
                    <span />
                  )}
                </li>
              ))}
            </ul>
          </>
        )}
        {!!changes.length && (
          <>
            <h2 className='mt-6 border-b border-b-light-gray-300 py-4 text-xs uppercase text-medium-gray-500'>
              <Trans
                id='pagesbuilder.publish.numchanges'
                values={{ numChanges: changes.length }}
              />
            </h2>
            <ul>
              {changes.map(change => (
                <li
                  key={change.id}
                  className='flex items-center justify-between border-b border-light-gray-300 px-2 py-3'
                >
                  <div className='flex items-center'>
                    {getIcon(change)}
                    <div className='text-sm'>
                      {getPrefix(change)}: <b>{change.descriptor}</b>
                    </div>
                  </div>
                  {change.descriptorId ? (
                    <Link
                      className='text-sm text-text-link hover:underline'
                      to={getUrl(change, routes)}
                    >
                      <Trans id='pagesbuilder.publish.view' />
                    </Link>
                  ) : (
                    <span />
                  )}
                </li>
              ))}
            </ul>
          </>
        )}
      </div>
      <div className='flex items-center py-8'>
        <button
          className='kp-button-solid'
          disabled={!!warnings.length}
          onClick={() => publish(false)}
          title={i18n._('pagesbuilder.publish.publish.title')}
        >
          <Trans id='publish' />
        </button>
      </div>
      <div className='my-6 h-px bg-light-gray-300' />
      <div className='flex items-center'>
        <span className='text-base font-medium'>
          <Trans id='pagesbuilder.publish.unsubmitted' />
        </span>
        <TooltipTrigger
          as={Icons.AlertInfo}
          label={i18n._('pagesbuilder.publish.show.drafts')}
          // 'Show Drafts'
          ml={2}
          tooltipId='show-drafts-help'
        />
        <Tooltip id='show-drafts-help' place='right'>
          <div style={{ width: 190 }}>
            <Trans id='pagesbuilder.publish.show.drafts.tip' />
          </div>
        </Tooltip>
      </div>
      <Checkbox
        checked={data.app.dataset.showDraft}
        label={i18n._('pagesbuilder.publish.show.saved.drafts')}
        disabled
      />
    </div>
  )
}

const getIcon = ({ action }) => {
  if (action === 'NEW') {
    return <Icons.Add className='mr-3 h-4 w-4 fill-green-400' />
  }
  if (action === 'ARCHIVED') {
    return <Icons.Remove className='mr-3 h-4 w-4 fill-yellow-400' />
  }
  return <Icons.Edit className='mr-3 h-4 w-4 fill-blue-500' />
}

const getPrefix = change =>
  startCase(`${change.action} ${change.type}`.toLowerCase())

const getUrl = (change, routes) => {
  switch (change.type) {
    case 'FIELD':
      return `${routes.form()}?showGadget=${change.descriptorId}`
    case 'SETTING':
      return routes.workflow()
    default:
      return `${routes.workflow()}?showStep=${change.descriptorId}`
  }
}

const getPublishPageQuery = (appId, pageId) => ({
  variables: { appId, pageId },
  fetchPolicy: 'network-only',
  query: gql`
    query PublishPageQuery($appId: ID!, $pageId: ID) {
      app(id: $appId, isConfiguration: true) {
        id
        canAnonymousCreate
        dataset(id: $pageId) {
          id
          hasDraft
          isPublished
          showDraft
          workflow
          formContainer {
            id
            gadgetIndexTypes(includeTableColumns: true)
          }
          form: formVersion {
            id
            schema {
              id
              formKey
              type
              label
              details
              customFormKey {
                enabled
                value
              }
              conditionalVisibility
              childrenTemplate {
                id
                formKey
                type
                label
                details
                children
                customFormKey {
                  enabled
                  value
                }
                conditionalVisibility
              }
            }
            template
          }
          draft {
            changes {
              id
              action
              type
              descriptor
              descriptorId
            }
          }
        }
      }
    }
  `
})
