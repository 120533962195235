/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import { i18n } from '@lingui/core'
import { Trans } from '@lingui/react'
import cx from 'clsx'
import { keyBy } from 'lodash'
import React from 'react'
import { useLocation, useNavigate, useOutletContext } from 'react-router-dom'

import { productBuilder } from '../../../components/feature-flags'
import { ModalPage } from '../../../components/modal-page'
import { Tooltip, TooltipTrigger } from '../../../components/new-tooltip'
import Spinner from '../../../components/spinner'
import { useTenantFeaturesContext } from '../../../components/tenant-features-context'
import { collectGadgets } from '../../../formbot/engine/formbot/utils'
import * as Icons from '../../../icons'
import { Popover2 } from '../../../ui/popover'
import ConditionForm from './condition-form'
import ConditionsTable, { DOC_ACCESS_LIMIT } from './conditions-table'
import { useAddAppConditionalPermissionMutation } from './mutation.add-app-conditional-permission'
import { useConditionalPermissionJobs } from './subscription.conditional-permissions-jobs'

export default () => {
  const navigate = useNavigate()
  const { state } = useLocation()
  return (
    <ModalPage
      title={i18n._('pagesbuilder.form.perm.conditional')}
      onClose={() =>
        navigate(state?.back ? state?.back : '..', { relative: 'path' })
      }
    >
      <InnerContent />
    </ModalPage>
  )
}

function InnerContent () {
  const { dataset, appId, datasetId, appType } = useOutletContext()
  const tenantFeatures = useTenantFeaturesContext()
  const tenantVersionSettings = tenantFeatures?.versions ?? false
  const docAccess = dataset?.conditionalPermissions ?? []
  const showDraft = dataset?.showDraft ?? false
  const schema = dataset?.form?.schema ?? []
  const gadgetIndexTypes = dataset?.formContainer?.gadgetIndexTypes
  const currentJobStatus = dataset?.conditionalPermissionJobStatus?.status
  const { data: jobData } = useConditionalPermissionJobs(appId, datasetId)
  const jobDataStatus = jobData?.conditionalPermissionJobs?.status
  const [syncStatus, setSyncStatus] = React.useState(null)

  const [addAppConditionalPermission, { loading: addLoading }] =
    useAddAppConditionalPermissionMutation(appId, datasetId)
  const fullSchema = useFullSchema(schema, gadgetIndexTypes, appType)
  const gadgetByFormKey = React.useMemo(() => {
    return keyBy(fullSchema, 'formKey')
  }, [fullSchema])

  React.useEffect(() => {
    if (currentJobStatus === 'SYNCED' && !jobDataStatus) return
    if (currentJobStatus === 'EXECUTING' || jobDataStatus === 'EXECUTING') {
      setSyncStatus('syncing')
    }
    if (jobDataStatus === 'COMPLETE') {
      setTimeout(() => {
        setSyncStatus('complete')
      }, 500)
    }
  }, [currentJobStatus, jobDataStatus, setSyncStatus])

  const hasRecords = docAccess && docAccess.length > 0
  const canAddMore = docAccess?.length < DOC_ACCESS_LIMIT
  return (
    <div className='flex flex-col items-center'>
      <div className='w-auto min-w-[27rem] max-w-4xl px-4 py-10'>
        <h3 className='pb-3 text-xl font-medium'>
          <Trans id='pagesbuilder.form.perm.about' />
        </h3>
        <p>
          <Trans id='pagesbuilder.form.perm.about.data' />{' '}
          <TooltipTrigger
            id='permissions-explanation'
            className='relative inline-block align-sub'
          >
            <Icons.AlertHelp />
            <Tooltip position='bottom' className='w-56 p-2 text-sm'>
              <Trans id='pagesbuilder.form.perm.common' />
              <ul className='list-disc pl-4'>
                <li>
                  <Trans id='pagesbuilder.form.perm.common.old' />
                </li>
                <li>
                  <Trans id='pagesbuilder.form.perm.common.removed' />
                </li>
              </ul>
            </Tooltip>
          </TooltipTrigger>
        </p>
        <div className='mt-2 flex items-center bg-white dark:bg-light-gray-300'>
          <div className='flex flex-col justify-center self-stretch bg-red-500 p-4'>
            <Icons.AlertError className='fill-white' width={24} height={24} />
          </div>
          <div className='flex w-full items-center justify-between gap-4 p-4'>
            <p>
              <Trans
                id='pagesbuilder.form.perm.users.access'
                components={{ bold: <b /> }}
              />
            </p>
          </div>
        </div>

        <br />
        <div className='mt-10 flex flex-col'>
          <div
            className={cx(
              'flex',
              syncStatus ? 'justify-between' : 'justify-end'
            )}
          >
            {syncStatus && (
              <p className='self-end'>
                <Trans id='pagesbuilder.form.perm.sync' />
                <span className='font-bold'>{syncStatus}</span>
              </p>
            )}
            <Popover2
              trigger={
                <button
                  className='kp-button-solid'
                  disabled={!canAddMore || addLoading}
                  title={
                    !canAddMore
                      ? i18n._('pagesbuilder.form.perm.remove.condition')
                      : null
                  }
                >
                  {addLoading ? (
                    <Spinner size={20} />
                  ) : (
                    <>
                      <Icons.Add className='mr-2 fill-white' />
                      <Trans id='pagesbuilder.form.perm.add.condition' />
                    </>
                  )}
                </button>
              }
              className={cx(
                'relative',
                hasRecords ? 'self-end' : 'self-center'
              )}
            >
              {hide => (
                <ConditionForm
                  schema={fullSchema}
                  onCancel={hide}
                  onSave={addAppConditionalPermission}
                  saving={addLoading}
                  showDraft={showDraft}
                  close={hide}
                  gadgetByFormKey={gadgetByFormKey}
                  datasetAllowsVersions={
                    dataset?.allowNewVersions && tenantVersionSettings
                  }
                />
              )}
            </Popover2>
          </div>
          {docAccess ? (
            <ConditionsTable
              docAccess={docAccess}
              schema={fullSchema}
              showDraft={showDraft}
              gadgetByFormKey={gadgetByFormKey}
              appId={appId}
              datasetId={datasetId}
              addLoading={addLoading}
              datasetAllowsVersions={
                dataset?.allowNewVersions && tenantVersionSettings
              }
            />
          ) : null}
        </div>
      </div>
    </div>
  )
}

const INTEGRATION_TYPES = new Set([
  'IntegrationTypeahead',
  'IntegrationMultiselect',
  'IntegrationFill'
])

function useFullSchema (schema, gadgetIndexTypes, appType) {
  return React.useMemo(() => {
    const isProductTemplate = appType === 'product' && productBuilder
    const expandedSchema = schema
      .flatMap(expandRepeatables)
      .filter(gadget =>
        isOkForProductUse(gadget, isProductTemplate, gadgetIndexTypes)
      )
    const deletedGadgets = getDeletedGadgets(expandedSchema, gadgetIndexTypes)
    const fullSchema = expandedSchema
      .concat(deletedGadgets)
      .map(normalizeGadget)
    const integrationGadgets = fullSchema
      .filter(gadget => INTEGRATION_TYPES.has(gadget.type))
      .map(gadget => gadget.formKey)
    return fullSchema.filter(gadget => {
      return integrationGadgets.every(
        formKey => !gadget.formKey.startsWith(formKey)
      )
    })
  }, [schema, gadgetIndexTypes, appType])
}

function normalizeGadget (gadget) {
  return {
    formKey: gadget.formKey,
    type: gadget.type,
    label:
      (gadget.customName?.enabled ? gadget.customName.value : '') ||
      gadget.label,
    details: gadget.details,
    deleted: !!gadget.deleted,
    unpublished: !!gadget.unpublished
  }
}

function expandRepeatables (gadget) {
  if (gadget.type === 'Table') {
    return [gadget].concat(
      gadget.childrenTemplate
        .filter(child => child.formKey)
        .map(child => ({
          ...child,
          formKey: `${gadget.formKey}.*.${child.formKey}`,
          label: `${gadget.label} - ${child.label}`
        }))
    )
  }
  if (gadget.type === 'Repeater') {
    return [gadget].concat(
      collectGadgets({
        type: 'Section',
        children: gadget.childrenTemplate
      })
        .filter(child => child.formKey)
        .map(child => ({
          ...child,
          formKey: `${gadget.formKey}.data.*.data.${child.formKey}`,
          label: `${gadget.label} - ${child.label}`
        }))
    )
  }
  return [gadget]
}

function isOkForProductUse (gadget, isProductTemplate, gadgetIndexTypes) {
  if (!isProductTemplate) return true
  if (!gadget.formKey?.startsWith('data.')) return true
  const key = gadget.formKey.replace('data.', '')
  return !!gadgetIndexTypes[key]
}

function getDeletedGadgets (schema, gadgetIndexTypes) {
  const schemaFormKeys = new Set(schema.map(gadget => gadget.formKey))
  return Object.entries(gadgetIndexTypes ?? {})
    .map(([formKey, gadget]) => ({
      ...gadget,
      formKey: `data.${formKey}`,
      type: gadget.gadgetType,
      deleted: true
    }))
    .filter(gadget => !schemaFormKeys.has(gadget.formKey))
}
