/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import { i18n } from '@lingui/core'
import { Trans } from '@lingui/react'
import _ from 'lodash'
import React from 'react'
import { useSearchParams } from 'react-router-dom'

import { useQuery } from '../../../components/use-query'
import VersionsDropdown from '../../../components/versions-dropdown'
import Formbot from '../../../formbot'
import { collectGadgets } from '../../../formbot/engine/formbot/utils'
import * as Icons from '../../../icons'
import Toggle from '../../../ui/toggle'

export function useCompareState (getQuery, documentId, res1, doc1, structure1) {
  const [searchParams] = useSearchParams()
  const [onlyChanges, setOnlyChanges] = React.useState(false)
  const [selected, setSelected] = React.useState(null)
  const id = searchParams.get('compare')
  const q = getQuery(id, null, { skip: !id || id === documentId })
  const { data: res2, loading } = useQuery(q)

  React.useEffect(() => {
    function handler (e) {
      const el = e.target
      if (document.querySelector('[data-changes-header]')?.contains(el)) return
      if (document.querySelector('[data-formbot]')?.contains(el)) return
      setSelected(null)
    }
    document.addEventListener('click', handler)
    return () => document.removeEventListener('click', handler)
  }, [])

  let doc2, structure2
  if (id) {
    const source = documentId === id ? res1 : res2
    doc2 = {
      data: source?.document?.data,
      meta: source?.document?.meta || {},
      integration: source?.document?.integration || {}
    }
    structure2 = {
      template: _.cloneDeep(source?.document?.form?.template),
      metaFields: source?.document?.form?.metaFields ?? [],
      integrationFields: source?.document?.form?.integrationFields ?? [],
      trashed: []
    }
  }

  const changes = diffDocuments(doc1, doc2, structure1, structure2)

  return [
    {
      id,
      documentId,
      onlyChanges,
      setOnlyChanges,
      selected,
      setSelected,
      changes,
      loading,
      res1,
      res2,
      doc1,
      doc2,
      structure1,
      structure2
    },
    { changes, selected, setSelected }
  ]
}

export function Header ({ compareState: ctx, cb }) {
  const { id, onlyChanges, setOnlyChanges, documentId, res1, res2 } = ctx
  if (!id) return null
  return (
    <div
      className='flex flex-col gap-4 bg-orange-100 p-4 dark:bg-orange-950'
      data-changes-header
    >
      <div className='flex flex-col items-center justify-between gap-2 md:flex-row'>
        <div className='text-base font-medium'>
          <Trans id='compare_changes.show_changes' message='Show Changes' />
        </div>
        <ChangePaginator ctx={ctx} />
        <Toggle
          off={i18n._({
            id: 'compare_changes.show_form',
            message: 'Show Form'
          })}
          on={i18n._({
            id: 'compare_changes.show_changes_only',
            message: 'Show Changes Only'
          })}
          value={onlyChanges}
          onChange={setOnlyChanges}
        />
      </div>
      <div className='flex flex-col justify-center gap-4 md:flex-row'>
        <VersionsDropdown
          document={documentId === id ? res1?.document : res2?.document}
          routeIdentifierCallback={v => `?compare=${v?.id}`}
        />
        <VersionsDropdown
          document={res1?.document}
          routeIdentifierCallback={version => `${cb(version)}?compare=${id}`}
        />
      </div>
    </div>
  )
}

function ChangePaginator ({ ctx }) {
  const { onlyChanges, changes, selected, setSelected } = ctx
  if (onlyChanges) return null
  if (!changes.length) {
    return <Trans id='compare_changes.no_changes' message='No Changes' />
  }
  const i = changes.findIndex(c => c.formKey === selected)
  return (
    <div className='flex items-center gap-2'>
      <Trans id='compare_changes.view_changes' message='View Changes' />
      <button
        className='kp-button-solid kp-button-icon kp-button-sm rotate-90'
        aria-label={i18n._({
          id: 'compare_changes.previous_change',
          message: 'Next Change'
        })}
        onClick={() => {
          const ii = ((i === -1 ? 1 : i) - 1 + changes.length) % changes.length
          setSelected(changes[ii]?.formKey)
        }}
      >
        <Icons.SelectDownArrow />
      </button>
      <button
        className='kp-button-solid kp-button-icon kp-button-sm -rotate-90'
        aria-label={i18n._({
          id: 'compare_changes.next_change',
          message: 'Previous Change'
        })}
        onClick={() => {
          const ii = (i + 1) % changes.length
          setSelected(changes[ii]?.formKey)
        }}
      >
        <Icons.SelectDownArrow />
      </button>
      {i !== -1 && `${i + 1}/${changes.length}`}
    </div>
  )
}

export function Changes ({ compareState: ctx }) {
  const { id, changes, onlyChanges, doc1, doc2, structure1, structure2 } = ctx
  if (!id || !onlyChanges) return null
  if (!changes.length) {
    return <Trans id='compare_changes.no_changes' message='No Changes' />
  }
  return (
    <ul className='flex flex-col gap-4'>
      {changes.map(change => (
        <li
          key={change.formKey}
          data-testid='form-change'
          className='flex flex-col items-center gap-2 rounded-lg bg-light-gray-100 px-5 py-4 md:flex-row'
        >
          {change.type === 'added' ? (
            <>
              <NewField />
              <Icons.Back className='-rotate-90 md:rotate-180' />
              <Form ctx={ctx} s={structure1} d={doc1} c={change.next} />
            </>
          ) : change.type === 'removed' ? (
            <>
              <Form ctx={ctx} s={structure2} d={doc2} c={change.prev} />
              <Icons.Back className='-rotate-90 md:rotate-180' />
              <RemovedField />
            </>
          ) : (
            <>
              <Form ctx={ctx} s={structure2} d={doc2} c={change.prev} />
              <Icons.Back className='-rotate-90 md:rotate-180' />
              <Form ctx={ctx} s={structure1} d={doc1} c={change.next} />
            </>
          )}
        </li>
      ))}
    </ul>
  )
}

function NewField () {
  return (
    <div className='flex flex-1 items-center justify-center gap-2 py-4'>
      <Icons.Add className='fill-green-400' />
      <Trans id='compare_changes.new_field' message='New Field' />
    </div>
  )
}

function RemovedField () {
  return (
    <div className='flex flex-1 items-center justify-center gap-2 py-4'>
      <Icons.Remove className='fill-red-500' />
      <Trans id='compare_changes.removed_field' message='Removed Field' />
    </div>
  )
}

function Form ({ ctx, s, d, c }) {
  const { documentId, res1 } = ctx
  const labelSize = res1?.document?.dataset?.labelSize
  return (
    <div className='w-full flex-1 bg-white'>
      <Formbot.View
        className='formbot formbot-view'
        document={{ data: { [c.template.formKey]: c.value } }}
        structure={{ ...s, template: c.template }}
        context={{ documentId, documentMeta: d.meta, labelSize }}
      />
    </div>
  )
}

export function Deletions ({ compareState: ctx }) {
  const { changes, documentId, selected, setSelected, doc2, structure2 } = ctx
  const deletions = changes.filter(c => c.type === 'removed').map(c => c.prev)
  if (!ctx.id || !deletions.length || ctx.onlyChanges) return null
  const children = deletions.map(c => c.template)
  let data = _.keyBy(deletions, d => d.template.formKey)
  data = _.mapValues(data, 'value')
  return (
    <Formbot.View
      className='formbot formbot-view mt-10 border-t border-medium-gray-200 pt-10'
      document={{ data }}
      structure={{
        ...structure2,
        template: {
          id: 'a',
          type: 'Section',
          label: i18n._({
            id: 'compare_changes.removed_fields',
            message: 'Removed Fields'
          }),
          children: [{ id: 'b', type: 'Column', children }]
        }
      }}
      context={{
        compare: { changes, selected, setSelected },
        documentId,
        documentMeta: doc2.meta,
        labelSize: ctx.res1?.document?.dataset?.labelSize
      }}
    />
  )
}

function diffDocuments (doc1, doc2, structure1, structure2) {
  if (!doc1?.data || !doc2?.data || !structure1 || !structure2) return []
  const schema1 = collectGadgets(structure1.template).reverse()
  const schema2 = collectGadgets(structure2.template).reverse()
  const schema1map = _.keyBy(schema1, 'formKey')
  const schema2map = _.keyBy(schema2, 'formKey')
  const changes = []
  for (const { formKey } of schema1) {
    if (!schema2map[formKey]) {
      changes.push({
        formKey,
        type: 'added',
        next: { value: doc1.data[formKey], template: schema1map[formKey] }
      })
    } else if (!_.isEqual(doc1.data[formKey], doc2.data[formKey])) {
      changes.push({
        formKey,
        type: 'changed',
        prev: { value: doc2.data[formKey], template: schema2map[formKey] },
        next: { value: doc1.data[formKey], template: schema1map[formKey] }
      })
    }
  }
  for (const { formKey } of schema2) {
    if (!schema1map[formKey]) {
      changes.push({
        formKey,
        type: 'removed',
        prev: { value: doc2.data[formKey], template: schema2map[formKey] }
      })
    }
  }
  return changes
}
