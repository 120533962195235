/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import { Trans } from '@lingui/react'
import React, { useState } from 'react'

import cmTree from './default-cm-rules'
import DecisionTree from './tree-edit'

export function RequiredConfig ({ id, onChange, Gadgets, value, allGadgets }) {
  const [showTree, setShowTree] = useState(false)
  if (!value.tree) {
    onChange({
      ...value,
      tree: cmTree
    })
  }
  return (
    <Gadgets.Padded>
      <Gadgets.Label>
        <Trans id='decision.tree.colon' />
      </Gadgets.Label>
      <button
        className='kp-button-solid mt-5'
        onClick={() => setShowTree(true)}
      >
        <Trans id='edit.tree' />
      </button>
      {showTree && (
        <DecisionTree
          tree={value.tree}
          allGadgets={allGadgets}
          setTree={tree => {
            onChange({
              ...value,
              tree
            })
          }}
          onClose={() => setShowTree(false)}
        />
      )}
    </Gadgets.Padded>
  )
}
