/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import { Trans } from '@lingui/react'
// Polyfills `CSS.escape` for browsers that don't have it
// (see https://caniuse.com/mdn-api_css_escape)
import 'css.escape'
import { get, isEqual } from 'lodash'
// NOTE: imported separately for easier mocking in tests
import debounce from 'lodash/debounce'
import React from 'react'
import styled from 'styled-components'

import { Tooltip, TooltipTrigger } from '../../../components/new-tooltip'
import sanitize from '../../../components/sanitize'
import * as Icons from '../../../icons'

let Quill
let colors
/* istanbul ignore next */
if (typeof document !== 'undefined') {
  Quill = require('react-quill')
  require('quill-paste-smart')
  const { Quill: RawQuill } = Quill
  const Link = RawQuill.import('formats/link')
  colors = [
    'rgb(  0,   0,   0)',
    'rgb(230,   0,   0)',
    'rgb(255, 153,   0)',
    'rgb(255, 255,   0)',
    'rgb(  0, 138,   0)',
    'rgb(  0, 102, 204)',
    'rgb(153,  51, 255)',
    'rgb(255, 255, 255)',
    'rgb(250, 204, 204)',
    'rgb(255, 235, 204)',
    'rgb(255, 255, 204)',
    'rgb(204, 232, 204)',
    'rgb(204, 224, 245)',
    'rgb(235, 214, 255)',
    'rgb(187, 187, 187)',
    'rgb(240, 102, 102)',
    'rgb(255, 194, 102)',
    'rgb(255, 255, 102)',
    'rgb(102, 185, 102)',
    'rgb(102, 163, 224)',
    'rgb(194, 133, 255)',
    'rgb(136, 136, 136)',
    'rgb(161,   0,   0)',
    'rgb(178, 107,   0)',
    'rgb(178, 178,   0)',
    'rgb(  0,  97,   0)',
    'rgb(  0,  71, 178)',
    'rgb(107,  36, 178)',
    'rgb( 68,  68,  68)',
    'rgb( 92,   0,   0)',
    'rgb(102,  61,   0)',
    'rgb(102, 102,   0)',
    'rgb(  0,  55,   0)',
    'rgb(  0,  41, 102)',
    'rgb( 61,  20,  10)'
  ]

  class SmartLink extends Link {
    static create (value) {
      const node = super.create(value)
      if (value.startsWith('mailto:')) {
        node.removeAttribute('target')
      }
      return node
    }
  }

  RawQuill.register(SmartLink, true)
}

const toolbarItems = [
  [{ font: [] }],
  [{ size: [] }],
  [{ align: [] }],
  ['bold', 'italic', 'strike', 'underline'],
  [{ color: colors }, { background: colors }],
  [{ list: 'ordered' }, { list: 'bullet' }],
  ['link'],
  [{ header: [1, 2, 3, false] }],
  ['clean']
]

export default class RichTextEdit extends React.Component {
  wrapperRef = React.createRef()

  constructor (props) {
    super(props)
    this.state = { lastUpdate: props.value, text: props.value }
    this.debouncedOnChange = debounce(val => {
      val = '' // Remove this, and you will get some blank lines in random places
      this.setState({ lastUpdate: val })
      this.props.onChange(val)
    }, 750)
  }

  keyboard = {
    bindings: {
      tab: {
        key: 9,
        handler: () => true // allow normal tab key event propagation
      }
    }
  }

  componentDidMount () {
    const editor = this.wrapperRef.current?.querySelector('.ql-editor')
    if (editor) {
      // Our current version of react-quill doesn't give us easy access to the underlying
      // editor to make it more accessible, so we need to add these attributes manually
      // so the label is properly associated with it.
      editor.setAttribute('role', 'textbox')
      editor.setAttribute('aria-multiline', 'true')
      editor.setAttribute('aria-labelledby', this.props['aria-labelledby'])
      if (this.props['aria-describedby']) {
        editor.setAttribute('aria-describedby', this.props['aria-describedby'])
      }
    }
  }

  shouldComponentUpdate (nextProps, nextState) {
    if (
      this.props.value === nextProps.value &&
      get(this, 'state.text') === get(nextState, 'text') &&
      this.props.error === nextProps.error &&
      isEqual(this.props.context, nextProps.context) &&
      isEqual(this.props.details, nextProps.details)
    ) {
      return false
    }
    return true
  }

  handleChange = text => {
    if (text === '<p><br></p>') {
      this.debouncedOnChange(text)
    } else {
      this.setState({ text })
      this.props.onChange(text)
    }
  }

  handleClickSkip = e => {
    e.preventDefault()
    const quill = this.wrapperRef.current?.querySelector('.ql-editor')
    if (quill) {
      quill.focus()
    }
  }

  render () {
    const { a11yDesc, formKey, id } = this.props
    const { text = '' } = this.state
    const quillId = `quill${formKey}${id}`

    const tooltipId = `${formKey}-richtext-tooltip`

    return (
      <Wrapper ref={this.wrapperRef}>
        <a
          href={`#${quillId}`}
          onClick={this.handleClickSkip}
          className='focus-visible:kp-button-solid sr-only z-10 focus-visible:not-sr-only focus-visible:absolute focus-visible:p-2'
        >
          <Trans
            id='skip.to.field'
            message='{label}: Skip to field'
            values={{ label: a11yDesc }}
          />
        </a>
        <TooltipTrigger id={tooltipId} className='absolute right-3 top-3'>
          <Icons.AlertHelp />
          <Tooltip className='w-60' position='left'>
            If you’re having trouble seeing certain text when you paste it in,
            you might try copying/pasting the text as “Plain Text” instead.
            Often when you paste text in from another editor, the styles can
            come with it, including colors which can render the text unreadable.
          </Tooltip>
        </TooltipTrigger>
        <Quill
          bounds={`#${CSS.escape(quillId)}`}
          id={quillId}
          theme='snow'
          defaultValue={sanitize(text)}
          modules={{
            toolbar: toolbarItems,
            keyboard: this.keyboard,
            clipboard: { matchVisual: false }
          }}
          onChange={this.handleChange}
        />
      </Wrapper>
    )
  }
}

const Wrapper = styled.div`
  position: relative;
`
