/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import { i18n } from '@lingui/core'
import { getMonth } from 'date-fns/getMonth'
import { getYear } from 'date-fns/getYear'
import { es, frCA } from 'date-fns/locale'
import { range } from 'lodash'
import React from 'react'
import DatePickerLib, { registerLocale } from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import styled from 'styled-components'

import * as Icons from '../icons'
import Button from '../ui/button'
import { Option, Select } from '../ui/select'

const months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December'
].map(month => month.substring(0, 3))
const curYear = getYear(new Date())
const years = range(curYear - 120, curYear + 120, 1)
registerLocale('es', es)
registerLocale('fr', frCA)

function CustomHeader ({
  date,
  changeYear,
  changeMonth,
  decreaseMonth,
  increaseMonth,
  prevMonthButtonDisabled,
  nextMonthButtonDisabled
}) {
  return (
    <Wrapper>
      <Button
        icon
        transparent
        small
        onClick={decreaseMonth}
        disabled={prevMonthButtonDisabled}
        aria-label={i18n._('previous.month')}
      >
        <Icons.SelectDownArrow style={{ transform: 'rotateZ(90deg)' }} />
      </Button>
      <Select
        small
        onChange={value => changeMonth(months.indexOf(value))}
        value={months[getMonth(date)]}
      >
        {months.map(option => (
          <Option key={option} value={option}>
            {option}
          </Option>
        ))}
      </Select>
      <Select small onChange={value => changeYear(value)} value={getYear(date)}>
        {years.map(option => (
          <Option key={option} value={option}>
            {option}
          </Option>
        ))}
      </Select>
      <Button
        icon
        transparent
        small
        onClick={increaseMonth}
        disabled={nextMonthButtonDisabled}
        aria-label={i18n._('next.month')}
      >
        <Icons.SelectDownArrow style={{ transform: 'rotateZ(-90deg)' }} />
      </Button>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 4px 25px 12px;

  select {
    width: 73px;
  }
`

export const DatePicker = styled(DatePickerLib).attrs(p => ({
  dateFormat: 'MMMM d, yyyy',
  showPopperArrow: false,
  portalId: 'datepicker-portal',
  renderCustomHeader: CustomHeader,
  popperPlacement: 'bottom',
  placeholderText: p.placeholder,
  selected: p.value
}))`
  height: 32px;
  min-width: 150px;
  width: 100%;
  padding: 0 8px;
  font-size: 14px;
  font-weight: normal;
  border: 1px solid #bebebe;
  background: var(--white);
  html.dark & {
    // Outlier: border dark:border-light-gray-300
    border: 1px solid #333;
  }
  border-radius: 2px;
  box-sizing: border-box;

  .formbot-config & {
    min-width: 0;
  }
`
