import { i18n } from '@lingui/core'

export function translate (rawInput) {
  if (typeof rawInput !== 'string') {
    return rawInput
  }
  const input = rawInput.toLowerCase()
  switch (input) {
    case 'waiting for approval':
      return i18n._({
        id: 'pagesrunner.workflow.waiting.for.approval',
        message: 'Waiting for Approval'
      })
    case 'waiting for task completion':
      return i18n._({
        id: 'pagesrunner.workflow.waiting.for.task',
        message: 'Waiting for Task Completion'
      })
    case 'no recipients defined':
      return i18n._({
        id: 'pagesrunner.workflow.no.recipients',
        message: 'No recipients defined'
      })
    case 'approval error':
      return i18n._({
        id: 'pagesrunner.workflow.approval.error',
        message: 'Approval Error'
      })
    case 'task completed':
      return i18n._({
        id: 'pagesrunner.workflow.task.completed',
        message: 'Task Completed'
      })
    case 'acknowledgement requested':
      return i18n._({
        id: 'acknowledgement.requested',
        message: 'Acknowledgement Requested'
      })
    case 'submitted for approval':
      return i18n._({
        id: 'pagesrunner.workflow.submitted.for.approval',
        message: 'Submitted for Approval'
      })
    case 'skipped':
      return i18n._({
        id: 'pagesrunner.workflow.skipped',
        message: 'Skipped'
      })
    case 'reassigned':
      return i18n._({
        id: 'pagesrunner.workflow.reassigned',
        message: 'Reassigned'
      })
    case 'retry':
      return i18n._({
        id: 'pagesrunner.workflow.retry',
        message: 'Retry'
      })
    case 'proposer':
      return i18n._({
        id: 'pagesrunner.workflow.proposer',
        message: 'Proposer'
      })
    case 'form submission':
      return i18n._({
        id: 'pagesrunner.workflow.form.submission',
        message: 'Form Submission'
      })
    case 'pending':
      return i18n._({
        id: 'pagesrunner.workflow.pending',
        message: 'Pending'
      })
    case 'acknowledge':
      return i18n._({
        id: 'acknowledge',
        message: 'Acknowledge'
      })
    case 'acknowledged':
      return i18n._({
        id: 'pagesrunner.action.workflow.actions.acknowledged',
        message: 'Acknowledged'
      })
    case 'task':
      return i18n._({
        id: 'task',
        message: 'Task'
      })
    case 'approval':
      return i18n._({
        id: 'approval',
        message: 'Approval'
      })
    case 'approved':
      return i18n._({
        id: 'approved',
        message: 'Approved'
      })
    case 'rejected':
      return i18n._({
        id: 'pagesrunner.action.workflow.actions.rejected',
        message: 'Denied'
      })
    case 'denied':
      return i18n._({
        id: 'pagesrunner.action.workflow.actions.rejected',
        message: 'Denied'
      })
    case 'retried':
      return i18n._({
        id: 'pagesrunner.action.workflow.actions.retried',
        message: 'Retried'
      })
    case 'completed':
      return i18n._({
        id: 'pagesrunner.action.workflow.actions.submitted',
        message: 'Submitted'
      })
    case 'sent back':
      return i18n._({
        id: 'pagesrunner.action.workflow.actions.sent.back',
        message: 'Sent back'
      })
    case 'notification sent':
      return i18n._({
        id: 'pagesrunner.action.workflow.actions.notified',
        message: 'Email Sent'
      })
    case 'integration completed':
      return i18n._({
        id: 'pagesrunner.action.workflow.actions.integration.completed',
        message: 'Integration Completed'
      })

    default:
      return input
  }
}
