/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import { Trans } from '@lingui/react'
import cx from 'clsx'
import React from 'react'

import * as Icons from '../icons'

function getCloseCount (messageKey) {
  const item = window.localStorage.getItem(`fyi-${messageKey}`)
  if (!item) return 0
  const parsedItem = parseInt(item, 10)
  return Number.isInteger(parsedItem) ? parsedItem : 0
}

function setCloseCount (messageKey, change) {
  window.localStorage.setItem(
    `fyi-${messageKey}`,
    change(getCloseCount(messageKey))
  )
}

export default function StatefulFYI ({
  messageKey,
  maxClose = Infinity,
  ...props
}) {
  return (
    <StatefulDismissable messageKey={messageKey} maxClose={maxClose}>
      {dismiss => <FYI {...props} onClose={dismiss} />}
    </StatefulDismissable>
  )
}

/**
 * @param {string} key
 * @param {object} [params]
 * @param {number} [params.maxClose=Infinity]
 * @returns {[boolean, () => void]}
 */
export function useStatefulDismiss (key, { maxClose = Infinity } = {}) {
  const [show, setShow] = React.useState(() => getCloseCount(key) < maxClose)
  const handleDismiss = React.useCallback(() => {
    setShow(false)
    setCloseCount(key, count => count + 1)
  }, [key])
  return [show, handleDismiss]
}

/**
 * @param {object} props
 * @param {string} props.messageKey
 * @param {number} [props.maxClose]
 * @param {(dismiss: () => void) => React.ReactElement} children
 */
export function StatefulDismissable ({ messageKey, maxClose, children }) {
  const [show, dismiss] = useStatefulDismiss(messageKey, { maxClose })
  return show ? children(dismiss) : null
}

function FYI ({ children, className, icon, onClose, ...props }) {
  return (
    <div {...props} className={cx(className, 'flex items-start gap-3 rounded')}>
      {icon}
      {children}
      <button onClick={onClose}>
        <span className='sr-only'>
          <Trans id='close' />
        </span>
        <Icons.Close width={16} height={16} />
      </button>
    </div>
  )
}
