/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import { i18n } from '@lingui/core'
import { Trans } from '@lingui/react'
import { get } from 'lodash'
import React from 'react'

import { relativeMinMaxDate } from '../../../components/feature-flags'
import Input from '../../../ui/input'
import Radios from '../../../ui/radios'
import { Option, Select } from '../../../ui/select'

const DATE_GADGETS = ['Date', 'Timestamp']

export function OptionalConfig ({ Gadgets, value, allGadgets }) {
  const dateishGadgets =
    allGadgets?.filter(gadget => DATE_GADGETS.includes(gadget.type)) ?? []
  const rollingDateError = validateRollingRange(value)
  return (
    <>
      <Gadgets.ConfigBox
        configKey='placeholder.enabled'
        label={i18n._('show.placeholder.text')}
        description={i18n._('show.placeholder.text.data')}
      >
        <Gadgets.Text configKey='placeholder.value' testid='placeholder' />
      </Gadgets.ConfigBox>
      <Gadgets.ConfigBox
        configKey='limitDates.enabled'
        label={i18n._('formbot.gadgets.datepicker.config.limit.dates')}
      >
        {relativeMinMaxDate ? (
          <Gadgets.Custom configKey='limitDates.value.type'>
            {({ onChange, value }) => (
              <Radios
                id='limitDates.value.type'
                name='limitDates.value.type'
                options={[
                  {
                    id: 'static',
                    label: i18n._(
                      'formbot.gadgets.datepicker.config.within.range'
                    ),
                    className: '!pl-0',
                    children: (
                      <>
                        <Gadgets.Date
                          configKey='limitDates.value.min'
                          label={i18n._(
                            'formbot.gadgets.datepicker.config.min.date'
                          )}
                          limitDates={{
                            enabled: true,
                            value: { max: get(value, 'limitDates.value.max') }
                          }}
                        />
                        <Gadgets.Date
                          configKey='limitDates.value.max'
                          label={i18n._(
                            'formbot.gadgets.datepicker.config.max.date'
                          )}
                          limitDates={{
                            enabled: true,
                            value: { min: get(value, 'limitDates.value.min') }
                          }}
                        />
                      </>
                    )
                  },
                  {
                    id: 'rolling',
                    label: i18n._(
                      'formbot.gadgets.datepicker.config.rolling.range'
                    ),
                    className: '!pl-0',
                    children: (
                      <>
                        <div className='flex w-full flex-col gap-1 pb-3 pt-1'>
                          Submitter can select a date between
                          <RelativeDate
                            Gadgets={Gadgets}
                            configKey='limitDates.value.rolling.min'
                            dateGadgets={dateishGadgets}
                          />
                          and
                          <RelativeDate
                            Gadgets={Gadgets}
                            configKey='limitDates.value.rolling.max'
                            dateGadgets={dateishGadgets}
                          />
                          {rollingDateError && (
                            <p className='text-red-500'>{rollingDateError}</p>
                          )}
                        </div>
                      </>
                    )
                  }
                ]}
                value={value || 'static'}
                onChange={onChange}
              />
            )}
          </Gadgets.Custom>
        ) : (
          <>
            <Gadgets.Date
              configKey='limitDates.value.min'
              label='Min Date'
              limitDates={{
                enabled: true,
                value: { max: get(value, 'limitDates.value.max') }
              }}
            />
            <Gadgets.Date
              configKey='limitDates.value.max'
              label='Max Date'
              limitDates={{
                enabled: true,
                value: { min: get(value, 'limitDates.value.min') }
              }}
            />
          </>
        )}
      </Gadgets.ConfigBox>
    </>
  )
}

function validateRollingRange (value) {
  const rolling = value?.limitDates?.value?.rolling
  if (!rolling) return null
  const { min, max } = rolling
  if (!min?.from || !max?.from) return null
  if (
    min?.from &&
    min.from === max?.from &&
    (min.offset ?? 0) > (max.offset ?? 0)
  ) {
    return <Trans id='max.date.must.after.min.date' />
  }
}

function RelativeDate ({ Gadgets, configKey, dateGadgets }) {
  return (
    <Gadgets.Custom configKey={configKey} className='!p-0'>
      {({ onChange, value }) => {
        const offset = value?.offset || 0
        const absOffset = Math.abs(offset)
        const isNegative = offset < 0
        return (
          <div className='flex items-center justify-between gap-1'>
            <Select
              onChange={val => onChange({ ...value, from: val })}
              value={value?.from}
              className='flex-grow'
            >
              <Option value=''>- - -</Option>
              {dateGadgets.map(gadget => (
                <Option key={gadget.id} value={gadget.formKey}>
                  {gadget.label}
                </Option>
              ))}
            </Select>
            <Select
              value={offset >= 0 ? '+' : '-'}
              onChange={sign => {
                onChange({
                  ...value,
                  offset: sign === '+' ? absOffset : -absOffset
                })
              }}
              className='w-[69px]'
            >
              <Option value='+'>
                <Trans id='plus' />
              </Option>
              <Option value='-'>
                <Trans id='minus' />
              </Option>
            </Select>
            <Input
              className='w-11'
              type='number'
              value={absOffset}
              onChange={offset => {
                offset = +offset
                onChange({ ...value, offset: isNegative ? -offset : offset })
              }}
              min={0}
              step={1}
            />
            <Trans id='days' />
          </div>
        )
      }}
    </Gadgets.Custom>
  )
}
