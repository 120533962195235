/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import FocusTrap from 'focus-trap-react'
import React from 'react'
import styled, { css } from 'styled-components'

import { useEscapePress } from '../components/use-escape-click'
import { useOutsideClick } from '../components/window-click'

const Wrapper = styled.div`
  position: absolute;
  border-radius: 4px;
  ${p =>
    !p.skipAbsoluteHorizontal
      ? p.right != null
        ? `right: ${p.right}px`
        : `left: calc(50% - ${p.width / 2 + p.offset}px);`
      : ''};
  ${p =>
    !p.skipAbsoluteVertical
      ? p.bottom != null
        ? `bottom: ${p.bottom}px`
        : p.top != null
          ? `top: ${p.top}px`
          : 'top: 54px'
      : ''};
  box-shadow: 0 0 10px 1px rgba(0, 0, 0, 0.2);
  @media (max-width: 500px) {
    ${p =>
      p.mobile &&
      css`
        position: fixed;
        right: 0;
        width: 100%;
        bottom: 0;
        left: 0;
        top: inherit;
        transition: bottom 0.4s ease-in-out;
        box-shadow: 0 0 10px 5px rgba(0, 0, 0, 0.2);
        max-height: 50%;
        overflow: auto;
      `}
  }
  background: white;
  html.dark & {
    // Outlier: dark:bg-light-gray-200
    background: #222;
  }
  font-weight: normal;
  z-index: 16;
`

const Content = styled.div`
  width: 100%;
  @media not all and (max-width: 500px) {
    max-width: calc(100vw - 30px);
  }
  height: 100%;
  background: #fff;
  html.dark & {
    // Outlier: dark:bg-light-gray-200
    background: #222;
  }
  box-sizing: border-box;
  border-radius: 4px;
`

function getParentRect (element) {
  let style = getComputedStyle(element)
  const re = /(auto|scroll)/
  if (style.position === 'fixed') return [0, document.body.offsetWidth, 0]
  for (let parent = element; (parent = parent.parentElement); ) {
    style = getComputedStyle(parent)
    if (re.test(style.overflow + style.overflowY + style.overflowX)) {
      return [parent.offsetLeft, parent.scrollWidth, parent.scrollLeft]
    }
  }
  return [0, document.body.offsetWidth, 0]
}

function usePopoverSizing (ref) {
  const [[offset, width], setDimensions] = React.useState([0, 0])
  React.useLayoutEffect(() => {
    if (ref.current) {
      const [parentLeft, parentWidth, scrollLeft] = getParentRect(ref.current)
      const { x, width } = ref.current.getBoundingClientRect()
      let nextOffset = 0
      const rightOffset =
        x + width / 2 - (parentLeft + parentWidth) + 10 + scrollLeft
      if (rightOffset > 0) nextOffset = rightOffset
      const leftOffset = x - width / 2 - parentLeft - 10 + scrollLeft
      if (leftOffset < 0) nextOffset = leftOffset
      setDimensions([nextOffset, width])
    }
  }, [])
  return [offset, width]
}

export const Popover = React.forwardRef(({ children, ...rest }, _ref) => {
  const fallbackRef = React.useRef()
  const ref = _ref || fallbackRef
  const [offset, width] = usePopoverSizing(ref)
  return (
    <Wrapper {...rest} width={width} offset={offset} ref={ref}>
      <Content>{children}</Content>
    </Wrapper>
  )
})

export default Popover

const Container = styled.div`
  position: relative;
`

export const Popover2 = ({
  trigger,
  role,
  children,
  className,
  onOpen,
  onClose,
  defaultShow,
  ...rest
}) => {
  const ref = React.useRef()
  const [showingModal, setShowingModal] = React.useState(defaultShow || false)
  const hide = React.useCallback(() => {
    if (onClose) onClose()
    setShowingModal(false)
  }, [onClose])
  const toggle = React.useCallback(() => {
    if (onClose && showingModal) onClose()
    if (onOpen && !showingModal) onOpen()
    setShowingModal(a => !a)
  }, [onClose, onOpen, showingModal])
  useOutsideClick(ref, hide, showingModal)
  useEscapePress(hide, showingModal, ref)
  return (
    <Container ref={ref} className={className}>
      {React.cloneElement(trigger, {
        onClick: e => {
          e.preventDefault()
          toggle()
        },
        'aria-haspopup': role
      })}
      {showingModal && (
        <Popover {...rest} role={role}>
          <FocusTrap focusTrapOptions={{ clickOutsideDeactivates: true }}>
            <div>{children(hide)}</div>
          </FocusTrap>
        </Popover>
      )}
    </Container>
  )
}
