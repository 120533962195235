/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import { i18n } from '@lingui/core'
import React from 'react'

export const ANNOUNCEKIT_LINK = 'https://announcekit.app/kuali/announcements'

export function useUnreadAnnounceKit () {
  const [unread, setUnread] = React.useState(window.announcekitUnread || 0)
  React.useEffect(() => {
    const unreadHandler = ({ detail }) => setUnread(detail.unread)
    document.addEventListener('announcekit:unread', unreadHandler)
    setupAnnouncekit('32GzFm')
    return () => {
      document.removeEventListener('announcekit:unread', unreadHandler)
    }
  }, [])
  const clearUnread = React.useCallback(() => {
    window.announcekitUnread = 0
    setUnread(0)
    const rawValue = localStorage.getItem('{announcekit}:6462')
    if (rawValue) {
      try {
        const value = JSON.parse(rawValue)
        value.lastRead = Date.now()
        localStorage.setItem('{announcekit}:6462', JSON.stringify(value))
      } catch {}
    }
  }, [])
  return [unread, clearUnread]
}

export function AnnounceKitUnreadBadge ({ unread }) {
  if (!unread) return null
  return (
    <span className='before:kp-pulse-badge-before after:kp-pulse-badge-after relative z-10 flex size-6 items-center justify-center rounded-full bg-wintergreen-300 text-sm font-bold before:bg-wintergreen-300 after:bg-wintergreen-300'>
      {unread}
    </span>
  )
}

function setupAnnouncekit (key) {
  if (document.getElementById(`announce-kit-${key}`)) return
  window.announcekit = window.announcekit || {
    queue: [],
    on: function (n, x) {
      window.announcekit.queue.push([n, x])
    },
    push: function (x) {
      window.announcekit.queue.push(x)
    }
  }
  window.announcekit.push({
    widget: `https://announcekit.app/widgets/v2/${key}`,
    name: key
  })
  window.announcekit.on('widget-unread', function ({ unread }) {
    window.announcekitUnread = unread
    const event = new CustomEvent('announcekit:unread', { detail: { unread } })
    document.dispatchEvent(event)
  })
  window.announcekit.on('widget-init', function ({ widget }) {
    addFrameTitle(widget)
  })
  const script = document.createElement('script')
  script.async = true
  script.src = 'https://cdn.announcekit.app/widget-v2.js'
  script.id = `announce-kit-${key}`
  document.body.append(script)
}

function addFrameTitle (widget) {
  const frame = widget?.frame?.frame
  if (frame) {
    frame.title = i18n._('new.feature.announcements')
  }
}
