/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import { gql } from '@apollo/client'
import { i18n } from '@lingui/core'
import { Trans } from '@lingui/react'
import { cloneDeep, findLast, get } from 'lodash'
import React from 'react'
import { Navigate, useParams, useSearchParams } from 'react-router-dom'

import {
  moveWorkflowStatus,
  moveWorkflowStatusWithVersions,
  showCompareVersions
} from '../../../components/feature-flags'
import FormbotContainer from '../../../components/formbot-container/formbot-container'
import MoreOptionsMenu from '../../../components/formbot-container/more-options-menu'
import { PortalOrange } from '../../../components/portals'
import { useQuery } from '../../../components/use-query'
import { useWindowWidth } from '../../../components/use-window-width'
import { DocumentStatus } from '../../../components/versions-dropdown'
import Formbot from '../../../formbot'
import SentBack from '../../../pages-runner/action/sent-back'
import WorkflowTracker from '../../../pages-runner/action/workflow-tracker'
import * as DocumentHistory from '../../../pages-runner/document-history'
import NotFound from '../../../pages/not-found'
import { useAlerts } from '../../../ui/alerts'
import { useDuplicateMutation } from './mutation.duplicate'
import { useWithdrawMutation } from './mutation.withdraw'
import { switchTypenames } from './util'

export default function SubmitterDocument () {
  const alerts = useAlerts()
  const width = useWindowWidth()
  const [searchParams] = useSearchParams()
  const { documentId, suite } = useParams()
  const query = getViewSubmissionQuery(documentId)
  const withdraw = useWithdrawMutation(query)
  const [duplicate] = useDuplicateMutation()
  const { data, loading, error } = useQuery(query)
  const [showWithdraw, setShowWithdraw] = React.useState(false)
  const [disableWithdraw, setDisableWithdraw] = React.useState(false)
  const [disableDuplicate, setDisableDuplicate] = React.useState(false)
  const [redirect, setRedirect] = React.useState(null)
  const [showSendbackMessage, setShowSendbackMessage] = React.useState(true)
  React.useEffect(() => {
    if (data?.submission?.canWithdraw && !showWithdraw) {
      setShowWithdraw(true)
    }
  }, [data, setShowWithdraw, showWithdraw])
  const notFound = !loading && !get(data, 'submission')
  const [showingWorkflow, setShowingWorkflow] = React.useState(
    !!searchParams.get('showWf')
  )
  const [showingHistory, setShowingHistory] = React.useState(
    moveWorkflowStatus && showingWorkflow
  )
  const [animateFormbot, setAnimateFormbot] = React.useState(false)
  const [animateHistoryButton, setAnimateHistoryButton] = React.useState(false)
  const menuRef = React.useRef()
  const [compareVersions, setCompareVersions] = React.useState(false)

  const structure = React.useMemo(
    () => ({
      template: cloneDeep(get(data, 'submission.form.template')),
      metaFields: data?.submission?.form?.metaFields ?? [],
      integrationFields: data?.submission?.form?.integrationFields ?? [],
      trashed: []
    }),
    [data]
  )
  const document = {
    data: get(data, 'submission.data'),
    meta: get(data, 'submission.meta', {}),
    integration: get(data, 'submission.integration', {})
  }

  const previousDocument = data.submission?.previousDocument

  const getActionButtons = () => {
    if (loading) return null
    return (
      <>
        {showWithdraw ? (
          <button
            className='kp-button-outline max-lg:w-full'
            disabled={disableWithdraw}
            onClick={async () => {
              setDisableWithdraw(true)
              const {
                data: { withdrawSubmission }
              } = await withdraw(
                data?.submission?.id,
                Intl.DateTimeFormat().resolvedOptions().timeZone
              )
              const cannotWithdraw = () => {
                alerts.type2(
                  i18n._('pages.submitter.document.withdraw'),
                  'error'
                )
              }
              switchTypenames(withdrawSubmission, {
                Success: () => {
                  setRedirect({
                    pathname: '/my/submissions'
                  })
                  alerts.type2(
                    <span>
                      <Trans id='pages.submitter.document.submission.withdrawn' />
                    </span>,
                    'success'
                  )
                },
                PermissionError: cannotWithdraw,
                TerminationError: cannotWithdraw
              })
            }}
          >
            <Trans id='withdraw' message='Withdraw' />
          </button>
        ) : null}
        <button
          className='kp-button-outline max-lg:w-full'
          disabled={disableDuplicate}
          onClick={async () => {
            setDisableDuplicate(true)
            const {
              data: { duplicateDocument }
            } = await duplicate(
              data?.submission?.id,
              Intl.DateTimeFormat().resolvedOptions().timeZone
            )
            switch (duplicateDocument.__typename) {
              case 'Redirect': {
                const { newActionId: actionId } = duplicateDocument
                setRedirect({ pathname: `/${suite}/my/drafts/${actionId}` })
                alerts.type2(
                  <span>
                    <Trans id='pages.submitter.document.duplicated' />
                  </span>,
                  'info'
                )
                break
              }
              case 'InvalidDocumentError': {
                alerts.type2(
                  i18n._('pages.submitter.document.invalid.document'),
                  'error'
                )
                break
              }
              case 'PermissionError': {
                alerts.type2(
                  i18n._('pages.submitter.document.permission.error'),
                  'error'
                )
                break
              }
              default: {
                break
              }
            }
          }}
        >
          <Trans id='duplicate' message='Duplicate' />
        </button>
        {moveWorkflowStatus && hasSimulationSteps && (
          <MoreOptionsMenu
            animateHistoryButton={animateHistoryButton}
            menuRef={menuRef}
            onClick={() => {
              if (!showingHistory) {
                setAnimateFormbot(true)
                setAnimateHistoryButton(false)
              }
              setShowingHistory(!showingHistory)
            }}
            width={width}
          />
        )}
      </>
    )
  }
  if (error || notFound) return <NotFound />
  const hasWFTroubleshootPerms = false
  const simulation = get(data, 'submission.workflow.simulation')
  const hasSimulationSteps = get(simulation, 'steps', []).length > 1
  const workflowToggle =
    hasSimulationSteps && !moveWorkflowStatus
      ? {
          checked: showingWorkflow,
          onChange: () => setShowingWorkflow(!showingWorkflow)
        }
      : null

  const openHistory =
    hasSimulationSteps && moveWorkflowStatus && !moveWorkflowStatusWithVersions
      ? () => {
          menuRef.current.click()
          setAnimateHistoryButton(true)
        }
      : null
  const showSendback =
    showSendbackMessage &&
    get(
      findLast(get(simulation, 'steps', []), step => step.type === 'formfill'),
      'isSendback'
    )
  const titleValue =
    data?.submission?.meta?.title ||
    `${i18n._('pages.submitter.document.submission')}`

  const modalHeader = (
    <PortalOrange>
      <div className='flex flex-1 items-center max-sm:flex-wrap'>
        <h1
          title={titleValue}
          className='max-w-64 truncate text-base font-medium max-sm:pb-2'
        >
          {titleValue}
        </h1>
        {!!data?.submission?.workflow?.simulation && (
          <div className='flex w-full max-w-96 items-center rounded py-1 text-sm sm:ml-6 sm:px-2'>
            <DocumentStatus
              meta={data?.submission?.meta}
              simulation={data?.submission?.workflow?.simulation}
            />
          </div>
        )}
      </div>
      {(width > 1024 || !showingHistory) && (
        <div className='small-top-shadow mr-4 flex items-center gap-2 max-lg:fixed max-lg:bottom-0 max-lg:left-0 max-lg:w-full max-lg:justify-center max-lg:bg-white max-lg:p-4'>
          {getActionButtons()}
        </div>
      )}
    </PortalOrange>
  )

  if (showingWorkflow && !moveWorkflowStatus) {
    return (
      <>
        {modalHeader}
        <FormbotContainer
          workflowToggle={workflowToggle}
          header={
            <>
              {showSendback && (
                <SentBack
                  simulation={simulation}
                  onClose={() => setShowSendbackMessage(false)}
                />
              )}
            </>
          }
          formbot={
            <div>
              {hasSimulationSteps && (
                <WorkflowTracker
                  appId={get(data, 'submission.app.id')}
                  branding={data?.submission?.app?.branding}
                  documentId={documentId}
                  documentQuery={query}
                  hasWFTroubleshootPerms={hasWFTroubleshootPerms}
                  simulation={simulation}
                />
              )}
            </div>
          }
        />
      </>
    )
  }

  if (redirect) return <Navigate to={redirect} />
  const formbotProps = {
    animate: animateFormbot,
    hideSidebars: showingHistory,
    openHistory,
    workflowToggle
  }
  return (
    <>
      {modalHeader}{' '}
      <div className='flex'>
        <div className='flex-grow'>
          <FormbotContainer
            {...formbotProps}
            loading={loading}
            compareToggle={
              showCompareVersions &&
              previousDocument && {
                checked: compareVersions,
                onChange: () => setCompareVersions(!compareVersions)
              }
            }
            formbot={
              <Formbot.View
                className='formbot formbot-view'
                document={document}
                structure={structure}
                branding={data?.submission?.app?.branding}
                context={{
                  previousDocument,
                  compareVersions,
                  documentMeta: document.meta,
                  labelSize: data?.submission?.dataset?.labelSize
                }}
              />
            }
            width={width}
          />
        </div>
        <DocumentHistory.View
          documentHistory={{
            appId: data?.submission?.app?.id,
            dataSetId: data?.document?.dataset?.id,
            documentId,
            hasWFTroubleshootPerms,
            isTable: data?.document?.dataset?.isTable,
            firstPageId: data?.document?.app?.firstPageId,
            simulation
          }}
          documentQuery={query}
          hide={() => {
            setAnimateFormbot(true)
            setShowingHistory(false)
          }}
          isVisible={showingHistory}
          width={width}
        />
      </div>
    </>
  )
}

const getViewSubmissionQuery = documentId => ({
  variables: { documentId },
  fetchPolicy: 'no-cache',
  query: gql`
    query getSubmission($documentId: ID!) {
      submission(args: { documentId: $documentId, keyBy: ID }) {
        id
        data
        integration
        status
        workflow {
          simulation
        }
        documentSetId
        viewer {
          canEdit
          canCreateVersion
        }
        previousDocument {
          id
          data
          meta
        }
        documentSet {
          id
          documents {
            id
            data
            meta
          }
        }
        app {
          id
          branding {
            id
            color
            logo
            maxHeight
            alt
          }
        }
        dataset {
          id
          labelSize
          allowNewVersions
        }
        meta
        form {
          id
          template
          metaFields {
            id
            formKey
            type
            label
            details
          }
          integrationFields {
            id
            formKey
            type
            label
            details
          }
        }
        canWithdraw
      }
    }
  `
})
