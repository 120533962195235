{
  "Connection": [
    "ActionConnection",
    "ActionsPaginatedConnection",
    "AppsConnection",
    "AssociatedDocumentConnection",
    "AuditLogConnection",
    "CategoryConnection",
    "CategoryHistoryConnection",
    "DependentDocumentConnection",
    "DocumentConnection",
    "DocumentConnectionById",
    "DraftConnection",
    "FilterConnection",
    "GroupConnection",
    "GroupHistoryConnection",
    "IntegrationConnection",
    "RoleConnection",
    "RoleMembersConnection",
    "SearchSpaceConnection",
    "SubmissionConnection",
    "UserConnection",
    "UserHistoryConnection"
  ],
  "DuplicateDocumentResult": [
    "InvalidDocumentError",
    "PermissionError",
    "Redirect"
  ],
  "Edge": [
    "ActionEdge",
    "ActionsPaginatedEdge",
    "AppEdge",
    "AssociatedDocumentEdge",
    "AuditLogEdge",
    "CategoryEdge",
    "CategoryHistoryEdge",
    "DependentDocumentEdge",
    "DocumentEdge",
    "DocumentListFilterEdge",
    "DraftEdge",
    "GroupEdge",
    "GroupHistoryEdge",
    "IntegrationEdge",
    "RoleEdge",
    "RoleMemberEdge",
    "SpaceEdge",
    "SubmissionEdge",
    "UserEdge",
    "UserHistoryEdge"
  ],
  "Error": [
    "InvalidDocumentError",
    "InvalidFieldError",
    "PermissionError",
    "TerminationError"
  ],
  "Node": [
    "Action",
    "App",
    "AssociatedDocument",
    "AuditLog",
    "Category",
    "CategoryHistoryLog",
    "DependentDocument",
    "Document",
    "DocumentListFilter",
    "DocumentSet",
    "DraftNode",
    "Field",
    "FieldSchema",
    "Group",
    "GroupHistoryLog",
    "Integration",
    "Role",
    "Space",
    "SubmissionNode",
    "User",
    "UserHistoryLog",
    "VersionedDocument"
  ],
  "SpaceContent": ["App", "Link"],
  "UserResult": ["InvalidFieldErrors", "User"],
  "WithdrawSubmissionResult": ["PermissionError", "Success", "TerminationError"]
}
