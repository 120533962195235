/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import { compact, flatMap, get } from 'lodash'

import { productBuilder } from '../../../components/feature-flags'
import { RequiredConfig } from './config'
import Edit from './edit'
import * as filters from './filters.jsx'
import { ReactComponent as Icon } from './icon.svg.jsx'
import * as progressiveDisclosure from './progressive-disclosure'
import View from './view'

function subFields ({ id, formKey, label, details }) {
  return compact(
    flatMap(get(details, 'outputFields'), field => {
      return [
        {
          id: `${id}.${field.path}`,
          type: field.type,
          formKey: `${formKey}.startTerm.${field.path}`,
          label: `${label} - startTerm - ${field.label}`,
          details: field.details,
          autoUpdate: !!details?.autoUpdate?.enabled,
          childrenTemplate: field.childrenTemplate,
          // These are unsortable because the data referenced in output fields is
          // stored as a raw string, so the database can't sort or filter on any
          // of those fields. @see https://kualico.atlassian.net/browse/PLT-467
          unsortable: true
        },
        {
          id: `${id}.${field.path}`,
          type: field.type,
          formKey: `${formKey}.endTerm.${field.path}`,
          label: `${label} - endTerm - ${field.label}`,
          details: field.details,
          autoUpdate: !!details?.autoUpdate?.enabled,
          childrenTemplate: field.childrenTemplate,
          // These are unsortable because the data referenced in output fields is
          // stored as a raw string, so the database can't sort or filter on any
          // of those fields. @see https://kualico.atlassian.net/browse/PLT-467
          unsortable: true
        }
      ]
    })
  )
}

export default {
  getAssembler: ({ Basic, GriddedText }, gridded) =>
    gridded ? GriddedText : Basic,
  RequiredConfig: productBuilder && RequiredConfig,
  defaultValue: { endTerm: { label: 'indefinite', indefinite: true } },
  Edit,
  sortSuffix: '.label',
  meta: { label: 'Terms', Icon, category: 'Special', hidden: !productBuilder },
  subFields,
  progressiveDisclosure,
  validateShape: v => v.any(),
  View,
  filters
}
