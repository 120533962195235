/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
// THIS IS A GENERATED FILE. PLEASE DO NOT EDIT DIRECTLY
// See https://docs.kualibuild.ninja/scripts/import-icons for instructions on how to re-generate this file
import styled from 'styled-components'
import { height, space, width } from 'styled-system'

import { ReactComponent as ActionsIcon } from './raw/actions.svg.jsx'
import { ReactComponent as AddIcon } from './raw/add.svg.jsx'
import { ReactComponent as AlertErrorIcon } from './raw/alert-error.svg.jsx'
import { ReactComponent as AlertHelpIcon } from './raw/alert-help.svg.jsx'
import { ReactComponent as AlertInfoIcon } from './raw/alert-info.svg.jsx'
import { ReactComponent as AlertWarningIcon } from './raw/alert-warning.svg.jsx'
import { ReactComponent as AnalyticsIcon } from './raw/analytics.svg.jsx'
import { ReactComponent as AnchorIcon } from './raw/anchor.svg.jsx'
import { ReactComponent as AppSwitcherIcon } from './raw/app-switcher.svg.jsx'
import { ReactComponent as AppWindowIcon } from './raw/app-window.svg.jsx'
import { ReactComponent as ArchiveIcon } from './raw/archive.svg.jsx'
import { ReactComponent as AttachmentIcon } from './raw/attachment.svg.jsx'
import { ReactComponent as AwardIcon } from './raw/award.svg.jsx'
import { ReactComponent as BackIcon } from './raw/back.svg.jsx'
import { ReactComponent as BadgeIcon } from './raw/badge.svg.jsx'
import { ReactComponent as BookIcon } from './raw/book.svg.jsx'
import { ReactComponent as BookmarkIcon } from './raw/bookmark.svg.jsx'
import { ReactComponent as BranchIcon } from './raw/branch.svg.jsx'
import { ReactComponent as BrandsettingsIcon } from './raw/brandsettings.svg.jsx'
import { ReactComponent as BusinessCardIcon } from './raw/business-card.svg.jsx'
import { ReactComponent as CalculateIcon } from './raw/calculate.svg.jsx'
import { ReactComponent as CameraIcon } from './raw/camera.svg.jsx'
import { ReactComponent as ChatIcon } from './raw/chat.svg.jsx'
import { ReactComponent as CheckCircleIcon } from './raw/check-circle.svg.jsx'
import { ReactComponent as CheckIcon } from './raw/check.svg.jsx'
import { ReactComponent as ClipboardIcon } from './raw/clipboard.svg.jsx'
import { ReactComponent as CloseIcon } from './raw/close.svg.jsx'
import { ReactComponent as CloudIcon } from './raw/cloud.svg.jsx'
import { ReactComponent as CollapsedIcon } from './raw/collapsed.svg.jsx'
import { ReactComponent as ComputerIcon } from './raw/computer.svg.jsx'
import { ReactComponent as ConfigurationIcon } from './raw/configuration.svg.jsx'
import { ReactComponent as CowboyHatIcon } from './raw/cowboy-hat.svg.jsx'
import { ReactComponent as CursorIcon } from './raw/cursor.svg.jsx'
import { ReactComponent as DashboardIcon } from './raw/dashboard.svg.jsx'
import { ReactComponent as DateIcon } from './raw/date.svg.jsx'
import { ReactComponent as DeleteIcon } from './raw/delete.svg.jsx'
import { ReactComponent as DenyOverrideIcon } from './raw/deny-override.svg.jsx'
import { ReactComponent as DislikeIcon } from './raw/dislike.svg.jsx'
import { ReactComponent as DownloadIcon } from './raw/download.svg.jsx'
import { ReactComponent as DragSortIcon } from './raw/drag-sort.svg.jsx'
import { ReactComponent as DragIcon } from './raw/drag.svg.jsx'
import { ReactComponent as DuplicateIcon } from './raw/duplicate.svg.jsx'
import { ReactComponent as EchoIcon } from './raw/echo.svg.jsx'
import { ReactComponent as EditAppIcon } from './raw/edit-app.svg.jsx'
import { ReactComponent as EditDocIcon } from './raw/edit-doc.svg.jsx'
import { ReactComponent as EditIcon } from './raw/edit.svg.jsx'
import { ReactComponent as EmailIcon } from './raw/email.svg.jsx'
import { ReactComponent as EmojiHappyIcon } from './raw/emoji-happy.svg.jsx'
import { ReactComponent as EmojiMehIcon } from './raw/emoji-meh.svg.jsx'
import { ReactComponent as EmojiSadIcon } from './raw/emoji-sad.svg.jsx'
import { ReactComponent as ExpandedIcon } from './raw/expanded.svg.jsx'
import { ReactComponent as ExportAllIcon } from './raw/export-all.svg.jsx'
import { ReactComponent as ExportViewIcon } from './raw/export-view.svg.jsx'
import { ReactComponent as ExportIcon } from './raw/export.svg.jsx'
import { ReactComponent as FavoriteFilledIcon } from './raw/favorite-filled.svg.jsx'
import { ReactComponent as FavoriteIcon } from './raw/favorite.svg.jsx'
import { ReactComponent as FileImageIcon } from './raw/file-image.svg.jsx'
import { ReactComponent as FilterIcon } from './raw/filter.svg.jsx'
import { ReactComponent as FingerPointingIcon } from './raw/finger-pointing.svg.jsx'
import { ReactComponent as FlagIcon } from './raw/flag.svg.jsx'
import { ReactComponent as FolderOpenIcon } from './raw/folder-open.svg.jsx'
import { ReactComponent as FolderIcon } from './raw/folder.svg.jsx'
import { ReactComponent as GenericFileIcon } from './raw/generic-file.svg.jsx'
import { ReactComponent as HiddenIcon } from './raw/hidden.svg.jsx'
import { ReactComponent as HistoryIcon } from './raw/history.svg.jsx'
import { ReactComponent as HomeIcon } from './raw/home.svg.jsx'
import { ReactComponent as HourglassIcon } from './raw/hourglass.svg.jsx'
import { ReactComponent as HtmlSelectIcon } from './raw/html-select.svg.jsx'
import { ReactComponent as InstitutionIcon } from './raw/institution.svg.jsx'
import { ReactComponent as IntegrationsIcon } from './raw/integrations.svg.jsx'
import { ReactComponent as KeyIcon } from './raw/key.svg.jsx'
import { ReactComponent as KeyboardArrowDownIcon } from './raw/keyboard-arrow-down.svg.jsx'
import { ReactComponent as LikeIcon } from './raw/like.svg.jsx'
import { ReactComponent as LinkIcon } from './raw/link.svg.jsx'
import { ReactComponent as LocationIcon } from './raw/location.svg.jsx'
import { ReactComponent as LockIcon } from './raw/lock.svg.jsx'
import { ReactComponent as LoginIcon } from './raw/login.svg.jsx'
import { ReactComponent as LogoutIcon } from './raw/logout.svg.jsx'
import { ReactComponent as MapIcon } from './raw/map.svg.jsx'
import { ReactComponent as MaximizeIcon } from './raw/maximize.svg.jsx'
import { ReactComponent as MenuVerticalIcon } from './raw/menu-vertical.svg.jsx'
import { ReactComponent as MenuIcon } from './raw/menu.svg.jsx'
import { ReactComponent as MessageIcon } from './raw/message.svg.jsx'
import { ReactComponent as MinimizeIcon } from './raw/minimize.svg.jsx'
import { ReactComponent as MobileIcon } from './raw/mobile.svg.jsx'
import { ReactComponent as ModuleIcon } from './raw/module.svg.jsx'
import { ReactComponent as MoneyIcon } from './raw/money.svg.jsx'
import { ReactComponent as MoonIcon } from './raw/moon.svg.jsx'
import { ReactComponent as MoveLeftIcon } from './raw/move-left.svg.jsx'
import { ReactComponent as MoveRightIcon } from './raw/move-right.svg.jsx'
import { ReactComponent as MoveIcon } from './raw/move.svg.jsx'
import { ReactComponent as NewsIcon } from './raw/news.svg.jsx'
import { ReactComponent as NotificationOffIcon } from './raw/notification-off.svg.jsx'
import { ReactComponent as NotificationRingIcon } from './raw/notification-ring.svg.jsx'
import { ReactComponent as NotificationIcon } from './raw/notification.svg.jsx'
import { ReactComponent as PenDrawIcon } from './raw/pen-draw.svg.jsx'
import { ReactComponent as PhotosIcon } from './raw/photos.svg.jsx'
import { ReactComponent as PinIcon } from './raw/pin.svg.jsx'
import { ReactComponent as PlayIcon } from './raw/play.svg.jsx'
import { ReactComponent as PointIcon } from './raw/point.svg.jsx'
import { ReactComponent as PrintIcon } from './raw/print.svg.jsx'
import { ReactComponent as ProfileIcon } from './raw/profile.svg.jsx'
import { ReactComponent as RatingEmptyIcon } from './raw/rating-empty.svg.jsx'
import { ReactComponent as RatingFilledIcon } from './raw/rating-filled.svg.jsx'
import { ReactComponent as RatingHalfFilledIcon } from './raw/rating-half-filled.svg.jsx'
import { ReactComponent as RedoIcon } from './raw/redo.svg.jsx'
import { ReactComponent as RemoveIcon } from './raw/remove.svg.jsx'
import { ReactComponent as ReportIcon } from './raw/report.svg.jsx'
import { ReactComponent as SearchIcon } from './raw/search.svg.jsx'
import { ReactComponent as SelectDownArrowIcon } from './raw/select-down-arrow.svg.jsx'
import { ReactComponent as SelectUpArrowIcon } from './raw/select-up-arrow.svg.jsx'
import { ReactComponent as SendEmailIcon } from './raw/send-email.svg.jsx'
import { ReactComponent as SendbackOverrideIcon } from './raw/sendback-override.svg.jsx'
import { ReactComponent as SendbackIcon } from './raw/sendback.svg.jsx'
import { ReactComponent as SettingsIcon } from './raw/settings.svg.jsx'
import { ReactComponent as ShareIcon } from './raw/share.svg.jsx'
import { ReactComponent as SortIcon } from './raw/sort.svg.jsx'
import { ReactComponent as StarsIcon } from './raw/stars.svg.jsx'
import { ReactComponent as StatusIcon } from './raw/status.svg.jsx'
import { ReactComponent as SunIcon } from './raw/sun.svg.jsx'
import { ReactComponent as SupportIcon } from './raw/support.svg.jsx'
import { ReactComponent as SyncIcon } from './raw/sync.svg.jsx'
import { ReactComponent as TagIcon } from './raw/tag.svg.jsx'
import { ReactComponent as TargetIcon } from './raw/target.svg.jsx'
import { ReactComponent as TimeIcon } from './raw/time.svg.jsx'
import { ReactComponent as ToolIcon } from './raw/tool.svg.jsx'
import { ReactComponent as UndoIcon } from './raw/undo.svg.jsx'
import { ReactComponent as UnlockIcon } from './raw/unlock.svg.jsx'
import { ReactComponent as UserInfoIcon } from './raw/user-info.svg.jsx'
import { ReactComponent as UserIcon } from './raw/user.svg.jsx'
import { ReactComponent as UsersIcon } from './raw/users.svg.jsx'
import { ReactComponent as VisibleIcon } from './raw/visible.svg.jsx'
import { ReactComponent as WorkflowBackIcon } from './raw/workflow-back.svg.jsx'
import { ReactComponent as WorkflowCheckIcon } from './raw/workflow-check.svg.jsx'
import { ReactComponent as WorkflowCompleteIcon } from './raw/workflow-complete.svg.jsx'
import { ReactComponent as WorkflowErrorIcon } from './raw/workflow-error.svg.jsx'
import { ReactComponent as WorkflowNotifiedIcon } from './raw/workflow-notified.svg.jsx'
import { ReactComponent as WorkflowReassignedIcon } from './raw/workflow-reassigned.svg.jsx'
import { ReactComponent as WorkflowRetryIcon } from './raw/workflow-retry.svg.jsx'
import { ReactComponent as WorkflowSkippedIcon } from './raw/workflow-skipped.svg.jsx'
import { ReactComponent as WorkflowXIcon } from './raw/workflow-x.svg.jsx'
import { ReactComponent as ZoomInIcon } from './raw/zoom-in.svg.jsx'
import { ReactComponent as ZoomOutIcon } from './raw/zoom-out.svg.jsx'

const Base = styled.div.attrs(p => ({ 'aria-hidden': !p['aria-label'] }))`
  flex-shrink: 0;
  ${space}
  ${width}
  ${height}
`

const defaultProps = as => ({ as, fill: 'var(--svg-base)' })

export const Actions = styled(Base)``
Actions.defaultProps = defaultProps(ActionsIcon)

export const Add = styled(Base)``
Add.defaultProps = defaultProps(AddIcon)

export const AlertError = styled(Base)``
AlertError.defaultProps = defaultProps(AlertErrorIcon)

export const AlertHelp = styled(Base)``
AlertHelp.defaultProps = defaultProps(AlertHelpIcon)

export const AlertInfo = styled(Base)``
AlertInfo.defaultProps = defaultProps(AlertInfoIcon)

export const AlertWarning = styled(Base)``
AlertWarning.defaultProps = defaultProps(AlertWarningIcon)

export const Analytics = styled(Base)``
Analytics.defaultProps = defaultProps(AnalyticsIcon)

export const Anchor = styled(Base)``
Anchor.defaultProps = defaultProps(AnchorIcon)

export const AppSwitcher = styled(Base)``
AppSwitcher.defaultProps = defaultProps(AppSwitcherIcon)

export const AppWindow = styled(Base)``
AppWindow.defaultProps = defaultProps(AppWindowIcon)

export const Archive = styled(Base)``
Archive.defaultProps = defaultProps(ArchiveIcon)

export const Attachment = styled(Base)``
Attachment.defaultProps = defaultProps(AttachmentIcon)

export const Award = styled(Base)``
Award.defaultProps = defaultProps(AwardIcon)

export const Back = styled(Base)``
Back.defaultProps = defaultProps(BackIcon)

export const Badge = styled(Base)``
Badge.defaultProps = defaultProps(BadgeIcon)

export const Book = styled(Base)``
Book.defaultProps = defaultProps(BookIcon)

export const Bookmark = styled(Base)``
Bookmark.defaultProps = defaultProps(BookmarkIcon)

export const Branch = styled(Base)``
Branch.defaultProps = defaultProps(BranchIcon)

export const Brandsettings = styled(Base)``
Brandsettings.defaultProps = defaultProps(BrandsettingsIcon)

export const BusinessCard = styled(Base)``
BusinessCard.defaultProps = defaultProps(BusinessCardIcon)

export const Calculate = styled(Base)``
Calculate.defaultProps = defaultProps(CalculateIcon)

export const Camera = styled(Base)``
Camera.defaultProps = defaultProps(CameraIcon)

export const Chat = styled(Base)``
Chat.defaultProps = defaultProps(ChatIcon)

export const CheckCircle = styled(Base)``
CheckCircle.defaultProps = defaultProps(CheckCircleIcon)

export const Check = styled(Base)``
Check.defaultProps = defaultProps(CheckIcon)

export const Clipboard = styled(Base)``
Clipboard.defaultProps = defaultProps(ClipboardIcon)

export const Close = styled(Base)``
Close.defaultProps = defaultProps(CloseIcon)

export const Cloud = styled(Base)``
Cloud.defaultProps = defaultProps(CloudIcon)

export const Collapsed = styled(Base)``
Collapsed.defaultProps = defaultProps(CollapsedIcon)

export const Computer = styled(Base)``
Computer.defaultProps = defaultProps(ComputerIcon)

export const Configuration = styled(Base)``
Configuration.defaultProps = defaultProps(ConfigurationIcon)

export const CowboyHat = styled(Base)``
CowboyHat.defaultProps = defaultProps(CowboyHatIcon)

export const Cursor = styled(Base)``
Cursor.defaultProps = defaultProps(CursorIcon)

export const Dashboard = styled(Base)``
Dashboard.defaultProps = defaultProps(DashboardIcon)

export const Date = styled(Base)``
Date.defaultProps = defaultProps(DateIcon)

export const Delete = styled(Base)``
Delete.defaultProps = defaultProps(DeleteIcon)

export const DenyOverride = styled(Base)``
DenyOverride.defaultProps = defaultProps(DenyOverrideIcon)

export const Dislike = styled(Base)``
Dislike.defaultProps = defaultProps(DislikeIcon)

export const Download = styled(Base)``
Download.defaultProps = defaultProps(DownloadIcon)

export const DragSort = styled(Base)``
DragSort.defaultProps = defaultProps(DragSortIcon)

export const Drag = styled(Base)``
Drag.defaultProps = defaultProps(DragIcon)

export const Duplicate = styled(Base)``
Duplicate.defaultProps = defaultProps(DuplicateIcon)

export const Echo = styled(Base)``
Echo.defaultProps = defaultProps(EchoIcon)

export const EditApp = styled(Base)``
EditApp.defaultProps = defaultProps(EditAppIcon)

export const EditDoc = styled(Base)``
EditDoc.defaultProps = defaultProps(EditDocIcon)

export const Edit = styled(Base)``
Edit.defaultProps = defaultProps(EditIcon)

export const Email = styled(Base)``
Email.defaultProps = defaultProps(EmailIcon)

export const EmojiHappy = styled(Base)``
EmojiHappy.defaultProps = defaultProps(EmojiHappyIcon)

export const EmojiMeh = styled(Base)``
EmojiMeh.defaultProps = defaultProps(EmojiMehIcon)

export const EmojiSad = styled(Base)``
EmojiSad.defaultProps = defaultProps(EmojiSadIcon)

export const Expanded = styled(Base)``
Expanded.defaultProps = defaultProps(ExpandedIcon)

export const ExportAll = styled(Base)``
ExportAll.defaultProps = defaultProps(ExportAllIcon)

export const ExportView = styled(Base)``
ExportView.defaultProps = defaultProps(ExportViewIcon)

export const Export = styled(Base)``
Export.defaultProps = defaultProps(ExportIcon)

export const FavoriteFilled = styled(Base)``
FavoriteFilled.defaultProps = defaultProps(FavoriteFilledIcon)

export const Favorite = styled(Base)``
Favorite.defaultProps = defaultProps(FavoriteIcon)

export const FileImage = styled(Base)``
FileImage.defaultProps = defaultProps(FileImageIcon)

export const Filter = styled(Base)``
Filter.defaultProps = defaultProps(FilterIcon)

export const FingerPointing = styled(Base)``
FingerPointing.defaultProps = defaultProps(FingerPointingIcon)

export const Flag = styled(Base)``
Flag.defaultProps = defaultProps(FlagIcon)

export const FolderOpen = styled(Base)``
FolderOpen.defaultProps = defaultProps(FolderOpenIcon)

export const Folder = styled(Base)``
Folder.defaultProps = defaultProps(FolderIcon)

export const GenericFile = styled(Base)``
GenericFile.defaultProps = defaultProps(GenericFileIcon)

export const Hidden = styled(Base)``
Hidden.defaultProps = defaultProps(HiddenIcon)

export const History = styled(Base)``
History.defaultProps = defaultProps(HistoryIcon)

export const Home = styled(Base)``
Home.defaultProps = defaultProps(HomeIcon)

export const Hourglass = styled(Base)``
Hourglass.defaultProps = defaultProps(HourglassIcon)

export const HtmlSelect = styled(Base)``
HtmlSelect.defaultProps = defaultProps(HtmlSelectIcon)

export const Institution = styled(Base)``
Institution.defaultProps = defaultProps(InstitutionIcon)

export const Integrations = styled(Base)``
Integrations.defaultProps = defaultProps(IntegrationsIcon)

export const Key = styled(Base)``
Key.defaultProps = defaultProps(KeyIcon)

export const KeyboardArrowDown = styled(Base)``
KeyboardArrowDown.defaultProps = defaultProps(KeyboardArrowDownIcon)

export const Like = styled(Base)``
Like.defaultProps = defaultProps(LikeIcon)

export const Link = styled(Base)``
Link.defaultProps = defaultProps(LinkIcon)

export const Location = styled(Base)``
Location.defaultProps = defaultProps(LocationIcon)

export const Lock = styled(Base)``
Lock.defaultProps = defaultProps(LockIcon)

export const Login = styled(Base)``
Login.defaultProps = defaultProps(LoginIcon)

export const Logout = styled(Base)``
Logout.defaultProps = defaultProps(LogoutIcon)

export const Map = styled(Base)``
Map.defaultProps = defaultProps(MapIcon)

export const Maximize = styled(Base)``
Maximize.defaultProps = defaultProps(MaximizeIcon)

export const MenuVertical = styled(Base)``
MenuVertical.defaultProps = defaultProps(MenuVerticalIcon)

export const Menu = styled(Base)``
Menu.defaultProps = defaultProps(MenuIcon)

export const Message = styled(Base)``
Message.defaultProps = defaultProps(MessageIcon)

export const Minimize = styled(Base)``
Minimize.defaultProps = defaultProps(MinimizeIcon)

export const Mobile = styled(Base)``
Mobile.defaultProps = defaultProps(MobileIcon)

export const Module = styled(Base)``
Module.defaultProps = defaultProps(ModuleIcon)

export const Money = styled(Base)``
Money.defaultProps = defaultProps(MoneyIcon)

export const Moon = styled(Base)``
Moon.defaultProps = defaultProps(MoonIcon)

export const MoveLeft = styled(Base)``
MoveLeft.defaultProps = defaultProps(MoveLeftIcon)

export const MoveRight = styled(Base)``
MoveRight.defaultProps = defaultProps(MoveRightIcon)

export const Move = styled(Base)``
Move.defaultProps = defaultProps(MoveIcon)

export const News = styled(Base)``
News.defaultProps = defaultProps(NewsIcon)

export const NotificationOff = styled(Base)``
NotificationOff.defaultProps = defaultProps(NotificationOffIcon)

export const NotificationRing = styled(Base)``
NotificationRing.defaultProps = defaultProps(NotificationRingIcon)

export const Notification = styled(Base)``
Notification.defaultProps = defaultProps(NotificationIcon)

export const PenDraw = styled(Base)``
PenDraw.defaultProps = defaultProps(PenDrawIcon)

export const Photos = styled(Base)``
Photos.defaultProps = defaultProps(PhotosIcon)

export const Pin = styled(Base)``
Pin.defaultProps = defaultProps(PinIcon)

export const Play = styled(Base)``
Play.defaultProps = defaultProps(PlayIcon)

export const Point = styled(Base)``
Point.defaultProps = defaultProps(PointIcon)

export const Print = styled(Base)``
Print.defaultProps = defaultProps(PrintIcon)

export const Profile = styled(Base)``
Profile.defaultProps = defaultProps(ProfileIcon)

export const RatingEmpty = styled(Base)``
RatingEmpty.defaultProps = defaultProps(RatingEmptyIcon)

export const RatingFilled = styled(Base)``
RatingFilled.defaultProps = defaultProps(RatingFilledIcon)

export const RatingHalfFilled = styled(Base)``
RatingHalfFilled.defaultProps = defaultProps(RatingHalfFilledIcon)

export const Redo = styled(Base)``
Redo.defaultProps = defaultProps(RedoIcon)

export const Remove = styled(Base)``
Remove.defaultProps = defaultProps(RemoveIcon)

export const Report = styled(Base)``
Report.defaultProps = defaultProps(ReportIcon)

export const Search = styled(Base)``
Search.defaultProps = defaultProps(SearchIcon)

export const SelectDownArrow = styled(Base)``
SelectDownArrow.defaultProps = defaultProps(SelectDownArrowIcon)

export const SelectUpArrow = styled(Base)``
SelectUpArrow.defaultProps = defaultProps(SelectUpArrowIcon)

export const SendEmail = styled(Base)``
SendEmail.defaultProps = defaultProps(SendEmailIcon)

export const SendbackOverride = styled(Base)``
SendbackOverride.defaultProps = defaultProps(SendbackOverrideIcon)

export const Sendback = styled(Base)``
Sendback.defaultProps = defaultProps(SendbackIcon)

export const Settings = styled(Base)``
Settings.defaultProps = defaultProps(SettingsIcon)

export const Share = styled(Base)``
Share.defaultProps = defaultProps(ShareIcon)

export const Sort = styled(Base)``
Sort.defaultProps = defaultProps(SortIcon)

export const Stars = styled(Base)``
Stars.defaultProps = defaultProps(StarsIcon)

export const Status = styled(Base)``
Status.defaultProps = defaultProps(StatusIcon)

export const Sun = styled(Base)``
Sun.defaultProps = defaultProps(SunIcon)

export const Support = styled(Base)``
Support.defaultProps = defaultProps(SupportIcon)

export const Sync = styled(Base)``
Sync.defaultProps = defaultProps(SyncIcon)

export const Tag = styled(Base)``
Tag.defaultProps = defaultProps(TagIcon)

export const Target = styled(Base)``
Target.defaultProps = defaultProps(TargetIcon)

export const Time = styled(Base)``
Time.defaultProps = defaultProps(TimeIcon)

export const Tool = styled(Base)``
Tool.defaultProps = defaultProps(ToolIcon)

export const Undo = styled(Base)``
Undo.defaultProps = defaultProps(UndoIcon)

export const Unlock = styled(Base)``
Unlock.defaultProps = defaultProps(UnlockIcon)

export const UserInfo = styled(Base)``
UserInfo.defaultProps = defaultProps(UserInfoIcon)

export const User = styled(Base)``
User.defaultProps = defaultProps(UserIcon)

export const Users = styled(Base)``
Users.defaultProps = defaultProps(UsersIcon)

export const Visible = styled(Base)``
Visible.defaultProps = defaultProps(VisibleIcon)

export const WorkflowBack = styled(Base)``
WorkflowBack.defaultProps = defaultProps(WorkflowBackIcon)

export const WorkflowCheck = styled(Base)``
WorkflowCheck.defaultProps = defaultProps(WorkflowCheckIcon)

export const WorkflowComplete = styled(Base)``
WorkflowComplete.defaultProps = defaultProps(WorkflowCompleteIcon)

export const WorkflowError = styled(Base)``
WorkflowError.defaultProps = defaultProps(WorkflowErrorIcon)

export const WorkflowNotified = styled(Base)``
WorkflowNotified.defaultProps = defaultProps(WorkflowNotifiedIcon)

export const WorkflowReassigned = styled(Base)``
WorkflowReassigned.defaultProps = defaultProps(WorkflowReassignedIcon)

export const WorkflowRetry = styled(Base)``
WorkflowRetry.defaultProps = defaultProps(WorkflowRetryIcon)

export const WorkflowSkipped = styled(Base)``
WorkflowSkipped.defaultProps = defaultProps(WorkflowSkippedIcon)

export const WorkflowX = styled(Base)``
WorkflowX.defaultProps = defaultProps(WorkflowXIcon)

export const ZoomIn = styled(Base)``
ZoomIn.defaultProps = defaultProps(ZoomInIcon)

export const ZoomOut = styled(Base)``
ZoomOut.defaultProps = defaultProps(ZoomOutIcon)
