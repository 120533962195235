/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import * as React from "react";

export const ReactComponent = ({ ...props }) => (
  <svg width={`16`} height={`16`} viewBox={`0 0 16 16`}{...props}><path fillRule={`evenodd`} clipRule={`evenodd`} d={`M0.775909 2.77588C0.775909 1.67131 1.67134 0.775879 2.77591 0.775879H13.2241C14.3287 0.775879 15.2241 1.67131 15.2241 2.77588V13.2241C15.2241 14.3286 14.3287 15.2241 13.2241 15.2241H2.77591C1.67134 15.2241 0.775909 14.3286 0.775909 13.2241V2.77588ZM4.45566 2.67815C4.36793 2.67756 4.28095 2.69434 4.19972 2.7275C4.1185 2.76067 4.04464 2.80958 3.9824 2.87141C3.92016 2.93324 3.87076 3.00677 3.83705 3.08777C3.80334 3.16877 3.78599 3.25564 3.78599 3.34337V12.6566C3.78599 12.833 3.85608 13.0022 3.98084 13.127C4.10559 13.2517 4.27479 13.3218 4.45122 13.3218C4.62765 13.3218 4.79686 13.2517 4.92161 13.127C5.04637 13.0022 5.11645 12.833 5.11645 12.6566V8.99777H11.7687C12.0614 8.99777 12.159 8.85586 12.19 8.76716C12.2211 8.67846 12.2344 8.50994 12.0038 8.32368L9.35174 6.20381C9.33936 6.19259 9.32947 6.1789 9.3227 6.16362C9.31594 6.14835 9.31244 6.13183 9.31244 6.11512C9.31244 6.09841 9.31594 6.08189 9.3227 6.06661C9.32947 6.05134 9.33936 6.03764 9.35174 6.02642L12.0127 3.90656C12.2433 3.7203 12.2255 3.54734 12.1989 3.46307C12.1723 3.37881 12.0659 3.23246 11.7687 3.23246H5.10715C5.0844 3.09791 5.02061 2.97263 4.92318 2.87455C4.79938 2.74992 4.63132 2.67932 4.45566 2.67815Z`}/></svg>
);

let src = "<svg width=\"16\" height=\"16\" viewBox=\"0 0 16 16\" xmlns=\"http://www.w3.org/2000/svg\">\n<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M0.775909 2.77588C0.775909 1.67131 1.67134 0.775879 2.77591 0.775879H13.2241C14.3287 0.775879 15.2241 1.67131 15.2241 2.77588V13.2241C15.2241 14.3286 14.3287 15.2241 13.2241 15.2241H2.77591C1.67134 15.2241 0.775909 14.3286 0.775909 13.2241V2.77588ZM4.45566 2.67815C4.36793 2.67756 4.28095 2.69434 4.19972 2.7275C4.1185 2.76067 4.04464 2.80958 3.9824 2.87141C3.92016 2.93324 3.87076 3.00677 3.83705 3.08777C3.80334 3.16877 3.78599 3.25564 3.78599 3.34337V12.6566C3.78599 12.833 3.85608 13.0022 3.98084 13.127C4.10559 13.2517 4.27479 13.3218 4.45122 13.3218C4.62765 13.3218 4.79686 13.2517 4.92161 13.127C5.04637 13.0022 5.11645 12.833 5.11645 12.6566V8.99777H11.7687C12.0614 8.99777 12.159 8.85586 12.19 8.76716C12.2211 8.67846 12.2344 8.50994 12.0038 8.32368L9.35174 6.20381C9.33936 6.19259 9.32947 6.1789 9.3227 6.16362C9.31594 6.14835 9.31244 6.13183 9.31244 6.11512C9.31244 6.09841 9.31594 6.08189 9.3227 6.06661C9.32947 6.05134 9.33936 6.03764 9.35174 6.02642L12.0127 3.90656C12.2433 3.7203 12.2255 3.54734 12.1989 3.46307C12.1723 3.37881 12.0659 3.23246 11.7687 3.23246H5.10715C5.0844 3.09791 5.02061 2.97263 4.92318 2.87455C4.79938 2.74992 4.63132 2.67932 4.45566 2.67815Z\"/>\n</svg>\n";
export default src;
