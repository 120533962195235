/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import { Trans } from '@lingui/react'
import cx from 'clsx'
import _ from 'lodash'
import React from 'react'

import * as Icons from '../../icons'

export default function compareChangesDecorator ({ template, context, value }) {
  const { changes, selected, setSelected } = context.compare || {}
  const change = changes?.find(c => c.formKey === template.formKey)
  if (!change) return {}
  const focused = template.formKey === selected
  context = _.omit(context, 'compare')
  return {
    label: label => (
      <div className='flex flex-col items-start gap-1'>
        {change.type === 'changed' && (
          <div className='kp-badge bg-orange-200 text-[black]'>
            <Trans id='compare_changes.changed' message='Changed' />
          </div>
        )}
        {change.type === 'removed' && (
          <div className='kp-badge bg-[#d22e2f] text-[white]'>
            <Trans id='compare_changes.removed' message='Removed' />
          </div>
        )}
        {change.type === 'added' && (
          <div className='kp-badge bg-green-200 text-[black]'>
            <Trans id='compare_changes.added' message='Added' />
          </div>
        )}
        {label}
      </div>
    ),
    component: (component, rerender) => {
      return (
        <div
          className={cx('relative h-full', {
            'border-4 border-blue-500 bg-orange-100 dark:bg-orange-950': focused
          })}
          onClick={() => setSelected(template.formKey)}
        >
          {focused && (
            <div
              data-testid='current-change'
              className='absolute bottom-[calc(100%+2px)] left-0 flex w-full flex-col items-center gap-2 rounded-lg bg-orange-100 p-4 shadow-md dark:bg-orange-950 md:-left-20 md:w-[calc(100%+160px)] md:flex-row'
            >
              {change.type === 'added' ? (
                <>
                  <NewField />
                  <Icons.Back className='-rotate-90 md:rotate-180' />
                  {rerender(value, template, context)}
                </>
              ) : change.type === 'removed' ? (
                <>
                  {rerender(change.prev.value, change.prev.template, context)}
                  <Icons.Back className='-rotate-90 md:rotate-180' />
                  <RemovedField />
                </>
              ) : (
                <>
                  {rerender(change.prev.value, change.prev.template, context)}
                  <Icons.Back className='-rotate-90 md:rotate-180' />
                  {rerender(value, template, context)}
                </>
              )}
            </div>
          )}
          {component}
        </div>
      )
    }
  }
}

function NewField () {
  return (
    <div className='flex flex-1 items-center justify-center gap-2 py-4'>
      <Icons.Add className='fill-green-400' />
      <Trans id='compare_changes.new_field' message='New Field' />
    </div>
  )
}

function RemovedField () {
  return (
    <div className='flex flex-1 items-center justify-center gap-2 py-4'>
      <Icons.Remove className='fill-red-500' />
      <Trans id='compare_changes.removed_field' message='Removed Field' />
    </div>
  )
}
