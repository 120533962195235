/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import { Trans } from '@lingui/react'
import React from 'react'

import RefreshButton from './refresh-button'

export default ({
  children,
  refreshing,
  refreshSimulation,
  title,
  ...rest
}) => (
  <div className='workflow-tracker-box bg-white shadow-md' {...rest}>
    <h3 className='flex flex-wrap items-center justify-between bg-[#efefed] px-8 py-3 text-base font-bold text-[#636463] dark:bg-light-gray-300 dark:text-black'>
      <div>{title}</div>
      {refreshSimulation && (
        <RefreshButton
          label={
            <Trans id='pagesrunner.workflow.latest' message='Get latest' />
          }
          refreshing={refreshing}
          refreshSimulation={refreshSimulation}
        />
      )}
    </h3>
    <div className='px-8 py-5'>{children}</div>
  </div>
)
