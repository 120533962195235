/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import { gql, useQuery } from '@apollo/client'
import { capitalize } from 'lodash'
import React from 'react'
import { Link } from 'react-router-dom'

import Spinner from '../../../components/spinner'
import * as Icons from '../../../icons'

export default function AssociationsView ({ details, context }) {
  const { data, loading } = useAssociationsQuery(details, context?.documentId)
  const labelMap = createLabelMap(data)

  return (
    <div>
      {loading ? (
        <div className='flex h-8 w-full items-center justify-center'>
          <Spinner size={16} />
        </div>
      ) : data.length === 0 ? (
        <div className='flex h-8 w-full items-center justify-center gap-1 rounded bg-light-gray-100 text-dark-gray-100'>
          <Icons.Link className='h-4 w-4' />
          <span>No linked documents</span>
        </div>
      ) : (
        <div className='flex flex-col gap-2 pt-2'>
          {Object.keys(labelMap).map(label => (
            <LabelGroup
              key={label}
              label={label}
              linkedDocuments={labelMap[label]}
              isProduct={details.isProduct}
            />
          ))}
        </div>
      )}
    </div>
  )
}

function LabelGroup ({ label, linkedDocuments, isProduct }) {
  return (
    <div>
      {label !== 'root' && (
        <span className='pb-1 font-medium text-dark-gray-500'>{label}</span>
      )}
      <ul className='ml-6 list-disc'>
        {linkedDocuments.map(doc => {
          const url = isProduct
            ? `/app/${doc.appId}/page/${doc.datasetId}/document-list/${doc.id}/view`
            : `/document-list/${doc.datasetId}/${doc.id}/view`

          return (
            <li className='text-text-link' key={doc.id}>
              <Link
                aria-label={doc.label}
                title={'Open ' + doc.label}
                to={url}
                target='_blank'
                className='hover:underline'
              >
                {doc.label}
              </Link>
            </li>
          )
        })}
      </ul>
    </div>
  )
}

export const useAssociationsQuery = (
  { id: linkedAppId, selectedGadget },
  documentId
) => {
  const associatedDocumentsQuery = getAssociatedDocumentsQuery(
    documentId,
    linkedAppId,
    selectedGadget?.formKey
  )
  const { data, error, loading } = useQuery(...associatedDocumentsQuery)
  let documents = data?.document.associationsConnection.edges ?? []
  documents = documents.map(({ node }) => node)
  return { data: documents, error, loading }
}

export function createLabelMap (data) {
  return data.reduce(
    (labelMap, doc) => {
      const groups = doc.ruleGroups.filter(Boolean)

      if (!groups.length) {
        labelMap.root.push(doc)
        return labelMap
      }

      groups.forEach(ruleGroup => {
        const label = ruleGroup?.[0] ? capitalize(ruleGroup[0]) : 'root'
        labelMap[label] = labelMap[label] || []
        labelMap[label].push(doc)
      })

      return labelMap
    },
    { root: [] }
  )
}

const getAssociatedDocumentsQuery = (documentId, linkedAppId, gadgetKey) => [
  gql`
    query getAssociatedDocuments(
      $documentId: ID!
      $linkedAppId: ID!
      $gadgetKey: String!
    ) {
      document(id: $documentId) {
        id
        associationsConnection(id: $linkedAppId, gadgetKey: $gadgetKey) {
          edges {
            node {
              id
              label
              appId
              datasetId
              ruleGroups
            }
          }
        }
      }
    }
  `,
  {
    variables: {
      documentId,
      linkedAppId,
      gadgetKey
    }
  }
]
