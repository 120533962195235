/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import { gql, useLazyQuery, useMutation, useQuery } from '@apollo/client'
import { i18n } from '@lingui/core'
import { Trans } from '@lingui/react'
import * as Sentry from '@sentry/browser'
import cx from 'clsx'
import { get, isEmpty, sample } from 'lodash'
import React from 'react'
import {
  Link,
  NavLink,
  Navigate,
  Outlet,
  useLocation,
  useParams
} from 'react-router-dom'
import styled from 'styled-components'

import { ReactComponent as KualiK } from '../components/kuali-k.svg.jsx'
import { ReactComponent as KualiLogoTM } from '../components/kualitm.svg.jsx'
import { loadLocale, locales, saveLocale } from '../i18n'
import * as Icons from '../icons'
import { SkipToContent, StartOfContent } from '../ui/a11y'
import Button from '../ui/button'
import { Flex } from '../ui/layout'
import { Popover2 } from '../ui/popover'
import { useTheme } from '../ui/theme'
import { Body1 } from '../ui/typography'
import AbbreviationIcon from './abbreviation-icon'
import {
  ANNOUNCEKIT_LINK,
  AnnounceKitUnreadBadge,
  useUnreadAnnounceKit
} from './announcekit'
import {
  featureFlagsModal,
  multipleLanguages,
  productBuilder,
  productPlayground,
  useNewAnnouncekit
} from './feature-flags'
import { GLaDOS, PortalBlue } from './portals'
import Spinner from './spinner'
import { GraphQLError as Error } from './system-error'
import { TenantFeaturesContextProvider } from './tenant-features-context'
import { useTileTransition } from './tile-transition'

const suiteListQuery = gql`
  query SuiteListQuery {
    suites {
      id
      icon
      name
      slug
    }
  }
`

export function SuiteRedirect () {
  const [fetchSuites, { data }] = useLazyQuery(suiteListQuery)
  const { pathname, search } = useLocation()
  const lastSuite = window.localStorage.getItem('current-suite')
  React.useEffect(() => {
    if (!productBuilder && !lastSuite) fetchSuites()
  }, [!lastSuite])
  let suite = productBuilder ? 'products' : lastSuite || data?.suites?.[0]?.slug
  if (!suite && !data?.suites) {
    return (
      <div className='flex h-screen w-screen items-center justify-center'>
        <Spinner size={100} />
      </div>
    )
  }
  suite = suite ?? 'void'
  return <Navigate to={`/${suite}${pathname}${search}`} replace />
}

export function RedirectToSuiteSpace () {
  const params = useParams()
  return <Navigate to={`/${params.suite}/space`} replace />
}

const productBuilderSuite = {
  name: productPlayground ? 'Product Playground' : 'Product Builder',
  slug: 'products',
  color: '#333',
  icon: 'product-builder',
  invertColor: true
}

const voidSuite = {
  name: '',
  slug: 'void',
  color: '#333',
  icon: '',
  invertColor: true
}

export function SuiteHomeLayout ({ which }) {
  const { suite } = useParams()
  const { finish } = useTileTransition()
  const { error, data } = useQuery(suiteHomeQuery, {
    variables: { suite }
  })
  React.useEffect(() => {
    if (data?.suite) window.localStorage.setItem('current-suite', suite)
  }, [suite, data?.suite])
  React.useEffect(() => {
    if (error) finish()
  }, [error])
  if (error) return <Error error={error} />
  if (isEmpty(data)) {
    return (
      <div className='flex h-screen items-center justify-center'>
        <Spinner size={200} />
      </div>
    )
  }
  const suiteObj = productBuilder
    ? productBuilderSuite
    : data?.suite ?? voidSuite
  if (!suiteObj) {
    const lastSuite = window.localStorage.getItem('current-suite')
    if (lastSuite === suite) window.localStorage.removeItem('current-suite')
    return <Navigate to='/' replace />
  }
  if (productBuilder && suite !== 'products') {
    return <Navigate to='/products/space' replace />
  }
  const inOnlySuite = data.suites.length === 1 && data.suites[0].slug === suite
  return (
    <TenantFeaturesContextProvider
      suites={data.suites}
      tenantFeatures={data?.tenant?.features}
    >
      <HomeLayout
        currentUser={data?.viewer?.user}
        actionCount={data?.viewer?.actionCount}
        rootSpaces={data?.viewer?.rootSpaces}
        tenantFeatures={data?.tenant?.features ?? {}}
        suites={productBuilder || inOnlySuite ? [] : data.suites}
        suite={suiteObj}
      >
        <Outlet context={{ currentUser: data?.viewer?.user }} />
      </HomeLayout>
    </TenantFeaturesContextProvider>
  )
}

const suiteHomeQuery = gql`
  query SuiteHomeQuery($suite: String!) {
    tenant {
      id
      features {
        spaces
        portals
        versions
        appCreationDisabled
      }
    }
    suites {
      id
      icon
      name
      slug
    }
    suite(slug: $suite) {
      id
      icon
      color
      name
      slug
      invertColor
    }
    viewer {
      id
      actionCount
      rootSpaces: spaces(type: ADMIN_ROOT) {
        id
      }
      user {
        id
        impersonating
        displayName
        canCreateApps
        canManageSettings
        canManageIdentity
        apps {
          cm
          cmUrl
          kualiApps
          kualiAppsUrl
        }
      }
    }
  }
`

function HomeLayout ({
  children,
  currentUser,
  actionCount,
  rootSpaces,
  tenantFeatures,
  suites,
  suite
}) {
  const [theme, setTheme] = useTheme()
  const [stopImpersonate] = useMutation(stopImpersonateMutation)
  React.useEffect(() => {
    if (!currentUser) return
    Sentry.configureScope(scope => {
      scope.setUser({ id: currentUser.id, username: currentUser.displayName })
    })
  }, [currentUser])
  const [announceKitUnreadCount, announceKitClearUnread] =
    useUnreadAnnounceKit()
  const canManageSettings = get(currentUser, 'canManageSettings')
  const canManageIdentity = get(currentUser, 'canManageIdentity', false)

  const showSystemSettings = !isEmpty(rootSpaces) || canManageSettings
  const canCreateApps = get(currentUser, 'canCreateApps')
  const cmUrl = get(currentUser, 'apps.cmUrl')
  const cmEnabled = get(currentUser, 'apps.cm') && !!cmUrl
  const kualiAppsUrl = get(currentUser, 'apps.kualiAppsUrl')
  const kualiAppsEnabled = get(currentUser, 'apps.kualiApps') && !!kualiAppsUrl
  const location = useLocation()
  const tenantHasSpaces = tenantFeatures?.spaces

  return (
    <GLaDOS>
      <div className='flex min-h-screen flex-col'>
        <SkipToContent />
        <header
          className={cx(
            'flex min-h-[80px] flex-wrap items-center justify-center pb-5 pl-4 pr-5 pt-6 md:justify-between md:py-0 md:pl-8 md:pr-9',
            { 'text-white dark:text-black': suite.invertColor }
          )}
          style={{ background: suite.color }}
        >
          {!!suites.length && (
            <Popover2
              role='menu'
              className='-order-1'
              trigger={
                <PopoverTrigger
                  aria-label={i18n._('home.layout.open.app.switcher')}
                  className='!rounded-lg !px-2 !py-3 transition-all hover:!bg-[rgba(0,0,0,0.2)]'
                >
                  <Icons.AppSwitcher
                    className={cx({
                      'fill-white dark:fill-black': suite.invertColor
                    })}
                  />
                </PopoverTrigger>
              }
            >
              {hide => (
                <Menu className='!p-2'>
                  {suites.map(s => (
                    <MenuLink
                      key={s.id}
                      to={`/${s.slug}/space/`}
                      onClick={hide}
                      className={cx(
                        'flex items-center gap-1.5 rounded-lg !p-4 hover:!bg-light-gray-100 dark:hover:bg-light-gray-300',
                        { '!bg-blue-000': s.slug === suite.slug }
                      )}
                    >
                      {s.icon ? (
                        <img
                          className='h-6 w-6 max-w-none'
                          src={`${process.env.PUBLIC_URL}/icons-suite/${s.icon}.svg`}
                        />
                      ) : (
                        <KualiK width='24' height='24' />
                      )}
                      <span className='font-[poppins] text-2xl font-medium tracking-tighter text-[#43535d]'>
                        {s.name}
                      </span>
                    </MenuLink>
                  ))}
                </Menu>
              )}
            </Popover2>
          )}
          <Link
            to={`/${suite.slug}/space`}
            className='-order-1 flex items-center rounded-lg px-2 py-1 transition-all hover:bg-[rgba(0,0,0,0.2)]'
          >
            {suite.icon ? (
              <img
                className='h-8 w-8 sm:mr-2'
                src={`${process.env.PUBLIC_URL}/icons-suite/${suite.icon}.svg`}
                alt=''
              />
            ) : (
              <KualiK width='32' height='32' />
            )}
            <div
              className={cx(
                'mr-2 hidden font-[poppins] text-2xl font-medium tracking-tighter sm:block',
                {
                  'text-white dark:text-black': suite.invertColor,
                  'text-black dark:text-white': !suite.invertColor
                }
              )}
            >
              {suite.name}
            </div>
          </Link>
          <Popover2
            role='menu'
            className='-order-1'
            trigger={
              <PopoverTrigger
                aria-label={i18n._('home.layout.open.system.menu')}
                className='!rounded-lg !p-[11px] transition-all hover:!bg-[rgba(0,0,0,0.2)]'
              >
                <Icons.MenuVertical
                  className={cx({
                    'fill-white dark:fill-black': suite.invertColor
                  })}
                />
              </PopoverTrigger>
            }
          >
            {hide => (
              <Menu className='!py-1'>
                <MenuLink
                  className='hover:bg-light-gray-200 dark:hover:bg-light-gray-300'
                  to={`${location.pathname}/identity/groups`}
                  onClick={hide}
                >
                  <Icons.Users />
                  <span>
                    {canManageIdentity
                      ? i18n._('home.layout.people.and.groups')
                      : i18n._('home.layout.groups')}
                  </span>
                </MenuLink>
                {showSystemSettings && (
                  <MenuLink
                    className='hover:bg-light-gray-200 dark:hover:bg-light-gray-300'
                    to='/system-settings'
                    onClick={hide}
                  >
                    <Icons.Module />
                    <span>
                      {tenantHasSpaces
                        ? i18n._('home.layout.spaces.and.settings')
                        : i18n._('home.layout.system.settings')}
                    </span>
                  </MenuLink>
                )}
                {canCreateApps && cmEnabled && (
                  <MenuLink
                    className='hover:bg-light-gray-200 dark:hover:bg-light-gray-300'
                    as='a'
                    target='_blank'
                    href={cmUrl}
                  >
                    <Icons.Book />
                    <span>
                      <Trans id='home.layout.curriculum.management' />
                    </span>
                  </MenuLink>
                )}
                {canCreateApps && kualiAppsEnabled && (
                  <MenuLink
                    className='hover:bg-light-gray-200 dark:hover:bg-light-gray-300'
                    as='a'
                    target='_blank'
                    href={kualiAppsUrl}
                  >
                    <Icons.AppSwitcher />
                    <span>
                      <Trans id='home.layout.other.kuali.apps' />
                    </span>
                  </MenuLink>
                )}
                {canCreateApps && productBuilder && (
                  <MenuLink
                    className='hover:bg-light-gray-200 dark:hover:bg-light-gray-300'
                    to={`${location.pathname}/imports`}
                    onClick={hide}
                  >
                    <Icons.Share />
                    <span>
                      <Trans id='home.layout.import.product.data' />
                    </span>
                  </MenuLink>
                )}
                {canCreateApps && canManageSettings && (
                  <MenuLink
                    className='hover:bg-light-gray-200 dark:hover:bg-light-gray-300'
                    to={`${location.pathname}/audit-log`}
                    onClick={hide}
                  >
                    <Icons.History />
                    <span>
                      <Trans id='home.layout.history' />
                    </span>
                  </MenuLink>
                )}
                {canManageSettings && (
                  <MenuLink
                    className='hover:bg-light-gray-200 dark:hover:bg-light-gray-300'
                    to={`${location.pathname}/usage/general`}
                    onClick={hide}
                  >
                    <Icons.Dashboard />
                    <span className='relative'>
                      <Trans id='usage' />
                      <span
                        after={i18n._({
                          id: 'experimental',
                          message: 'experimental'
                        })}
                        className='pr-[5.5rem] after:absolute after:left-12 after:rounded-sm after:bg-yellow-200 after:px-2 after:text-[9px] after:font-medium after:uppercase after:text-dark-gray-500 after:content-[attr(after)] dark:after:text-light-gray-100'
                      />
                    </span>
                  </MenuLink>
                )}
                <MenuLink
                  className='hover:bg-light-gray-200 dark:hover:bg-light-gray-300'
                  as='a'
                  target='_blank'
                  href='https://kuali.zendesk.com'
                >
                  <Icons.Support />
                  <span>
                    <Trans id='help' />
                  </span>
                </MenuLink>
              </Menu>
            )}
          </Popover2>
          <div className='-order-1 flex-1' />
          <div className='w-full md:w-0' />
          <nav className='flex items-center gap-10 whitespace-nowrap pb-1 pt-6 md:pb-6'>
            <NavLink
              to={`/${suite.slug}/space`}
              className={cx(
                'relative after:absolute after:left-1/2 after:block after:h-0.5 after:w-0 after:transition-all hover:after:left-0 hover:after:w-full [&.active]:font-medium [&.active]:after:left-0 [&.active]:after:w-full [&:not(.active)]:after:opacity-25',
                {
                  'after:bg-white dark:after:bg-black': suite.invertColor,
                  'after:bg-blue-500': !suite.invertColor
                }
              )}
            >
              <Trans id='home.layout.home' />
            </NavLink>
            <NavLink
              to={`/${suite.slug}/actions`}
              className={cx(
                'relative flex items-end after:absolute after:left-1/2 after:block after:h-0.5 after:w-0 after:transition-all hover:after:left-0 hover:after:w-full [&.active]:font-medium [&.active]:after:left-0 [&.active]:after:w-full [&:not(.active)]:after:opacity-25',
                {
                  'mr-5': !!actionCount,
                  'after:bg-white dark:after:bg-black': suite.invertColor,
                  'after:bg-blue-500': !suite.invertColor
                }
              )}
            >
              <Trans id='home.layout.action.list' />
              {!!actionCount && (
                <div
                  className={cx(
                    'absolute -right-5 top-1 flex h-4 w-4 items-center justify-center rounded-full bg-white text-[9px] font-medium leading-none',
                    {
                      'bg-white dark:bg-black': suite.invertColor,
                      'bg-blue-500': !suite.invertColor
                    }
                  )}
                  style={{ color: suite.invertColor ? suite.color : 'white' }}
                >
                  {actionCount > 99 ? '99+' : actionCount}
                </div>
              )}
            </NavLink>
            <NavLink
              to={`/${suite.slug}/my`}
              className={cx(
                'relative after:absolute after:left-1/2 after:block after:h-0.5 after:w-0 after:transition-all hover:after:left-0 hover:after:w-full [&.active]:font-medium [&.active]:after:left-0 [&.active]:after:w-full [&:not(.active)]:after:opacity-25',
                {
                  'after:bg-white dark:after:bg-black': suite.invertColor,
                  'after:bg-blue-500': !suite.invertColor
                }
              )}
            >
              <Trans id='home.layout.my.documents' />
            </NavLink>
          </nav>
          <div className='w-full flex-none md:w-0 md:flex-1' />
          <PortalBlue className='flex-1 md:flex-none' />
          {!useNewAnnouncekit && (
            <a
              className='-order-1 mr-2 flex items-center gap-1 rounded-full bg-staticblack/0 py-2 pl-4 pr-2 transition-all hover:bg-staticblack/25 md:order-1'
              href={ANNOUNCEKIT_LINK}
              target='_blank'
              rel='noreferrer'
              onClick={() => announceKitClearUnread()}
            >
              <span className='text-base'>
                <Trans id='home.layout.whats.new' />
              </span>
              <AnnounceKitUnreadBadge unread={announceKitUnreadCount} />
            </a>
          )}
          <Popover2
            role='menu'
            className='-order-1 md:order-1'
            trigger={
              <PopoverTrigger
                aria-label={i18n._('home.layout.open.user.menu')}
                className='!rounded-full !border-0 !p-1 transition-all hover:!bg-[rgba(0,0,0,0.2)]'
              >
                <div className='relative'>
                  <AbbreviationIcon
                    className='h-8 w-8 rounded-full text-xs font-medium !text-black'
                    name={currentUser?.displayName}
                  />
                  {useNewAnnouncekit && announceKitUnreadCount > 0 && (
                    <div
                      className='absolute -right-0.5 -top-0.5 h-3 w-3 rounded-full border-2 bg-green-300'
                      style={{ borderColor: suite.color }}
                    />
                  )}
                </div>
              </PopoverTrigger>
            }
          >
            {hide => (
              <Menu className='!py-1'>
                <MenuLink
                  className='hover:bg-light-gray-200 dark:hover:bg-light-gray-300'
                  to={`${location.pathname}/account/view`}
                  onClick={hide}
                >
                  <Icons.Profile />
                  <span>
                    <Trans id='home.layout.my.account' />
                  </span>
                </MenuLink>
                {currentUser?.impersonating && (
                  <MenuLink
                    className='hover:bg-light-gray-200 dark:hover:bg-light-gray-300'
                    as='button'
                    onClick={() =>
                      stopImpersonate().then(() => (window.location = '/'))
                    }
                  >
                    <Icons.Close />
                    <Trans id='home.layout.stop.impersonating' />
                  </MenuLink>
                )}
                <Popover2
                  role='sub-menu'
                  className='-order-1 md:order-1'
                  trigger={
                    <MenuLink
                      className='hover:bg-light-gray-200 dark:hover:bg-light-gray-300'
                      as='button'
                    >
                      <Icons.Brandsettings />
                      <Trans id='home.layout.theme' />
                    </MenuLink>
                  }
                  right={151}
                  top={4}
                >
                  {() => (
                    <>
                      <MenuLink
                        className='hover:bg-light-gray-200 dark:hover:bg-light-gray-300'
                        as='button'
                        onClick={() => {
                          setTheme('light')
                          hide()
                        }}
                      >
                        <Icons.Sun />
                        <Trans id='home.layout.light.mode' />
                        {theme === 'light' && (
                          <Icons.Check className='relative -right-4' />
                        )}
                      </MenuLink>
                      <MenuLink
                        className='hover:bg-light-gray-200 dark:hover:bg-light-gray-300'
                        as='button'
                        onClick={() => {
                          setTheme('dark')
                          hide()
                        }}
                      >
                        <Icons.Moon />
                        <Trans id='home.layout.dark.mode' />
                        {theme === 'dark' && (
                          <Icons.Check className='relative -right-4' />
                        )}
                      </MenuLink>
                      <MenuLink
                        className='hover:bg-light-gray-200 dark:hover:bg-light-gray-300'
                        as='button'
                        onClick={() => {
                          setTheme('default')
                          hide()
                        }}
                      >
                        <Icons.Computer />
                        <Trans id='home.layout.system.default' />
                        {theme === 'default' && (
                          <Icons.Check className='relative -right-4' />
                        )}
                      </MenuLink>
                    </>
                  )}
                </Popover2>
                {multipleLanguages && (
                  <Popover2
                    role='sub-menu'
                    className='-order-1 md:order-1'
                    trigger={
                      <MenuLink
                        className='hover:bg-light-gray-200 dark:hover:bg-light-gray-300'
                        as='button'
                      >
                        <Icons.Flag />
                        <Trans id='home.layout.language' />
                      </MenuLink>
                    }
                    right={151}
                    top={4}
                  >
                    {() => (
                      <>
                        {Object.keys(locales).map(key => (
                          <MenuLink
                            className='hover:bg-light-gray-200 dark:hover:bg-light-gray-300'
                            key={key}
                            as='button'
                            onClick={() => {
                              saveLocale(key)
                              loadLocale(key)
                              hide()
                            }}
                          >
                            {locales[key]}
                            {i18n.locale.startsWith(key) && (
                              <Icons.Check className='relative -right-4' />
                            )}
                          </MenuLink>
                        ))}
                      </>
                    )}
                  </Popover2>
                )}
                {featureFlagsModal && (
                  <MenuLink
                    className='hover:bg-light-gray-200 dark:hover:bg-light-gray-300'
                    to='?center-modal=feature-flags'
                    onClick={hide}
                  >
                    <Icons.Target />
                    <span>Feature Flags</span>
                  </MenuLink>
                )}
                {useNewAnnouncekit && (
                  <MenuLink
                    className='hover:bg-light-gray-200 dark:hover:bg-light-gray-300'
                    href={ANNOUNCEKIT_LINK}
                    target='_blank'
                    rel='noreferrer'
                    as='a'
                    onClick={() => announceKitClearUnread()}
                  >
                    <Icons.Stars />
                    <div className='flex w-full items-center justify-between'>
                      <span>
                        <Trans id='home.layout.whats.new' />
                      </span>
                      {announceKitUnreadCount > 0 && (
                        <div className='flex h-4 w-4 items-center justify-center rounded-full bg-wintergreen-300 text-[9px] font-medium'>
                          {announceKitUnreadCount}
                        </div>
                      )}
                    </div>
                  </MenuLink>
                )}
                <MenuLink
                  className='hover:bg-light-gray-200 dark:hover:bg-light-gray-300'
                  as='a'
                  href='/auth/signout'
                >
                  <Icons.Logout />
                  <span>
                    <Trans id='home.layout.logout' />
                  </span>
                </MenuLink>
                <Trademark />
              </Menu>
            )}
          </Popover2>
        </header>
        <StartOfContent />
        {children}
      </div>
    </GLaDOS>
  )
}

const stopImpersonateMutation = gql`
  mutation StopImpersonate {
    stopImpersonate
  }
`

const hipPhrases = [
  'Sparking Joy',
  'Herding Cats',
  'Polishing Pixels',
  'Steaming Hams',
  'More Cowbell',
  'Double Rainbows',
  'Fluxing Capacitors',
  'Bending Elements',
  'Dodging Bullets',
  'Hacking Matrices',
  'Training Dragons',
  'Trolling Forums',
  'Ricking Rolls',
  'Yeeting Objects',
  'Nyaning Cats',
  'Salt Sprinkling',
  'Epic Handshaking',
  'Counting Chickens',
  'Holding Horses',
  'Stealing Thunder',
  'Turning Tables',
  'Biting Bullets',
  'Crossing Bridges',
  'Finding Needles',
  'Opening Cans',
  'Rocking Boats',
  'Splitting Hairs',
  'Binge Watching',
  'Grinding Levels',
  'Rolling Nat20s',
  'Soldering Circuits',
  'Mapping Galaxies',
  'Brewing Potions',
  'Setting Mods',
  'No Whammies',
  'Making Data Dance',
  'Turning Heads',
  'Iterating like a Boss',
  'Warp Speed',
  '42',
  'Not Today',
  'Beaming Up'
]
const Trademark = () => {
  return (
    <div className='border-t-[1px] border-light-gray-200 p-4 dark:border-light-gray-100'>
      <div className='whitespace-nowrap text-[9px] uppercase text-light-gray-500'>
        {i18n.locale.startsWith('en') && sample(hipPhrases)}{' '}
        {i18n._({ id: 'since', message: 'since' })} 2004
      </div>
      <div>
        <Popover2
          role='note'
          trigger={<KualiLogoTM className='cursor-pointer' />}
        >
          {hide => (
            <div className='flex w-80 flex-col gap-4 p-6 text-sm text-black'>
              <button onClick={hide} className='absolute right-3 top-3'>
                <Icons.Close width='12' height='12' />
              </button>
              <Trans id='hey.there' components={{ p: <p />, br: <br /> }} />
              <KualiK width='16' height='16' />
            </div>
          )}
        </Popover2>
      </div>
    </div>
  )
}

const PopoverTrigger = styled(Flex)`
  height: inherit;
  &:active {
    border-color: transparent;
  }
  &:hover {
    background: none;
  }
`
PopoverTrigger.defaultProps = { as: Button, transparent: true }

const Menu = styled.div`
  padding-bottom: 8px;
`

const MenuLink = styled(Body1)`
  width: 100%;
  display: flex;
  align-items: center;
  padding: 8px 16px;
  text-decoration: none;
  white-space: nowrap;
  background: none;
  border: none;
  cursor: pointer;
  > svg {
    margin-right: 16px;
  }
`
MenuLink.defaultProps = { as: Link }
