/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import { Trans } from '@lingui/react'
import cx from 'clsx'
import React from 'react'
import styled from 'styled-components'

import Spinner from '../../../../components/spinner'
import usePrintMode from '../../../../components/use-print-mode'
import Button from '../../../../ui/button'

export default function ZoomableImage ({
  small,
  large,
  largeHeight,
  largeWidth,
  filename
}) {
  const [loading, setLoading] = React.useState(true)
  const [zoomed, setZoomed] = React.useState(false)
  const [highResGrowLoading, setHighResGrowLoading] = React.useState(false)
  const [highResShrinkLoading, setHighResShrinkLoading] = React.useState(false)
  const isPrinting = usePrintMode()

  return (
    <>
      <ZoomWrapper
        as={isPrinting ? 'a' : 'div'}
        onClick={
          !isPrinting
            ? event => {
                event.stopPropagation(true)
                setHighResGrowLoading(true)
                setZoomed(true)
              }
            : null
        }
        href={isPrinting ? large : null}
      >
        <div className='relative flex h-10 w-10 items-center justify-center'>
          {(loading || highResGrowLoading) && (
            <Spinner className='absolute z-10' size={30} />
          )}
          <img
            src={small}
            onLoad={() => setLoading(false)}
            hidden={highResGrowLoading}
            className={cx('max-h-10 max-w-10 duration-75', {
              'brightness-75': highResGrowLoading
            })}
          />
        </div>
        <div className='pl-2 font-medium'>{filename}</div>
      </ZoomWrapper>
      {zoomed && (
        <ZoomedImageWrapper
          hidden={highResGrowLoading || highResShrinkLoading}
          onClick={event => {
            event.stopPropagation(true)
            setTimeout(() => {
              setZoomed(false)
              setHighResShrinkLoading(false)
            }, 950)
            setHighResShrinkLoading(true)
          }}
        >
          <LargeImgWrapper
            height={
              highResGrowLoading || highResShrinkLoading
                ? 40
                : largeHeight || 640
            }
            width={
              highResGrowLoading || highResShrinkLoading
                ? 40
                : largeWidth || 640
            }
          >
            <img
              src={large}
              onLoad={() => {
                setHighResGrowLoading(false)
              }}
              className='max-h-full max-w-full'
            />
          </LargeImgWrapper>
          <DockedButton
            outline
            onClick={e => {
              e.stopPropagation()
              const newTab = window.open()
              newTab?.document.write(
                `<!DOCTYPE html><head><title>Image preview</title></head><body><img src="${large}"></body></html>`
              )
            }}
          >
            <Trans id='open.in.new.tab' />
          </DockedButton>
        </ZoomedImageWrapper>
      )}
    </>
  )
}

const DockedButton = styled(Button)`
  position: absolute;
  bottom: 24px;
  right: 8px;
`

const ZoomWrapper = styled.div`
  cursor: zoom-in;
  display: flex;
  align-items: center;
`

const ZoomedImageWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: #ffffff;
  cursor: zoom-out;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 3;
  opacity: ${p => (p.hide ? 0 : 1)};
  transition: opacity 1s;
`
const LargeImgWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: calc(100vw - 32px);
  width: ${p => p.width}px;
  height: ${p => p.height}px;
  transition:
    height 1s,
    width 1s;
`
