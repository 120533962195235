/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import { gql } from '@apollo/client'
import localforage from 'localforage'
import {
  compact,
  filter,
  find,
  flow,
  forEach,
  get,
  includes,
  isEqual,
  map,
  reject,
  uniqBy
} from 'lodash'
import React from 'react'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import shortid from 'shortid'
import { useImmer } from 'use-immer'

import { filtersOnDocList } from '../../../components/feature-flags'
import { useIds } from '../../../components/use-ids'
import { useQuery } from '../../../components/use-query'
import { formbot, gadgets } from '../../../formbot'
import { gatherAllSubGadgets } from '../../../formbot/engine/formbot/utils'
import { insertData } from '../../../formbot/filter-config'

const DEFAULT_QUERY = {
  skip: 0,
  query: '',
  limit: 25,
  sorts: [],
  filters: [],
  filter: null,
  columns: []
}

const combine = (operators, type) => {
  if (operators.length === 0) return null
  if (operators.length === 1) return operators[0]
  return { type, operators }
}

export const paramsToGql = ({ skip, limit, ...params }) => {
  const query = {
    skip,
    limit,
    query: params.query || '',
    sort: flow(
      a => filter(a, sort => !!sort.field),
      a =>
        map(a, sort => {
          let sortStr = sort.field
          if (sort.ascending) sortStr = `-${sortStr}`
          sortStr += get(gadgets, [sort.type, 'sortSuffix'], '')
          return sortStr
        })
    )(params.sorts)
  }

  const operators = compact(
    map(params.filters, myFilter => {
      const format = get(gadgets, [myFilter.type, 'filters', 'toGraphQL'])
      if (!format) return null
      const operators = format(myFilter)
      return combine(operators, 'OR')
    })
  )

  const fields = combine(operators, 'AND')
  if (fields) query.fields = fields

  if (filtersOnDocList) {
    query.fields = insertData(params.filter)
    query.versionConfig = params.versionConfig ?? 'LATEST_VERSION'
  }

  query.columns = flow(
    a => filter(a, 'visible'),
    a => map(a, 'formKey')
  )(params.columns)

  return query
}

export function gqlToParams (form, gqlParams) {
  const params = {
    ...DEFAULT_QUERY,
    query: gqlParams.query || '',
    sorts: buildSorts(form.schema, gqlParams.sort),
    filters: [],
    columns: buildColumns(form, gqlParams.columns),
    limit: gqlParams.limit ?? 25,
    viewId: gqlParams.id
  }

  if (gqlParams.fields) {
    const items =
      gqlParams.fields.type === 'AND'
        ? gqlParams.fields.operators
        : [gqlParams.fields]

    forEach(items, item => {
      const values = item.type === 'OR' ? item.operators : [item]
      const fieldType = gadgetFieldToType(form.schema, values[0].field)
      const format = gadgets?.[fieldType]?.filters?.fromGraphQL

      if (format) {
        params.filters.push(format(values))
      }
    })
  }

  return params
}

export const buildColumns = ({ schema, gadgetIndexTypes }, columnIds) => {
  const metaFields = filter(schema, g => !g.formKey.startsWith('data.'))
  const dataGadgets = gatherAllSubGadgets(
    map(gadgetIndexTypes, (value, formKey) => ({
      ...value,
      label: value?.customName?.enabled
        ? value?.customName?.value
        : value.label,
      type: value.gadgetType,
      formKey: `data.${formKey}`
    })),
    formbot
  )
  const gadgets = reject([...dataGadgets, ...metaFields], g =>
    includes(['Spacer', 'DataLink'], g.type)
  )
  const visible = compact(map(columnIds, id => find(gadgets, { formKey: id })))
  const hidden = reject(gadgets, gadget => includes(columnIds, gadget.formKey))
  return [
    ...map(visible, gadget => ({ ...gadget, visible: true })),
    ...map(hidden, gadget => ({ ...gadget, visible: false }))
  ]
}

export const buildSorts = (schema, sorts) => {
  return compact(
    map(sorts, sort => {
      const ascending = sort.indexOf('-') === 0
      const field = ascending ? sort.slice(1) : sort
      const type = gadgetFieldToType(schema, field)
      if (!type) return null
      return {
        id: shortid.generate(),
        field: field.replace('.label', ''),
        type,
        ascending
      }
    })
  )
}

function gadgetFieldToType (schema, field) {
  if (!field) return
  let gadget = find(schema, { formKey: field })
  if (!gadget) gadget = find(schema, { formKey: field.replace('.id', '') })
  if (!gadget) gadget = find(schema, { formKey: field.replace('.label', '') })
  if (!gadget) {
    gadget = find(schema, { formKey: field.replace('.startDate', '') })
  }
  return gadget ? gadget.type : ''
}

function persistParams (storageKey, params) {
  const {
    query,
    limit,
    sorts,
    filters,
    filter,
    columns,
    versionConfig,
    viewId
  } = params
  localforage.setItem(storageKey, {
    query,
    limit,
    sorts,
    filters,
    filter,
    columns,
    versionConfig,
    viewId: viewId || 'DEFAULT'
  })
}

function usePrevious (value) {
  const ref = React.useRef()
  React.useEffect(() => {
    ref.current = value
  }, [value])
  return ref.current
}

export function useLoadById () {
  const navigate = useNavigate()
  const location = useLocation()
  const [searchParams] = useSearchParams()
  const [params, setParams] = React.useState(null)
  const id = searchParams.get('id')
  const { appId, datasetId } = useIds()
  const prevDatasetId = usePrevious(datasetId)
  const storageKey = `query-${datasetId || appId}`
  const q = getListPageByIdQuery(appId, datasetId, id)
  const { loading, error, data } = useQuery(q)
  React.useEffect(() => {
    async function load () {
      if (!params || datasetId !== prevDatasetId) {
        if (!id || error) {
          const savedParams = await localforage.getItem(storageKey)
          setParams({ ...DEFAULT_QUERY, ...(savedParams || {}) })
          if (id) navigate(location.pathname, { replace: true })
        }
        if (!error && !loading && data?.app?.dataset) {
          const { formContainer, filter } = data.app.dataset
          const params = gqlToParams(formContainer, filter.connection)
          persistParams(storageKey, params)
          setParams(params)
          navigate(location.pathname, { replace: true })
        }
      }
    }
    load()
  }, [loading, error, data, datasetId])
  const filteredParams = React.useMemo(
    () => params && { ...params, columns: uniqBy(params.columns, 'formKey') },
    [params]
  )
  return [filteredParams, storageKey]
}

const getListPageByIdQuery = (appId, pageId, id) => ({
  variables: { appId, pageId, id },
  skip: !id,
  query: gql`
    query ListPageByIdQuery($appId: ID!, $pageId: ID, $id: ID!) {
      app(id: $appId) {
        id
        dataset(id: $pageId) {
          id
          formContainer {
            id
            gadgetIndexTypes
            schema {
              id
              formKey
              type
              label
              details
            }
          }
          filter(args: { id: $id }) {
            id
            connection {
              sort
              fields
              query
              columns
            }
          }
        }
      }
    }
  `
})

export function useQueryParams (defaultParams, storageKey) {
  const [params, _updateParams] = useImmer(defaultParams)
  React.useEffect(() => {
    persistParams(storageKey, params)
  }, [
    params.query,
    params.limit,
    params.sorts,
    params.filters,
    params.filter,
    params.columns,
    params.versionConfig
  ])
  const gqlParams = paramsToGql(params)
  const updateParams = update => {
    _updateParams(draft => {
      const result = update(draft)
      if (result) {
        result.defaultColumns = draft.defaultColumns
        result.defaultSorts = draft.defaultSorts
        return result
      }
    })
  }
  const reset = () =>
    updateParams(draft => {
      draft.columns = draft.defaultColumns
      draft.sorts = draft.defaultSorts
      draft.filters = []
      draft.filter = null
      draft.viewId = 'DEFAULT'
    })

  React.useEffect(() => {
    updateParams(draft => {
      draft.columns = defaultParams.columns
      draft.sorts = defaultParams.sorts
      draft.limit = defaultParams.limit
    })
  }, [defaultParams.columns, defaultParams.sorts, defaultParams.limit])
  return { params, gqlParams, updateParams, reset }
}

export function useSetDefaults (updateParams, app) {
  React.useEffect(() => {
    if (!app?.documentListConfig || !app?.form) return
    updateParams(draft => {
      const parsed = gqlToParams(app.form, app.documentListConfig)
      draft.defaultColumns = parsed.columns
      draft.defaultSorts = parsed.sorts
      if (!draft.viewId || draft.viewId === 'CUSTOM') {
        if (matchesDefaultConfig(draft, parsed)) {
          draft.viewId = 'DEFAULT'
        } else {
          draft.viewId = 'CUSTOM'
        }
      }
    })
  }, [app?.form, app?.documentListConfig])
  React.useEffect(() => {
    if (app) {
      updateParams(draft => {
        if (draft.viewId === 'DEFAULT') {
          draft.columns = draft.defaultColumns
          draft.sorts = draft.defaultSorts
          return draft
        }
        draft.sorts = draft.sorts.length ? draft.sorts : draft.defaultSorts
        const visibleKeys = map(filter(draft.columns, 'visible'), 'formKey')
        draft.columns = visibleKeys.length
          ? buildColumns(app.form, visibleKeys)
          : draft.defaultColumns
        draft.sorts = filter(
          draft.sorts,
          sort => !!gadgetFieldToType(app.form.schema, sort.field)
        )
        draft.filters = filter(
          draft.filters,
          filter => !!gadgetFieldToType(app.form.schema, filter.field)
        )
      })
    }
  }, [app?.id])
}

function matchesDefaultConfig (stored, defaults) {
  const areEqual = key => {
    if (key === 'sorts') {
      return isEqual(
        stored.sorts.map(({ id, ...rest }) => rest),
        defaults.sorts.map(({ id, ...rest }) => rest)
      )
    }
    return isEqual(stored[key], defaults[key])
  }

  return ['columns', 'sorts', 'filters', 'query'].every(areEqual)
}
