/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import cx from 'clsx'
import React from 'react'
import styled from 'styled-components'

import Button from '../../../ui/button'
import { useFormbot } from '../../engine/formbot-react/hooks'
import { InnerTh, Table, Tbody, Td, Th, Thead, Tr } from './ui'
import * as utils from './utils'
import ViewModal from './view-modal'

export default function TableView (props) {
  const [showModal, setShowModal] = React.useState(false)
  if (props.condensed) {
    const length = props.value?.filter(row => !('_isFooter' in row)).length || 0
    const label = length === 1 ? `${length} item` : `${length} items`
    return (
      <div className='flex items-center justify-center'>
        {props.value && (
          <Button
            transparent
            onClick={event => {
              event.stopPropagation(true)
              event.preventDefault() // Needed because stopPropagation still bubbles up events for links
              setShowModal(true)
            }}
            aria-label={`Table with ${length} ${label}.  Click to view.`}
          >
            {label}
          </Button>
        )}
        <ViewModal setShowModal={setShowModal} showModal={showModal}>
          <MyTable {...props} />
        </ViewModal>
      </div>
    )
  }
  return <MyTable {...props} />
}

function isScrollable (element) {
  return (
    element.scrollHeight > element.clientHeight ||
    element.scrollWidth > element.clientWidth
  )
}

// This hook is used to determine if the table is scrollable. It only does it
// once and is only used for the print view.
function useScrollable () {
  const ref = React.useRef()
  const [scrollable, setScrollable] = React.useState(false)
  React.useLayoutEffect(() => {
    const element = ref.current
    if (!element) return
    setScrollable(isScrollable(element))
  }, [])
  return [ref, scrollable]
}

function MyTable (props) {
  const [wrapperRef, scrollable] = useScrollable()
  const formbot = useFormbot()
  const { formKey, fbRenderView, gridded, id, details } = props
  const calcFooter = props.details?.calculationFooter
  const template = props.childrenTemplate.filter(
    g =>
      props.progDisc(utils.removeRowBasedProgDisc(g)) &&
      g.details?.headless !== true
  )
  const value =
    props.value ||
    [...new Array(details.defaultRowCount || 2)].map(() =>
      utils.newRow(props.childrenTemplate, formbot)
    )
  return (
    <div
      ref={wrapperRef}
      className={cx('w-1 min-w-full overflow-auto', { scrollable })}
    >
      <Table
        aria-labelledby={props['aria-labelledby']}
        gridded={gridded}
        id={id}
      >
        <Thead>
          <Tr>
            {template.map(gadget => {
              if (gadget.details?.headless) {
                return <SneakyCell key={gadget.formKey} />
              }
              return (
                <Th id={gadget.id} key={gadget.formKey}>
                  <InnerTh>
                    <span className={cx({ 'required-field': gadget.required })}>
                      {gadget.label}
                    </span>
                    <utils.Description
                      description={gadget.description}
                      id={gadget.id}
                      labelSize={props.context?.labelSize}
                    />
                  </InnerTh>
                </Th>
              )
            })}
          </Tr>
        </Thead>
        <Tbody>
          {value.map((row, i) => {
            if (row?._isFooter) return null
            const gadgets = utils.getCellGadgets(template, formKey, i, formbot)
            return (
              <Tr key={row._rowId}>
                {template.map(gadget => {
                  const shouldShow = props.progDisc(
                    utils.finalizeRowBasedProgDisc(gadget, i),
                    gadgets
                  )
                  const Wrapper = gadget.details?.headless
                    ? SneakyCell
                    : RegularCell
                  return (
                    <Wrapper key={gadget.formKey}>
                      <label htmlFor={gadget.id} className='print-label'>
                        <b>{gadget.label}</b>
                      </label>
                      {shouldShow ? (
                        fbRenderView(gadget.type, row[gadget.formKey], {
                          ...gadget.details,
                          rowId: row._rowId,
                          rowIndex: i
                        })
                      ) : (
                        <utils.HiddenGadget />
                      )}
                    </Wrapper>
                  )
                })}
              </Tr>
            )
          })}
        </Tbody>
        {calcFooter?.enabled && (
          <tfoot>
            <tr className='bg-light-gray-200' key={value[0]._rowId}>
              {template.map(gadget => {
                return (
                  <FooterField
                    key={gadget.formKey}
                    gadget={gadget}
                    calcFunction={calcFooter.columns?.[gadget.id]}
                    fbRenderView={fbRenderView}
                    value={value}
                    shouldShow={props.progDisc(
                      utils.finalizeRowBasedProgDisc(gadget, 0),
                      utils.getCellGadgets(template, formKey, 0, formbot)
                    )}
                  />
                )
              })}
            </tr>
          </tfoot>
        )}
      </Table>
    </div>
  )
}

function FooterField ({
  shouldShow,
  gadget,
  calcFunction,
  fbRenderView,
  value
}) {
  const Wrapper = gadget.details?.headless ? SneakyCell : RegularCell
  return (
    <Wrapper className={cx({ calculation: !!calcFunction })}>
      {shouldShow ? (
        <>
          {!!calcFunction && (
            <label htmlFor={gadget.id} className='print-label'>
              <b>{gadget.label}</b>
            </label>
          )}
          {fbRenderView(
            gadget.type === 'LinearScale' || calcFunction === 'count'
              ? 'Number'
              : gadget.type,
            value[0][gadget.formKey],
            {
              ...gadget.details,
              rowId: value[0]._rowId,
              rowIndex: 0
            }
          )}
        </>
      ) : (
        <utils.HiddenGadget />
      )}
    </Wrapper>
  )
}

const TableViewCell = styled.div`
  display: grid;
  height: 100%;
  padding: 16px;
  & > .formbot-gadget {
    justify-content: center;
  }
`

const SneakyCell = styled.div``
function RegularCell ({ children, ...props }) {
  return (
    <Td {...props}>
      <TableViewCell>{children}</TableViewCell>
    </Td>
  )
}
