/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import { find, get, map } from 'lodash'
import React from 'react'
import styled from 'styled-components'

import { gadgets } from '../../formbot'
import * as Icons from '../../icons'
import { TableHeader, TableHeaderCell, TableRow } from '../../ui/table'
import { filtersOnDocList } from '../feature-flags'
import { useEscapePress } from '../use-escape-click'
import { useOutsideClick } from '../window-click'
import HeaderPopover from './header-popover'

export default ({
  columns,
  params,
  updateParams,
  children,
  rowHeader,
  ...props
}) => (
  <TableHeader {...props}>
    <TableRow>
      {rowHeader && <td className='sr-only' />}
      {map(columns, gadget => (
        <TH
          key={gadget.formKey}
          params={params}
          updateParams={updateParams}
          gadget={gadget}
        />
      ))}
      {children}
    </TableRow>
  </TableHeader>
)

const TH = ({ params, updateParams, gadget }) => {
  const ref = React.useRef()
  const [showingModal, setShowingModal] = React.useState(false)
  const hide = React.useCallback(() => setShowingModal(false), [])
  const toggle = React.useCallback(() => setShowingModal(a => !a), [])
  const FilterUI = get(gadgets, [gadget.type, 'filters', 'UI'])
  useOutsideClick(ref, hide, showingModal)
  useEscapePress(hide, showingModal)
  const sort = find(params.sorts, { field: gadget.formKey })
  const sortable = !(
    gadget.unsortable || get(gadgets, [gadget.type, 'unsortable'])
  )
  const clickable = FilterUI || sortable
  return (
    <TableHeaderCell scope='col'>
      <InnerWrapper ref={ref}>
        {clickable && (
          <Clickable
            onClick={toggle}
            aria-haspopup='dialog'
            newDocListFilters={filtersOnDocList}
            as={filtersOnDocList ? 'div' : 'button'}
          >
            {gadget.label}
            {sort &&
              (sort.ascending ? (
                <Icons.KeyboardArrowDown
                  ml={1}
                  width={12}
                  height={12}
                  className='fill-medium-gray-500 dark:fill-medium-gray-300'
                />
              ) : (
                <KeyboardArrowUp
                  ml={1}
                  width={12}
                  height={12}
                  className='fill-medium-gray-500 dark:fill-medium-gray-300'
                />
              ))}
            {!filtersOnDocList && <MenuIcon ml={1} fill='#bebebe' />}
          </Clickable>
        )}
        {!clickable && gadget.label}
        {showingModal && (
          <HeaderPopover
            gadget={gadget}
            params={params}
            updateParams={updateParams}
          />
        )}
      </InnerWrapper>
    </TableHeaderCell>
  )
}

const KeyboardArrowUp = styled(Icons.KeyboardArrowDown)`
  transform: rotate(180deg);
`

const MenuIcon = styled(Icons.MenuVertical)`
  opacity: 0;
`

const Clickable = styled.button`
  background: none;
  border: none;
  font-weight: inherit;
  font-size: inherit;
  padding: 0;
  white-space: nowrap;
  height: 36px;
  display: flex;
  align-items: center;
  ${p => !p.newDocListFilters && 'cursor: pointer;'}
  ${p => p.newDocListFilters && 'pointer-events: none;'}
  &:hover > ${MenuIcon} {
    opacity: 1;
  }
`

const InnerWrapper = styled.div`
  display: inline-block;
  position: relative;
`
